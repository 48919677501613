import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from 'react-loader';
import { Container, Col, Row, Card, CardBody, CardTitle, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import { useAppDispatch, useAppSelector, useProductionUnits, BOE, MCFE } from '../../hooks';
import { readWell } from '../../actions/wellActions';
import { readProductions } from '../../actions/productionActions';
import { readRegions } from '../../actions/regionActions';
import { sizes } from '../../theme';
import { paths } from '../../paths';
import AsyncPage from '../AsyncPage/AsyncPage';
import ErrorComponent from '../AsyncPage/ErrorComponent';
import WellTransferList from '../Transfer/components/WellTransferList';
import WellProductionChart from './components/WellProductionChart';
import WellsMap from './components/WellsMap';
import WellProductionList from './components/WellProductionList';
import WellDetails from './components/WellDetails';

const WELL_STORE = 'wellDetailsStore';
const REGION_STORE = 'wellRegionStore';
const PRODUCTION_STORE = 'wellProductionsStore';
const TRANSFERS_STORE = 'wellTransfersStore';

interface ParamTypes {
  id: string;
}

const ViewWell = () => {
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState('Summary');
  const [delayRender, setDelayRender] = useState(true);
  const { id: urlId } = useParams<ParamTypes>();

  const well = useAppSelector((state) => state.serverStores?.[WELL_STORE]?.payload);
  const region = useAppSelector((state) => state.serverStores?.[REGION_STORE]?.payload?.results?.[0]);
  const error = useAppSelector(
    (state) =>
      state.serverStores?.[WELL_STORE]?.error ||
      state.serverStores?.[REGION_STORE]?.error ||
      state.serverStores?.[PRODUCTION_STORE]?.error,
  );
  const isFetching = useAppSelector(
    (state) =>
      state.serverStores?.[WELL_STORE]?.isFetching ||
      state.serverStores?.[REGION_STORE]?.isFetching ||
      state.serverStores?.[PRODUCTION_STORE]?.isFetching,
  );

  const api = well?.api || '';

  const { title: productionUnitsTitle, units: productionUnits } = useProductionUnits();

  const transfersQuerystring = JSON.stringify({
    skip: 0,
    limit: 20,
    sort: [{ transferDate: 'desc' }],
    filters: { wellId: urlId },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(readWell(WELL_STORE, urlId!));
    const _productionQuery = {
      limit: 50,
      skip: 0,
      sort: [{ year: 'desc' }],
      filters: { wellId: urlId },
    };
    dispatch(readProductions(PRODUCTION_STORE, _productionQuery));
  }, [urlId, dispatch]);

  const stateAbbrev = well?.stateAbbrev;
  useEffect(() => {
    if (stateAbbrev) {
      const _regionQuery = {
        limit: 1,
        skip: 0,
        filters: { stateAbbrev, level: 2 },
      };
      dispatch(readRegions(REGION_STORE, _regionQuery));
    }
  }, [stateAbbrev, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!well) {
    return null;
  }

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const hdrStyle = { display: 'inline-block', marginBottom: '0.5rem', marginRight: '0.5rem', marginLeft: '0.5rem' };
  const mapHeight = Math.min(600, Math.max(200, window.innerHeight - sizes.appHeaderPadding - 200));

  const chartLastFullYear = region?.latestFullProductionYear;
  const chartDisclaimer = `
    Note: production data is complete through ${chartLastFullYear || 'the previous year'} for ${well.stateAbbrev}.
    Production totals beyond ${chartLastFullYear || 'the previous year'} may not be indicative of final values.
  `;

  return (
    <main className={'mainContent'}>
      <AsyncPage loading={isFetching} error={error}>
        <Container fluid={true}>
          <Link to={paths.LIST_WELLS} title={'View all wells'}>
            Wells
          </Link>
          &nbsp;&gt;&nbsp;
          <h2 style={hdrStyle}>
            {well.site}&nbsp;
            {well.api ? (
              <span>
                ({api.slice(0, 2)}-{api.slice(2, 5)}-{api.slice(5, 10)})
              </span>
            ) : (
              ''
            )}
          </h2>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'Summary' ? 'active' : ''}
                onClick={() => toggle('Summary')}
                title={'View well summary'}
              >
                Summary
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'Production' ? 'active' : ''}
                onClick={() => toggle('Production')}
                title={'View well production table'}
              >
                Production
              </NavLink>
            </NavItem>
          </Nav>
          <div className={'mt-4'}>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={'Summary'}>
                <Row>
                  <Col sm={6} md={5} lg={4} xl={3} className={'mb-4'}>
                    <WellDetails well={well} />

                    <WellTransferList storeIdentifier={TRANSFERS_STORE} queryString={transfersQuerystring} />
                  </Col>
                  <Col sm={6} md={7} lg={8} xl={9} className={'mb-4'}>
                    {well.location && well.location.length > 1 && (
                      <Card className={'mb-4'}>
                        <CardBody>
                          <CardTitle>Location</CardTitle>
                          {/* eslint-disable-next-line */}
                          <WellsMap
                            storeIdentifier={WELL_STORE}
                            queryIdentifier={'NO_QUERY'}
                            height={mapHeight.toString()}
                            mapTypeId={'hybrid'}
                            defaultZoom={17}
                          />
                        </CardBody>
                      </Card>
                    )}
                    <div className={'mb-2 font-italic'}>{chartDisclaimer}</div>
                    <div className={'mb-4'}>
                      <Card>
                        <CardBody>
                          <CardTitle>Total Production ({productionUnitsTitle})</CardTitle>
                          <WellProductionChart
                            storeIdentifier={PRODUCTION_STORE}
                            dataField={'boeTotal'}
                            yTitle={`Production (${productionUnitsTitle})`}
                            h={400}
                            lastFullYear={chartLastFullYear}
                          />
                        </CardBody>
                      </Card>
                    </div>
                    <div className={'mb-4'}>
                      <Card>
                        <CardBody>
                          <CardTitle>Gas Production {`(${productionUnits === MCFE ? 'Mcf' : productionUnitsTitle})`}</CardTitle>
                          <WellProductionChart
                            storeIdentifier={PRODUCTION_STORE}
                            dataField={'gasTotal'}
                            yTitle={`Total Gas (${productionUnits === MCFE ? 'Mcf' : productionUnitsTitle})`}
                            h={400}
                            lastFullYear={chartLastFullYear}
                          />
                        </CardBody>
                      </Card>
                    </div>
                    <div className={'mb-4'}>
                      <Card>
                        <CardBody>
                          <CardTitle>Oil Production {`(${productionUnits === BOE ? 'Bbl' : productionUnitsTitle})`}</CardTitle>
                          <WellProductionChart
                            storeIdentifier={PRODUCTION_STORE}
                            dataField={'oilTotal'}
                            yTitle={`Total Oil (${productionUnits === BOE ? 'Bbl' : productionUnitsTitle})`}
                            h={400}
                            lastFullYear={chartLastFullYear}
                          />
                        </CardBody>
                      </Card>
                    </div>
                    <div>
                      <Card>
                        <CardBody>
                          <CardTitle>NGL Production {`(${productionUnits === BOE ? 'Bbl' : productionUnitsTitle})`}</CardTitle>
                          <WellProductionChart
                            storeIdentifier={PRODUCTION_STORE}
                            dataField={'nglTotal'}
                            yTitle={`Total NGL (${productionUnits === BOE ? 'Bbl' : productionUnitsTitle})`}
                            h={400}
                            lastFullYear={chartLastFullYear}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={'Production'}>
                <WellProductionList storeIdentifier={PRODUCTION_STORE} />
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </AsyncPage>
    </main>
  );
};

export default ViewWell;
