import React from 'react';

import { DataExport } from 'tcf-upstream-shared/models';
import { DATA_EXPORTS_URL } from 'tcf-upstream-shared/urls';

import { useAppDispatch } from '../hooks';
import { getDownloadToken } from '../actions/reportActions';

export interface OwnProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  dataExport: DataExport;
  children?: React.ReactNode;
  afterDownload?: () => void;
}

// Experimented with window.open, but some browsers (Safari) block that because
// they're considered pop-ups.  This method was successfully used in the Library, so let's see
// if we can make it work here.
//
// Bottom line is that we only want subscribers and/or admins to be able to view files so we need
// a way to secure hyperlinks.  To do that, we're going to pass short lived download tokens
// through URL query params.  That means obtaining a download token and temporarily updating
// the anchor tag with it on click.
const DataExportLink = (props: OwnProps) => {
  const dispatch = useAppDispatch();
  const { dataExport, children, afterDownload, ...anchorProps } = props;
  const url = `/api${DATA_EXPORTS_URL}/${dataExport.id}/file`;
  const fileName = `${dataExport.data_type}_${dataExport.local_datetime}.zip`;
  let downloading = false;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!downloading) {
      downloading = true;
      event.preventDefault();
      const anchor = event.currentTarget as HTMLAnchorElement;
      dispatch(getDownloadToken(dataExport.id)).then((res: any) => {
        anchor.href = `${url}?dt=${res.downloadToken}`;
        anchor.click();
        anchor.href = url;
        downloading = false;
        if (afterDownload) afterDownload();
      });
    }
  };

  return (
    <a {...anchorProps} target="_blank" rel="noopener noreferrer" download={fileName} href={url} onClick={handleClick}>
      {children}
    </a>
  );
};

export default DataExportLink;
