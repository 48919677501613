import { AxiosInstance } from 'axios';
import { ApiResponse } from 'tcf-upstream-shared/models';

export class AuthApiClient {
  constructor(readonly axios: AxiosInstance) {}

  async sendLoginEmail(request: {
    email: string;
    redirectTo?: string;
  }): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>('/auth/email', request);
  }

  async login(token: string): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>('/auth/login', { token });
  }

  async logout(): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>('/auth/logout');
  }
}
