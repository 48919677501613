import React from 'react';
import { Link } from 'react-router-dom';

import { EvidenceFile, OperatorEvent, SortFields } from 'tcf-upstream-shared/models';

import { paths } from '../../../paths';
import TcfBootstrapTable, { getDefaultSorted } from '../../../components/TcfBootstrapTable';
import EvidenceLink from '../../../components/EvidenceLink';
import * as formatting from '../../../utils/formatting';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_SORT: SortFields = [{ eventDate: 'desc' }];

const downloadIcon = (
  <img src={`${process.env.PUBLIC_URL}/download.svg`} alt="Download" width="9" style={{ marginRight: '7px' }} />
);
const externalLinkIcon = (
  <img src={`${process.env.PUBLIC_URL}/external.svg`} alt="Open external link" width="9" style={{ marginRight: '7px' }} />
);

const formatType = (cell: string, input: OperatorEvent) => {
  return input.description || input.typeId || '';
};

export const formatOperatorLink = (id?: number, name?: string) => {
  const operatorName = name || (id && `Operator ${id}`) || 'Unknown operator';
  if (id === undefined || id === null) return operatorName;
  return <Link to={paths.VIEW_OPERATOR.replace(':id', id.toString())}>{operatorName}</Link>;
};

const formatURL = (eventId: number, file: EvidenceFile) => {
  const trimmedURL = (file.url || '').trim();
  if (!trimmedURL || !trimmedURL.toLowerCase().match(/^https?:\/\//)) return null;
  const shortenedURL = formatting.shortenURL(trimmedURL);
  // Since URLs are more consistently descriptive, I wanted to save the title for the file if we have one.  So,
  // if there's a file, just show the URL, otherwise, use the title for the URL.  I just didn't want to use the
  // title for both since it would be repetitive.
  return (
    <div key={`url_${eventId}_${file.fileId}`}>
      {externalLinkIcon}
      <a href={trimmedURL} target="_blank" rel="noopener noreferrer">
        {(!file.newFileName && file.title) || shortenedURL}
      </a>
    </div>
  );
};

const formatFile = (eventId: number, file: EvidenceFile) => {
  if (!file.newFileName) return null;
  const f = {
    id: file.fileId,
    original_file_name: file.originalFileName,
    new_file_name: file.newFileName,
  };
  return (
    <div key={`file_${eventId}_${file.fileId}`}>
      {downloadIcon}
      <EvidenceLink file={f} className="tableButton btn btn-link btn-sm" style={{ border: 0, marginLeft: 0 }}>
        {file.title || formatting.shortenFileName(file.originalFileName) || 'No title provided for this file'}
      </EvidenceLink>
    </div>
  );
};

const formatEvidence = (cell: string, input: OperatorEvent) => {
  return (input.files || []).map((f: EvidenceFile) => [formatFile(input.eventId, f), formatURL(input.eventId, f)]);
};

interface OwnProps {
  operatorId: number;
  events: OperatorEvent[];
}

const EventList = (props: OwnProps) => {
  const { operatorId, events } = props;

  const _formatEvent = (cell: string, input: OperatorEvent) =>
    formatting.formatFullEventLabel(
      {
        type_id: input.typeId,
        type: input.description,
        operator_id: input.operatorId,
        operator: { id: input.operatorId, name: input.operatorName } as any,
        parent_id: input.parentId,
        parent: { id: input.parentId, name: input.parentName } as any,
        event_date: input.eventDate,
        old_name: input.oldName,
        new_name: input.newName,
      } as any,
      operatorId,
      formatOperatorLink,
    );

  const columns = [
    {
      dataField: 'eventDate',
      text: 'Date',
      formatter: formatting.formatNaiveDate,
      sort: true,
    },
    {
      dataField: 'description',
      text: 'Type',
      formatter: formatType,
      sort: true,
    },
    {
      isDummyField: true,
      dataField: 'dummyField0',
      text: 'Event',
      formatter: _formatEvent,
    },
    {
      isDummyField: true,
      dataField: 'dummyField1',
      text: 'Supporting Docs',
      formatter: formatEvidence,
    },
  ];

  return (
    <TcfBootstrapTable
      allowPaging
      allowWrap
      keyField="eventId"
      columns={columns}
      dataSource={events}
      defaultSorted={getDefaultSorted(DEFAULT_SORT)}
      sizePerPage={DEFAULT_PAGE_SIZE}
      totalSize={events.length}
    />
  );
};

export default EventList;
