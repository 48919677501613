import React, { useEffect } from 'react';

import DocumentList from './components/DocumentList';

const DOCUMENTS_STORE = 'documentsListStore';

const ListDocuments = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={'flexPage'}>
      <main className={'mainContent'}>
        <h4>Recent Articles from TCF Energy Sector:</h4>
        <div className={'mt-4'}>
          <DocumentList storeIdentifier={DOCUMENTS_STORE} />
        </div>
      </main>
    </div>
  );
};

export default ListDocuments;
