import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { upsertQueryFilter, removeQueryFilter } from '../../../actions/queryActions';
import { FormGroup, Input, Label } from 'reactstrap';

const HIDE_SUBS = null;
const FILTER_FIELD = 'parentId';

interface FilterStatusProps {
  queryIdentifier: string;
}

const SubsidiaryFilter = (props: FilterStatusProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier } = props;
  const parentId: any = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.[FILTER_FIELD]);

  const onStatusChange = (event: any) => {
    if (event.target?.checked) {
      dispatch(removeQueryFilter(queryIdentifier, FILTER_FIELD));
    } else {
      dispatch(upsertQueryFilter(queryIdentifier, FILTER_FIELD, HIDE_SUBS));
    }
  };

  return (
    <FormGroup check>
      <Label check>
        <Input type="checkbox" onChange={onStatusChange} checked={typeof parentId === 'undefined'} />
        &nbsp;Subsidiaries / Acquired Entities
      </Label>
    </FormGroup>
  );
};

export default SubsidiaryFilter;
