import React from 'react';
import moment, { MomentInput } from 'moment';
import { Link } from 'react-router-dom';
import { isNumber, isString } from 'lodash';

import { AdminFile, AdminOperatorEvent } from 'tcf-upstream-shared/models';

import { paths } from '../paths';

export const standardizeText = (text?: string) => {
  return (text || '').replace(/\s+/g, ' ').trim();
};

export const formatPhone = (phone?: string) => {
  const formatted = standardizeText(phone);
  if (!formatted) return '';
  const [p, ...rest] = formatted.split(' ');
  let p2 = p;
  if (p.match(/^[A-Za-z0-9]{10}$/)) {
    p2 = `(${p.slice(0, 3)}) ${p.slice(3, 6)}-${p.slice(6, 10)}`;
  } else if (p.match(/^1[A-Za-z0-9]{10}$/)) {
    p2 = `(${p.slice(1, 4)}) ${p.slice(4, 7)}-${p.slice(7, 11)}`;
  }
  return [p2, ...rest].join(' ');
};

export const formatZIP = (zip?: string, zip2?: string) =>
  [standardizeText(zip), standardizeText(zip2)].filter(Boolean).join('-');

export const formatZIPForTable = (cell: string, input: { zip?: string; zip2?: string }) => formatZIP(input.zip, input.zip2);

export const formatCityStateZip = (city?: string, stateAbbrev?: string, zip?: string, zip2?: string) =>
  [[standardizeText(city), standardizeText(stateAbbrev)].filter(Boolean).join(', '), formatZIP(zip, zip2)]
    .filter(Boolean)
    .join(' ');

export const formatCityStateZipForTable = (
  cell: string,
  input: { city?: string; state_abbrev?: string; zip?: string; zip2?: string },
) => formatCityStateZip(input.zip, input.zip2);

export const formatStreetAddress = (address1?: string, address2?: string) =>
  [standardizeText(address1), standardizeText(address2)].filter(Boolean).join(' ');

export const formatStreetAddressForTable = (cell: string, input: { address1?: string; address2?: string }) =>
  formatStreetAddress(input.address1, input.address2);

export const formatAddress = (
  id?: number | string,
  name?: string,
  address1?: string,
  address2?: string,
  city?: string,
  stateAbbrev?: string,
  zip?: string,
  zip2?: string,
  phone?: string,
) =>
  [
    id !== null && id !== undefined ? `[${id}]` : '',
    standardizeText(name),
    standardizeText(address1),
    standardizeText(address2),
    formatCityStateZip(city, stateAbbrev, zip, zip2),
    formatPhone(phone),
  ].filter(Boolean);

export const formatAddressForTable = (
  cell: string,
  input: {
    id?: number | string;
    name?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state_abbrev?: string;
    zip?: string;
    zip2?: string;
    phone?: string;
  },
) =>
  formatAddress(
    input.id,
    input.name,
    input.address1,
    input.address2,
    input.city,
    input.state_abbrev,
    input.zip,
    input.zip2,
    input.phone,
  );

export const formatFullEventLabel = (event?: AdminOperatorEvent, operatorId?: number, fmt = formatOperatorLink) => {
  if (!event) return 'NO EVENT PROVIDED';
  if (!event.type_id) return 'EVENT TYPE NOT SPECIFIED';

  const parent = fmt(event.parent_id, event.parent?.name) ?? 'OPERATOR NOT SPECIFIED';
  const operator = fmt(event.operator_id, event.operator?.name) ?? 'OPERATOR NOT SPECIFIED';

  switch (event.type_id) {
    case 'A':
      if (event.parent?.id === operatorId) return <>Acquired {operator}</>;
      if (event.operator?.id === operatorId) return <>Was acquired by {parent}</>;
      return (
        <>
          {parent} acquired {operator}
        </>
      );
    case 'B':
      if (event.operator?.id === operatorId) return 'Filed for bankruptcy';
      return <>{operator} filed for bankruptcy</>;
    case 'D':
      if (event.parent?.id === operatorId) return <>Divested {operator}</>;
      if (event.operator?.id === operatorId) return <>Was divested by {parent}</>;
      return (
        <>
          {parent} divested {operator}
        </>
      );
    case 'M':
      if (event.parent?.id === operatorId) return <>Absorbed {operator} via merger</>;
      if (event.operator?.id === operatorId) return <>Merged into {parent}</>;
      return (
        <>
          {operator} merged into {parent}
        </>
      );
    case 'N':
      const frag = `from "${event.old_name ?? 'NOT SPECIFIED'}" to "${event.new_name ?? 'NOT SPECIFIED'}"`;
      if (event.operator?.id === operatorId) return `Renamed ${frag}`;
      return (
        <>
          {operator} renamed {frag}
        </>
      );
    case 'P':
      if (event.parent?.id === operatorId) return <>Became the parent of {operator}</>;
      if (event.operator?.id === operatorId) return <>Became a child of {parent}</>;
      return (
        <>
          {parent} became the parent of {operator}
        </>
      );
    case 'S':
      if (event.operator?.id === operatorId) return 'Shut down';
      return <>{operator} shut down</>;
    default:
      return (
        <>
          UNRECOGNIZED EVENT TYPE {event.type_id} involving {parent} and {operator}
        </>
      );
  }
};

export const formatFullEventLabelForTable = (cell: string, input: AdminOperatorEvent) => {
  return formatFullEventLabel(input);
};

export const formatEventType = (typeId?: string, description?: string) => {
  const _id = standardizeText(typeId);
  const _description = standardizeText(description);
  return _id ? `${_id}: ${_description || 'DESCRIPTION NOT FOUND'}` : _description ? `???: ${_description}` : '';
};

export const formatEventTypeFromEventForTable = (cell: string, input: AdminOperatorEvent) => {
  return formatEventType(input.type_id, input.type?.description);
};

export const formatOperatorNameNoId = (id?: number, name?: string) => {
  return `${standardizeText(name) || 'NAME NOT FOUND'}`;
};

export const formatOperatorName = (id?: number, name?: string) => {
  const _name = standardizeText(name);
  return id ? `[${id}] ${_name || 'NAME NOT FOUND'}` : _name ? `[???] ${_name}` : '';
};

export const formatOperatorLink = (id?: number, name?: string) => {
  if (id !== undefined && id !== null) {
    return <Link to={paths.admin.EDIT_OPERATOR.replace(':id', id.toString())}>{formatOperatorName(id, name)}</Link>;
  } else {
    return name ?? null;
  }
};

export const formatNaiveDate = (dateTime: MomentInput) => (dateTime && moment.utc(dateTime).format('M/D/YYYY')) || '';
export const formatNaiveDateTime = (dateTime: MomentInput) => (dateTime && moment.utc(dateTime).format('M/D/YYYY h:mma')) || '';
export const formatLocalDate = (dateTime: MomentInput) => (dateTime && moment(dateTime).format('M/D/YYYY')) || '';
export const formatLocalDateTime = (dateTime: MomentInput) => (dateTime && moment(dateTime).format('M/D/YYYY h:mma z')) || '';
export const formatDateTimeForFileName = (dateTime: MomentInput) =>
  (dateTime && moment(dateTime).format('YYYY_MM_DD_HH_mm')) || '';

export const formatLocalDateTimeBetter = (dateTime: MomentInput) =>
  (dateTime && moment(dateTime).format('YYYY-MM-DD hh:mma z')) || '';

export const formatYearMonthRange = (fromDate?: Date | string | null, toDate?: Date | string | null): string => {
  if (!(fromDate && toDate)) {
    return 'n/a';
  }
  const f = moment.utc(fromDate).format('M/YYYY');
  const t = moment.utc(toDate).format('M/YYYY');
  return f !== t ? `${f}-${t}` : f;
};

export const formatYearRange = (fromYear?: number | string, toYear?: number | string): string => {
  return fromYear && toYear ? (fromYear !== toYear ? `${fromYear}-${toYear}` : `${fromYear}`) : 'n/a';
};

export const removeTimeFromDate = (date?: Date) => {
  // It seems date form controls do not understand Javascript Dates or the ISO 8601 string format and MySQL can't
  // handle the ISO 8601 string format for DATE fields.  This function converts Javascript Date strings into simple
  // 'yyyy-mm-dd' format.  The result is not actually a Date, but a string that is typed as a Date using some typescript
  // tomfoolery to prevent compile errors.  This works for form fields and MySQL but I would recommend against using it
  // for anything else.  Returns the original "date" value if it is falsy.
  if (date === null || date === undefined) return date;
  return (moment.utc(date).format('YYYY-MM-DD') as unknown) as Date;
};

export const capitalizeFirstCharacter = (word: string) => {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const humanizeBytesUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const humanizeBytes = (size?: number) => {
  if (size === null || size === undefined) return '';

  const thresh = 1024;

  if (Math.abs(size) < thresh) return size + ' B';

  let u = -1;
  const r = 10;

  do {
    size /= thresh;
    ++u;
  } while (Math.round(Math.abs(size) * r) / r >= thresh && u < humanizeBytesUnits.length - 1);

  return size.toFixed(1) + ' ' + humanizeBytesUnits[u];
};

// Attempts to cleanly truncate text to maxLength characters on word breaks.  If we cannot break cleanly, just
// snaps the string off at maxLength characters.  If the text was truncated, adds ellipses which means the final
// result can be maxLength + 3 characters long.  Also standardizes whitespace in text to maximize space efficiency.
export const truncateText = (text?: string, maxLength = 60, truncationIndicator = '...') => {
  const t = standardizeText(text);
  const words = t.split(' ');
  let abbreviated = words[0];
  for (const word of words.slice(1)) {
    const newAbbreviated = abbreviated + ' ' + word;
    if (newAbbreviated.length > maxLength) {
      if (abbreviated) return abbreviated + truncationIndicator;
      return newAbbreviated.slice(0, maxLength) + truncationIndicator;
    }
    abbreviated = newAbbreviated;
  }
  return abbreviated;
};

export const formatId = (url: string, title: string, replace = ':id') => {
  return (id: number | string | null | undefined) => {
    const convertedId = isNumber(id) ? id.toString() : isString(id) ? id : null;
    if (!convertedId) return null;
    return (
      <Link to={url.replace(replace, convertedId)} title={title}>
        {id}
      </Link>
    );
  };
};

export const formatFileNameForDisplay = (file: AdminFile) => {
  // Try REALLY HARD to come up with something to display for a file.
  return (
    truncateText(file.title) ||
    truncateText(file.url) ||
    shortenFileName(file.original_file_name) ||
    truncateText(file.description) ||
    file.new_file_name ||
    ''
  );
};

export const formatFileNameForDisplayForTable = (cell: string, file: AdminFile) => formatFileNameForDisplay(file);

export const formatFileForDisplay = (file: AdminFile) => {
  // Try REALLY HARD to come up with something to display for a file.
  const id = file.id ? `[${file.id}]` : '';
  const name = formatFileNameForDisplay(file);
  return [id, name].filter(Boolean).join(' ') || '';
};

export const formatFileForDisplayForTable = (cell: string, file: AdminFile) => formatFileForDisplay(file);

// So we want to show as much of the file name as we can + the extension.  If the name is really long,
// truncate it, place ellipses where we trimmed, and add the extension back.  For edge cases where
// the file name contains a dot and not an extension, just truncate the file name.
export const shortenFileName = (fileName?: string, maxLength = 60, truncationIndicator = '...') => {
  if (!fileName || fileName.length <= maxLength) return fileName || '';

  const lastDotIndex = fileName.lastIndexOf('.');
  const ext = lastDotIndex > -1 ? fileName.substring(lastDotIndex + 1).trim() : '';

  // If this doesn't appear to be an extension, just return the truncated file name.
  if (ext.length > 5 || ext.length === 0) return fileName.substring(0, maxLength) + truncationIndicator;

  const name = lastDotIndex > -1 ? fileName.substring(0, lastDotIndex).trim() : '';

  // We want the file name to end up at about maxLength characters not counting ellipses.
  return [name.substring(0, maxLength - ext.length) + truncationIndicator, ext].filter(Boolean).join('');
};

export const shortenURL = (url?: string, maxLength = 60, truncationIndicator = '...') => {
  const trimmedURL = (url || '').trim();
  if (!trimmedURL || trimmedURL.length <= maxLength) return trimmedURL;
  const bits = trimmedURL.split('/');
  let abbreviated = bits[0];
  for (const bit of bits.slice(1)) {
    const newAbbreviated = abbreviated + '/' + bit;
    if (newAbbreviated.length > maxLength) {
      if (abbreviated && bit.length <= 10) return abbreviated + truncationIndicator;
      return newAbbreviated.slice(0, maxLength) + truncationIndicator;
    }
    abbreviated = newAbbreviated;
  }
  return abbreviated;
};
