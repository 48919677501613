import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { Card, CardTitle, CardBody } from 'reactstrap';

import { SearchResults, Document } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { readDocuments } from '../../../actions/documentActions';
import { paths } from '../../../paths';
import TcfBootstrapTable from '../../../components/TcfBootstrapTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

interface LocalDocumentListProps {
  storeIdentifier: string;
  queryString: string;
  label?: string;
  hideFields?: string[];
  hideCount?: boolean;
  children?: any;
}

const LocalDocumentList = (props: LocalDocumentListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true);
  const { storeIdentifier, queryString, label, hideCount } = props;
  const documents: SearchResults = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);

  useEffect(() => {
    const _query = JSON.parse(queryString);
    dispatch(readDocuments(storeIdentifier, _query));
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!documents?.total || documents.total < 1) {
    return null;
  }

  const formatTitle = (cell: any, row: Document) => (
    <a href={paths.VIEW_DOCUMENT.replace(':id', row.id!.toString())} title={'View article'}>
      {cell}
    </a>
  );

  const formatDate = (cell: any) => cell.toString().slice(0, 10);

  const columns = [
    {
      dataField: 'title',
      text: 'Title',
      formatter: formatTitle,
      headerStyle: { width: '80%' },
    },
    {
      dataField: 'meta.publishing.displayPublishedAt',
      text: 'Published',
      formatter: formatDate,
      sort: false,
      align: 'right',
      headerStyle: { width: '20%', textAlign: 'right' },
    },
  ];

  const results = documents?.results || [];
  const totalSize = documents?.total || 0;

  return (
    <Card className={'mb-4'}>
      <CardBody>
        {label ? (
          <CardTitle>
            {label}: {hideCount ? null : totalSize.toLocaleString()}
          </CardTitle>
        ) : null}
        <TcfBootstrapTable allowWrap columns={columns} dataSource={results} totalSize={totalSize} />
        {props.children}
      </CardBody>
    </Card>
  );
};

export default LocalDocumentList;
