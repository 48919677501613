import * as React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';

import { ROOT_REGION_ID_STRING } from 'tcf-upstream-shared/constants';
import { UpstreamToken } from 'tcf-upstream-shared/models';

import { paths } from '../../paths';

import Home from '../../pages/Home/Home';
import Login from '../../pages/Login/Login';
import Logout from '../../pages/Logout/Logout';
import LoginWithToken from '../../pages/Login/LoginWithToken';
import ListDocuments from '../../pages/Document/ListDocuments';
import ListOperators from '../../pages/Operator/ListOperators';
import ViewOperator from '../../pages/Operator/ViewOperator';
import ViewRegion from '../../pages/Region/ViewRegion';
import ListReports from '../../pages/Report/ListReports';
import ListSources from '../../pages/Source/ListSources';
import ListTransfers from '../../pages/Transfer/ListTransfers';
import ListWells from '../../pages/Well/ListWells';
import ViewWell from '../../pages/Well/ViewWell';
import NotFoundError from '../NotFoundError';

interface RoutesProps {
  authUser?: UpstreamToken;
  authError?: string;
}

const AdminRoutes = Loadable({
  loader: () => import('./AdminRoutes'),
  loading: () => <div />,
});

export default (props: RoutesProps) => {
  const { pathname } = useLocation();
  const { authUser, authError } = props;
  const redirectToLogin = <Redirect to={{ pathname: paths.LOGIN, state: { redirectTo: pathname } }} />;
  const redirectToTopRegion = <Redirect to={{ pathname: paths.VIEW_REGION.replace(':id', ROOT_REGION_ID_STRING) }} />;

  return (
    <>
      <Switch>
        <Route exact path={paths.LOGIN}>
          <Login />
        </Route>
        <Route exact path={paths.LOGIN_WITH_TOKEN}>
          <LoginWithToken />
        </Route>
        <Route exact path={paths.LOGOUT}>
          <Logout />
        </Route>

        {/* Redirect on an authentication error, but only if none of the above routes matched. */}
        {authError && <Route>{redirectToLogin}</Route>}

        <Route exact path={paths.HOME}>
          <Home />
        </Route>

        {/* Rest of pages requires login */}
        {!authUser && <Route>{redirectToLogin}</Route>}

        <Route exact path={paths.LIST_DOCUMENTS}>
          <ListDocuments />
        </Route>

        <Route exact path={paths.LIST_OPERATORS}>
          <ListOperators />
        </Route>
        <Route exact path={paths.VIEW_OPERATOR}>
          <ViewOperator />
        </Route>

        <Route exact path={paths.LIST_REGIONS}>
          {/*<ListRegions />*/}
          {redirectToTopRegion}
        </Route>
        <Route exact path={paths.VIEW_REGION}>
          <ViewRegion />
        </Route>

        {authUser?.canViewSources && (
          <Route exact path={paths.LIST_SOURCES}>
            <ListSources />
          </Route>
        )}

        {authUser?.canViewReports && (
          <Route exact path={paths.LIST_REPORTS}>
            <ListReports />
          </Route>
        )}

        <Route exact path={paths.LIST_TRANSFERS}>
          <ListTransfers />
        </Route>

        <Route exact path={paths.LIST_WELLS}>
          <ListWells />
        </Route>
        <Route exact path={paths.VIEW_WELL}>
          <ViewWell />
        </Route>

        <Route path={paths.admin.ROOT} render={() => (authUser?.canDataEntry ? null : <NotFoundError />)} />

        <Route>
          <NotFoundError />
        </Route>
      </Switch>

      {authUser?.canDataEntry && <AdminRoutes />}
    </>
  );
};
