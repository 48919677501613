import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { WellLookup } from '../../../reducers/wellLookupsReducer';
import { upsertQueryFilter, removeQueryFilter } from '../../../actions/queryActions';
import { FormGroup, Input, Label } from 'reactstrap';
import Definition from '../../../components/Definition';

export interface WellLookupsFilterProps {
  queryIdentifier: string;
  filterField: 'config' | 'type' | 'status' | 'producing';
  values?: { key: string; key_as_string: string; doc_count: number }[];
}

export interface StateProps {
  options: WellLookup;
  selectedOptionIds?: string[];
}

const WellLookupsFilter = (props: WellLookupsFilterProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier, filterField, values } = props;
  const options: WellLookup = useAppSelector((state) => state.wellLookups?.[filterField + 'Values']);
  const selectedOptionIds: string[] = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.[filterField]);

  const onOptionsChanged = (event: any) => {
    const { value: optionId, checked } = event.target;
    let nextSelectedOptionIds;
    if (checked) {
      nextSelectedOptionIds = [...(selectedOptionIds || [])];
      nextSelectedOptionIds.push(optionId);
    } else {
      nextSelectedOptionIds = (selectedOptionIds || []).filter((o) => o !== optionId);
    }
    if (nextSelectedOptionIds && nextSelectedOptionIds.length > 0) {
      dispatch(upsertQueryFilter(queryIdentifier, filterField, nextSelectedOptionIds));
    } else {
      dispatch(removeQueryFilter(queryIdentifier, filterField));
    }
  };

  return (
    <>
      {Object.entries(options).map((entry) => {
        const doc_count = values?.find((v) => v.key.toString() === entry[0])?.doc_count;
        return (
          <FormGroup check key={entry[0]}>
            <Label check>
              <Input
                type="checkbox"
                value={entry[0]}
                onChange={onOptionsChanged}
                checked={(selectedOptionIds || []).includes(entry[0])}
              />
              &nbsp;
              {entry[1].helpText ? (
                <Definition label={entry[1].description}>{entry[1].helpText}</Definition>
              ) : (
                entry[1].description
              )}
              {doc_count ? <span className={'text-muted'}> ({doc_count.toLocaleString()})</span> : ''}
            </Label>
          </FormGroup>
        );
      })}
    </>
  );
};

export default WellLookupsFilter;
