import React, { useState } from 'react';
import Loader from 'react-loader';
import { Card, CardBody, CardTitle, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import { ROOT_REGION_NAME } from 'tcf-upstream-shared/constants';
import { Region, RegionYear } from 'tcf-upstream-shared/models';

import { useAppSelector } from '../../../hooks';
import DeclineChart from '../../Production/components/DeclineChart';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

const GRAPH_MIN_YEAR = 2010;

interface RegionDeclineChartProps {
  storeIdentifier: string;
  benchmarkStoreIdentifier?: string;
  title: React.ReactElement; // 'YoY Pct Change, Methods 1 & 2, Operator vs United States'
  label: string; // 'Operator'
  minYear: number;
  h?: number;
  yTitle?: string;
}

const RegionDeclineChart = (props: RegionDeclineChartProps) => {
  const { storeIdentifier, benchmarkStoreIdentifier, title, label, minYear, h, yTitle } = props;

  const error: string | undefined = useAppSelector(
    (state) =>
      state.serverStores?.[storeIdentifier]?.error ||
      (benchmarkStoreIdentifier && state.serverStores?.[benchmarkStoreIdentifier]?.error),
  );
  const isFetching: boolean = useAppSelector(
    (state) =>
      state.serverStores?.[storeIdentifier]?.isFetching ||
      (benchmarkStoreIdentifier && state.serverStores?.[benchmarkStoreIdentifier]?.isFetching),
  );
  const region: Region = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const benchmark: Region = useAppSelector((state) =>
    benchmarkStoreIdentifier ? state.serverStores?.[benchmarkStoreIdentifier]?.payload : null,
  );
  const [useMethod, setUseMethod] = useState('2');

  if (isFetching) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!region) {
    return null;
  }

  const handleMethodChange = (event: any) => {
    setUseMethod(event.target.value);
  };

  const declineField = useMethod === '1' ? 'boeDecline1Pct' : 'boeDecline2Pct';
  const regionDecline = region?.prodYear
    ?.filter((ry: RegionYear) => ry.year >= (minYear || GRAPH_MIN_YEAR))
    .sort((a: RegionYear, b: RegionYear) => a.year - b.year)
    .map((ry: RegionYear) => ({
      ...ry,
      x: ry.year,
      y: ry[declineField] ? 100 * Math.min(1.0, Math.max(-1.0, ry[declineField]!)) : 0,
    }));
  const hasValues = regionDecline?.find((t: any) => t.y !== 0);

  if (!hasValues) {
    return <h5>No data for year {minYear || GRAPH_MIN_YEAR} to present.</h5>;
  }

  const benchmarkDecline =
    benchmarkStoreIdentifier &&
    benchmark?.prodYear
      ?.filter((ry: RegionYear) => ry.year >= (minYear || 2010))
      .sort((a: RegionYear, b: RegionYear) => a.year - b.year)
      .map((ry: RegionYear) => ({
        ...ry,
        x: ry.year,
        y: ry[declineField] ? 100 * Math.min(1.0, Math.max(-1.0, ry[declineField]!)) : 0,
      }));

  return (
    <Card>
      <CardBody>
        <CardTitle>
          <Row>
            <Col md={5}>{title}</Col>
            <Col md={7}>
              <Form inline className={'d-flex justify-content-end'}>
                <FormGroup>
                  <Label for={'selectmethod'} className={'mr-2'}>
                    Method:
                  </Label>
                  <Input
                    type={'select'}
                    name={'selectmethod'}
                    id={'selectmethod'}
                    onChange={handleMethodChange}
                    value={useMethod}
                  >
                    <option value={'1'}>1 - Producing wells prior 13 months.</option>
                    <option value={'2'}>2 - Producing wells prior 25 months.</option>
                  </Input>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardTitle>
        <DeclineChart
          label={label}
          benchmark={ROOT_REGION_NAME}
          decline={regionDecline!}
          benchmarkDecline={benchmarkDecline || undefined}
          h={h || 600}
          yTitle={yTitle || 'Pct change from prior year'}
        />
      </CardBody>
    </Card>
  );
};

export default RegionDeclineChart;
