import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavItem, Container } from 'reactstrap';

import { paths } from '../paths';

const MainMenuAdmin = () => {
  const { pathname } = useLocation();

  return (
    <Navbar style={{ backgroundColor: '#eee', padding: '0.25rem 0rem' }}>
      <Container id={'main-menu-container'} fluid>
        <Nav pills>
          <NavItem>
            <Link
              title={'View operators'}
              to={paths.admin.LIST_OPERATORS}
              className={'nav-link' + (pathname === paths.admin.LIST_OPERATORS ? ' active' : '')}
            >
              Operators
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={'View events'}
              to={paths.admin.LIST_EVENTS}
              className={'nav-link' + (pathname === paths.admin.LIST_EVENTS ? ' active' : '')}
            >
              Events
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={'View files'}
              to={paths.admin.LIST_FILES}
              className={'nav-link' + (pathname === paths.admin.LIST_FILES ? ' active' : '')}
            >
              Files
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={'View reports'}
              to={paths.admin.LIST_REPORT_FILES}
              className={'nav-link' + (pathname === paths.admin.LIST_REPORT_FILES ? ' active' : '')}
            >
              Reports
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={'View ETL runs'}
              to={paths.admin.LIST_ETL_RUNS}
              className={'nav-link' + (pathname === paths.admin.LIST_ETL_RUNS ? ' active' : '')}
            >
              ETL Runs
            </Link>
          </NavItem>
          <NavItem>
            <Link title={'Subscriber view'} to={paths.HOME} className={'nav-link' + (pathname === paths.HOME ? ' active' : '')}>
              Subscriber View
            </Link>
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default MainMenuAdmin;
