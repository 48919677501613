import { getAsyncActionTypes } from './getAsyncActionTypes';

// Auth actions.
export const LOGIN = getAsyncActionTypes('LOGIN');
export const LOGOUT = getAsyncActionTypes('LOGOUT');
export const SEND_LOGIN_EMAIL = getAsyncActionTypes('SEND_LOGIN_EMAIL');
export const NEW_ACCESS_TOKEN_RECEIVED = 'NEW_ACCESS_TOKEN_RECEIVED';
export const API_REQUEST_NOT_AUTHORIZED = 'API_REQUEST_NOT_AUTHORIZED';

export const UPDATE_MAINTENANCE_WINDOW = 'UPDATE_MAINTENANCE_WINDOW';
export const GET_DOWNLOAD_TOKEN = getAsyncActionTypes('GET_DOWNLOAD_TOKEN');
export const ADMIN_LINK_EVENTS_AND_FILES = getAsyncActionTypes('ADMIN_LINK_EVENTS_AND_FILES');

// Usage actions.
export const LOG_USAGE = getAsyncActionTypes('LOG_USAGE');

// Admin actions
export const ADMIN_OBJECTS_CREATE = getAsyncActionTypes('ADMIN_OBJECTS_CREATE');
export const ADMIN_OBJECTS_READ = getAsyncActionTypes('ADMIN_OBJECTS_READ');
export const ADMIN_OBJECTS_UPDATE = getAsyncActionTypes('ADMIN_OBJECTS_UPDATE');
export const ADMIN_OBJECTS_DELETE = getAsyncActionTypes('ADMIN_OBJECTS_DELETE');
export const ADMIN_OBJECTS_REMOVE_STORE = 'ADMIN_OBJECTS_REMOVE_STORE';

export const ADMIN_OBJECT_CREATE = getAsyncActionTypes('ADMIN_OBJECT_CREATE');
export const ADMIN_OBJECT_READ = getAsyncActionTypes('ADMIN_OBJECT_READ');
export const ADMIN_OBJECT_UPDATE = getAsyncActionTypes('ADMIN_OBJECT_UPDATE');
export const ADMIN_OBJECT_DELETE = getAsyncActionTypes('ADMIN_OBJECT_DELETE');
export const ADMIN_OBJECT_UPLOAD = getAsyncActionTypes('ADMIN_OBJECT_UPLOAD');
export const ADMIN_OBJECT_REMOVE_STORE = 'ADMIN_OBJECT_REMOVE_STORE';

export const ADMIN_OPERATOR_INITIALIZE_LOOKUP_CACHE = 'ADMIN_OPERATOR_INITIALIZE_LOOKUP_CACHE';
export const ADMIN_OPERATOR_ADD_TO_LOOKUP_CACHE = 'ADMIN_OPERATOR_ADD_TO_LOOKUP_CACHE';

// Typical actions for a querying/filtering pages.
export const INITIALIZE_QUERY = 'INITIALIZE_QUERY';
export const SET_QUERY = 'SET_QUERY';
export const SET_QUERY_PAGING_AND_SORTING = 'SET_QUERY_PAGING_AND_SORTING';
export const SET_QUERY_FILTER = 'SET_QUERY_FILTER';
export const UPDATE_QUERY_FILTERS = 'UPDATE_QUERY_FILTERS';
export const UPSERT_QUERY_FILTER = 'UPSERT_QUERY_FILTER';
export const REMOVE_QUERY_FILTER = 'REMOVE_QUERY_FILTER';
export const COMPLETELY_REMOVE_QUERY_FILTERS = 'COMPLETELY_REMOVE_QUERY_FILTERS';
export const ADD_TO_QUERY_FILTER_LIST = 'ADD_TO_QUERY_FILTER_LIST';
export const REMOVE_FROM_QUERY_FILTER_LIST = 'REMOVE_FROM_QUERY_FILTER_LIST';
export const RESET_QUERY_FILTER = 'RESET_QUERY_FILTER';
export const RESET_QUERY = 'RESET_QUERY';

// Typical actions for reading remote data.
export const READ_SERVER = getAsyncActionTypes('READ_SERVER');
export const RESET_SERVER_STORE = 'RESET_SERVER_STORE';

export const READ_WELL_LOOKUPS = getAsyncActionTypes('READ_WELL_LOOKUPS');

// For managing breadcrumb state.
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
