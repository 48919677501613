import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import { Field as RFField } from 'react-final-form';
import { LabelProps } from 'reactstrap/lib/Label';
import { InputProps } from 'reactstrap/lib/Input';

const stringifyValue = (value: number | string) => {
  // Due to a shortcoming in React Final Form at the time of this writing, values need to be strings else
  // options will not be selectable because, internally, RFF attempts to === the original value to the
  // value returned by the option button... which is always a string.
  const t = typeof value;
  if (!['number', 'string'].includes(t)) {
    throw new Error('TcfRadioGroup options.value must be number or string.  No other data types are currently supported.');
  }
  return t === 'string' ? value : value.toString();
};

interface Option {
  label: string;
  value: number | string;
}

interface OwnProps {
  legend?: string;
  legendProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLLegendElement>, HTMLLegendElement>;
  fieldName: string;
  options: Option[];
  labelProps?: LabelProps;
  inputProps?: InputProps;
}

const TcfRadioGroup = (props: OwnProps) => {
  const { legend, legendProps = {}, fieldName, options, labelProps = {}, inputProps = {} } = props;

  let error = '';

  return (
    <FormGroup>
      {legend ? (
        <legend {...{ className: 'field-label' }} {...legendProps}>
          {legend}
        </legend>
      ) : null}
      {options.map((option) => {
        const s = stringifyValue(option.value);
        return (
          <RFField name={fieldName} type="radio" key={s} value={s}>
            {({ input, meta }) => {
              const isLast = option.value === options[options.length - 1].value;
              if (meta.touched && meta.error) error = meta.error;
              return (
                <>
                  <FormGroup key={input.value} check>
                    <Label {...labelProps} check>
                      <Input
                        {...inputProps}
                        name={input.name}
                        type="radio"
                        value={input.value}
                        checked={input.checked}
                        onChange={input.onChange}
                      />{' '}
                      {option.label}
                    </Label>
                  </FormGroup>
                  {error && isLast ? (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                      {error}
                    </div>
                  ) : null}
                </>
              );
            }}
          </RFField>
        );
      })}
    </FormGroup>
  );
};

export default TcfRadioGroup;
