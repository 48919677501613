import React, { useState } from 'react';
import Loader from 'react-loader';
import { Card, CardBody, Form, FormGroup, Input } from 'reactstrap';

import { Region, RegionYear } from 'tcf-upstream-shared/models';

import { useAppSelector, useProductionUnits } from '../../../hooks';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import ProductionList, {
  ProductionListType,
  ProductionValueType,
  ProductionTimeUnit,
} from '../../Production/components/ProductionList';

interface RegionProductionListProps {
  storeIdentifier: string;
  disclaimer: string;
}

const RegionProductionList = (props: RegionProductionListProps) => {
  const [listType, setListType] = useState('summary');
  const [timeUnit, setTimeUnit] = useState('year');
  const [valueType, setValueType] = useState('basic');
  const { storeIdentifier, disclaimer } = props;

  const error = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const region: Region = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const { title: productionUnitsTitle, convertToSelectedUnits } = useProductionUnits();

  if (isFetching) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!region) {
    return null;
  }

  const handleListTypeChange = (event: any) => {
    const nextType = event.target.value;
    if (listType.startsWith('summary') && !nextType.startsWith('summary')) {
      setTimeUnit('quarter');
    } else if (!listType.startsWith('summary') && nextType.startsWith('summary')) {
      setTimeUnit('year');
    }
    setListType(nextType);
  };
  const handleTimeUnitChange = (event: any) => setTimeUnit(event.target.value);
  const handleValueTypeChange = (event: any) => setValueType(event.target.value);

  const productionList: any[] =
    region.prodYear
      ?.sort((a: RegionYear, b: RegionYear) => b.year - a.year)
      ?.map((p: RegionYear, index: number) => {
        const row = Object.keys(p).reduce((prev: any, k: string) => {
          const prefix: string = k.slice(0, 3);
          const lowerK = k.toLowerCase();
          if (lowerK.includes('count') || lowerK.includes('pct')) {
            prev[k] = p[k];
          } else if (['oil', 'ngl', 'boe'].includes(prefix)) {
            prev[k] = convertToSelectedUnits(p[k], 'boe');
          } else if ('gas' === prefix) {
            prev[k] = convertToSelectedUnits(p[k], 'mcfe');
          } else {
            prev[k] = p[k];
          }
          return prev;
        }, {});
        row.id = `Year${p.year}_${index}`;

        row.oilQ1 = (row.oilJan || 0) + (row.oilFeb || 0) + (row.oilMar || 0);
        row.oilQ2 = (row.oilApr || 0) + (row.oilMay || 0) + (row.oilJun || 0);
        row.oilQ3 = (row.oilJul || 0) + (row.oilAug || 0) + (row.oilSep || 0);
        row.oilQ4 = (row.oilOct || 0) + (row.oilNov || 0) + (row.oilDec || 0);

        row.nglQ1 = (row.nglJan || 0) + (row.nglFeb || 0) + (row.nglMar || 0);
        row.nglQ2 = (row.nglApr || 0) + (row.nglMay || 0) + (row.nglJun || 0);
        row.nglQ3 = (row.nglJul || 0) + (row.nglAug || 0) + (row.nglSep || 0);
        row.nglQ4 = (row.nglOct || 0) + (row.nglNov || 0) + (row.nglDec || 0);

        row.boeQ1 = (row.boeJan || 0) + (row.boeFeb || 0) + (row.boeMar || 0);
        row.boeQ2 = (row.boeApr || 0) + (row.boeMay || 0) + (row.boeJun || 0);
        row.boeQ3 = (row.boeJul || 0) + (row.boeAug || 0) + (row.boeSep || 0);
        row.boeQ4 = (row.boeOct || 0) + (row.boeNov || 0) + (row.boeDec || 0);

        row.gasQ1 = (row.gasJan || 0) + (row.gasFeb || 0) + (row.gasMar || 0);
        row.gasQ2 = (row.gasApr || 0) + (row.gasMay || 0) + (row.gasJun || 0);
        row.gasQ3 = (row.gasJul || 0) + (row.gasAug || 0) + (row.gasSep || 0);
        row.gasQ4 = (row.gasOct || 0) + (row.gasNov || 0) + (row.gasDec || 0);

        row.oilH1 = (row.oilQ1 || 0) + (row.oilQ2 || 0);
        row.oilH2 = (row.oilQ3 || 0) + (row.oilQ4 || 0);

        row.nglH1 = (row.nglQ1 || 0) + (row.nglQ2 || 0);
        row.nglH2 = (row.nglQ3 || 0) + (row.nglQ4 || 0);

        row.boeH1 = (row.boeQ1 || 0) + (row.boeQ2 || 0);
        row.boeH2 = (row.boeQ3 || 0) + (row.boeQ4 || 0);

        row.gasH1 = (row.gasQ1 || 0) + (row.gasQ2 || 0);
        row.gasH2 = (row.gasQ3 || 0) + (row.gasQ4 || 0);
        return row;
      }) || [];

  if (!productionList?.length) {
    return <h5>No production data found for this region.</h5>;
  }

  const regionApi = region?.apiCode || '';

  return (
    <Card>
      <CardBody>
        <div className={'mb-3'}>
          <Form inline>
            <FormGroup className={'my-1'}>
              <Input type="select" id="selectType" onChange={handleListTypeChange} value={listType}>
                <option value="summary">Summary</option>
                <option value="summaryByWellClass">Summary by Well Class</option>
                <option value="boeMonth">Total {productionUnitsTitle}</option>
                <option value="gasMonth">Gas</option>
                <option value="oilMonth">Oil</option>
                <option value="nglMonth">NGL</option>
              </Input>
            </FormGroup>
            <span className={'mx-2'}>by</span>
            <FormGroup className={'my-1'}>
              <Input type="select" id="selectValueType" onChange={handleTimeUnitChange} value={timeUnit}>
                {listType.startsWith('summary') && <option value="year">year</option>}
                {!listType.startsWith('summary') && <option value="half">half year</option>}
                {!listType.startsWith('summary') && <option value="quarter">quarter</option>}
                {!listType.startsWith('summary') && <option value="month">month</option>}
              </Input>
            </FormGroup>
            <span className={'mx-2'}>listing</span>
            <FormGroup className={'my-1'}>
              <Input type="select" id="selectValueType" onChange={handleValueTypeChange} value={valueType}>
                <option value="basic">reported values</option>
                <option value="change">YoY change</option>
                <option value="pctChange">YoY change %</option>
              </Input>
            </FormGroup>
          </Form>
        </div>
        <div className={'font-italic my-1'}>{disclaimer}</div>
        <ProductionList
          productionList={productionList}
          listType={listType as ProductionListType}
          timeUnit={timeUnit as ProductionTimeUnit}
          valueType={valueType as ProductionValueType}
          exportFileName={`region_${regionApi}_${listType}_${timeUnit}_${valueType}_production.csv`}
        />
      </CardBody>
    </Card>
  );
};

export default RegionProductionList;
