import decodeJwt from 'jwt-decode';
import { local } from './storage';

import { TokenScope } from 'tcf-upstream-shared/models';

export interface DecodedToken {
  jti?: string;
  iat: number;
  exp: number;
  sub: string;
  scope: TokenScope;
  email: string;
  firstName?: string;
  lastName?: string;
  subscription?: string;
  entitlements?: string[];
}

export class Token {
  private decodedToken?: DecodedToken;

  constructor(private name: string, private storage: Storage) {}

  get(): string | undefined {
    this.decode();
    return this.storage.getItem(this.name) || undefined;
  }

  decode(): DecodedToken | undefined {
    let tokenString: string | undefined;
    if (!this.decodedToken) {
      tokenString = this.storage.getItem(this.name) || undefined;
      if (tokenString) {
        this.decodedToken = decodeJwt<DecodedToken>(tokenString);
      }
    }

    if (tokenString && !this.decodedToken) {
      this.delete();
    }

    return this.decodedToken;
  }

  set(value: string | null) {
    this.decodedToken = undefined;
    if (value) {
      this.storage.setItem(this.name, value);
    } else {
      this.delete();
    }
  }

  delete() {
    this.decodedToken = undefined;
    this.storage.removeItem(this.name);
  }
}

export const accessToken = new Token('access_token', local);
