import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import { Card, CardTitle, CardBody } from 'reactstrap';
import moment from 'moment';

import { SearchResults, Region, OperatorRegionYear } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector, useProductionUnits } from '../../../hooks';
import { readRegions } from '../../../actions/regionActions';
import { paths } from '../../../paths';
import TcfBootstrapTable, { formatNumber } from '../../../components/TcfBootstrapTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

interface LocalRegionListProps {
  storeIdentifier: string;
  queryString: string;
  label?: string;
  hideFields?: string[];
  hideCount?: boolean;
  children?: any;
}

const LocalRegionList = (props: LocalRegionListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true);
  const { storeIdentifier, queryString, label, hideCount, children } = props;
  const hideFields = props.hideFields || [];

  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const regions: SearchResults = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);

  const { title: productionUnitsTitle, convertToSelectedUnits } = useProductionUnits();

  useEffect(() => {
    const _query = JSON.parse(queryString);
    dispatch(readRegions(storeIdentifier, _query));
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!regions?.total || regions.total < 1) {
    return null;
  }

  const formatCountyName = (cell: any, row: Region) => (
    <Link to={paths.VIEW_REGION.replace(':id', row.id!.toString())} title={'View region'}>
      {`${cell}${row.level === 3 ? ' County, ' + row.stateAbbrev : ''}`}
    </Link>
  );

  const formatProductionDataFromTo = (cell: any, row: Region) => {
    const fromDate = row?.dataFrom ? moment.utc(row.dataFrom).format('M/YYYY') : '';
    const toDate = row?.dataTo ? moment.utc(row.dataTo).format('M/YYYY') : '';
    return fromDate || toDate ? `${fromDate}-${toDate}` : '';
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: formatCountyName,
      sort: false,
      hidden: hideFields.includes('name'),
    },
    {
      dataField: 'stateAbbrev',
      text: 'State',
      sort: false,
      hidden: hideFields.includes('stateAbbrev'),
    },
    {
      dataField: 'boeTotal',
      text: `Total ${productionUnitsTitle}`,
      sort: false,
      align: 'right',
      formatter: formatNumber,
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'dataTo',
      text: 'Production Data',
      sort: false,
      hidden: hideFields.includes('dataTo'),
      align: 'right',
      formatter: formatProductionDataFromTo,
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'producingOperatorCount',
      text: 'Producing Operators',
      sort: false,
      hidden: hideFields.includes('producingOperatorCount'),
      formatter: formatNumber,
      align: 'right',
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'wellCount',
      text: 'Wells',
      sort: false,
      hidden: hideFields.includes('wellCount'),
      formatter: formatNumber,
      align: 'right',
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'producingWellCount',
      text: 'Producing Wells',
      sort: false,
      hidden: hideFields.includes('producingWellCount'),
      formatter: formatNumber,
      align: 'right',
      headerStyle: { textAlign: 'right' },
    },
  ];

  const dataSource = (regions?.results || []).map((o) => ({
    ...o,
    boeTotal: convertToSelectedUnits(o.boeTotal),
    prodYear: (o.prodYear || []).map((py: OperatorRegionYear) => ({ ...py, boeTotal: convertToSelectedUnits(py.boeTotal) })),
  }));
  const totalSize = regions?.total || 0;

  return (
    <Card className={'mb-4'}>
      <CardBody>
        {label ? (
          <CardTitle>
            {label}: {hideCount ? null : totalSize.toLocaleString()}
          </CardTitle>
        ) : null}
        <TcfBootstrapTable allowWrap columns={columns} dataSource={dataSource} totalSize={totalSize} />
        {children}
      </CardBody>
    </Card>
  );
};

export default LocalRegionList;
