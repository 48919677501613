// Displays table from data that is similar to production records, including region_year and operator_region_year
import React from 'react';
import { Link } from 'react-router-dom';

import { Production } from 'tcf-upstream-shared/models';

import { useProductionUnits } from '../../../hooks';
import { paths } from '../../../paths';
import TcfBootstrapTable, { formatNumber, formatStates, formatPct } from '../../../components/TcfBootstrapTable';

export type ProductionListType = 'summary' | 'summaryByWellClass' | 'boe' | 'gas' | 'oil' | 'ngl';
export type ProductionTimeUnit = 'year' | 'half' | 'quarter' | 'month';
export type ProductionValueType = 'basic' | 'change' | 'pctChange';

interface ProductionListProps {
  productionList: any[];
  listType: ProductionListType;
  timeUnit: ProductionTimeUnit;
  valueType?: ProductionValueType;
  exportFileName: string;
}

const PERIOD_UNITS = {
  month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  quarter: ['Q1', 'Q2', 'Q3', 'Q4'],
  half: ['H1', 'H2'],
  year: ['Total'],
};
// const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
// const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
// const halves = ['H1', 'H2'];

const ProductionList = (props: ProductionListProps) => {
  const { productionList, listType, exportFileName } = props;
  const valueType = props.valueType || 'basic';
  const timeUnit = props.timeUnit || 'year';
  const { title: productionUnitsTitle } = useProductionUnits();
  const totalSize = productionList.length;
  const columnPrefix = valueType === 'change' ? 'YoY Change in ' : valueType === 'pctChange' ? 'YoY % Change in ' : '';
  const numberFormatter = valueType === 'pctChange' ? formatPct : formatNumber;
  const listTypePrefix = listType.slice(0, 3);
  const periodUnits = PERIOD_UNITS[timeUnit];

  const productionListLength = productionList?.length || 0;
  if (productionListLength < 1) {
    return null;
  }

  if (['change', 'pctChange'].includes(valueType)) {
    productionList.forEach((row: any, index: number) => {
      Object.keys(row).forEach((key: any) => {
        const lowerKey = key.toLowerCase();
        const prefix: string = lowerKey.slice(0, 3);
        if (
          lowerKey.includes('count') ||
          lowerKey.includes('pct') ||
          lowerKey.includes('month') ||
          ['gas', 'oil', 'ngl', 'boe'].includes(prefix)
        ) {
          const currentValue = row[key];
          const nextIndex = index + 1;
          const previousValue =
            productionListLength > nextIndex && productionList[nextIndex].year === row.year - 1
              ? productionList[nextIndex][key]
              : undefined;
          if (valueType === 'change') {
            row[key] =
              currentValue == null
                ? previousValue == null
                  ? null
                  : -previousValue
                : previousValue == null
                ? currentValue
                : currentValue - previousValue;
          } else if (valueType === 'pctChange') {
            if (!currentValue && !previousValue) {
              row[key] = null;
            } else if (!currentValue) {
              row[key] = -1;
            } else if (!previousValue) {
              row[key] = 1;
            } else {
              row[key] = currentValue / previousValue - 1;
            }
          }
        }
      });
    });
  }

  const formatOperator: any = (cell: string, row: Production) =>
    row.operatorId ? (
      <Link to={paths.VIEW_OPERATOR.replace(':id', row.operatorId!.toString())} title={'View operator'}>
        {cell}
      </Link>
    ) : (
      cell
    );

  const columns: any[] = [
    {
      dataField: 'year',
      text: 'Year',
      sort: true,
      headerStyle: { width: '6%' },
    },
  ];

  if (productionList[0].hasOwnProperty('operatorName')) {
    columns.push({
      dataField: 'operatorName',
      text: 'Operator',
      sort: true,
      formatter: formatOperator,
      headerStyle: { width: '10%' },
    });
  }

  if (listType === 'summary') {
    if (productionList[0].hasOwnProperty('operatorCount')) {
      columns.push({
        dataField: 'operatorCount',
        text: columnPrefix + 'Operators',
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '6%', textAlign: 'right' },
      });
    }
    if (productionList[0].hasOwnProperty('wellStates')) {
      columns.push({
        dataField: 'wellStates',
        text: 'Producing in States',
        sort: false,
        formatter: formatStates,
        headerStyle: { width: '6%' },
      });
    }
  }

  if (listType === 'summaryByWellClass') {
    columns.push(
      {
        dataField: 'wellCountHoriz',
        text: columnPrefix + 'Horizontal Producing Wells',
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '6%', textAlign: 'right' },
      },
      {
        dataField: 'wellCountVert',
        text: columnPrefix + 'Vertical Producing Wells',
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '6%', textAlign: 'right' },
      },
      {
        dataField: 'wellCountUnkn',
        text: columnPrefix + 'Unknown Class Producing Wells',
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '6%', textAlign: 'right' },
      },
    );
  }

  if (productionList[0].hasOwnProperty('wellCount')) {
    columns.push({
      dataField: 'wellCount',
      text: columnPrefix + 'Total Producing Wells',
      sort: true,
      formatter: numberFormatter,
      align: 'right',
      headerStyle: { width: '6%', textAlign: 'right' },
    });
  }

  if (listType === 'summaryByWellClass') {
    columns.push(
      {
        dataField: 'boeTotalHoriz',
        text: columnPrefix + `Horizontal ${productionUnitsTitle}`,
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '6%', textAlign: 'right' },
      },
      {
        dataField: 'boeTotalVert',
        text: columnPrefix + `Vertical ${productionUnitsTitle}`,
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '6%', textAlign: 'right' },
      },
      {
        dataField: 'boeTotalUnkn',
        text: columnPrefix + `Unknown Class ${productionUnitsTitle}`,
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '6%', textAlign: 'right' },
      },
    );
  }

  if (listType === 'summary') {
    columns.push(
      {
        dataField: 'gasTotal',
        text: columnPrefix + 'Total Gas',
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '12%', textAlign: 'right' },
      },
      {
        dataField: 'oilTotal',
        text: columnPrefix + 'Total Oil',
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '12%', textAlign: 'right' },
      },
      {
        dataField: 'nglTotal',
        text: columnPrefix + 'Total NGL',
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '12%', textAlign: 'right' },
      },
    );
  }

  if (listType === 'summary' || listType === 'summaryByWellClass') {
    columns.push({
      dataField: 'boeTotal',
      text: columnPrefix + `Total ${productionUnitsTitle}`,
      sort: true,
      formatter: numberFormatter,
      align: 'right',
      headerStyle: { width: '12%', textAlign: 'right' },
    });
  }

  if (['gas', 'oil', 'ngl', 'boe'].includes(listTypePrefix)) {
    for (const period of periodUnits) {
      columns.push({
        dataField: listTypePrefix.concat(period),
        text: columnPrefix + period,
        sort: true,
        formatter: numberFormatter,
        align: 'right',
        headerStyle: { width: '6%', textAlign: 'right' },
      });
    }
    columns.push({
      dataField: listTypePrefix.concat('Total'),
      text: columnPrefix + `Total ${productionUnitsTitle}`,
      sort: true,
      formatter: numberFormatter,
      align: 'right',
      headerStyle: { width: '6%', textAlign: 'right' },
    });
  }

  return (
    <TcfBootstrapTable columns={columns} dataSource={productionList} totalSize={totalSize} exportFileName={exportFileName} />
  );
};

export default ProductionList;
