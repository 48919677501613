import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Jumbotron } from 'reactstrap';

import { ROOT_REGION_ID, ROOT_REGION_TITLE } from 'tcf-upstream-shared/constants';

import { useAppSelector } from '../../hooks';
import { UpstreamToken } from 'tcf-upstream-shared/models';
import { paths } from '../../paths';
import TopDocumentsList from '../Document/components/LocalDocumentList';
import LocalOperatorsList from '../Operator/components/LocalOperatorList';
import LocalWellList from '../Well/components/LocalWellList';
import TopLevelRegion from '../Region/components/TopLevelRegion';
import LocalRegionList from '../Region/components/LocalRegionList';
import RecentSourceList from '../Source/components/RecentSourceList';
import RecentTransferList from '../Transfer/components/RecentTransferList';

const OPERATORS_STORE = 'homeOperatorsStore';
const OPERATORS_QUERYSTRING = JSON.stringify({
  skip: 0,
  limit: 10,
  sort: [{ regionSummary: { field: 'boeTotal', regionId: ROOT_REGION_ID, order: 'desc' } }],
  filters: { isProducing: true, parentId: null },
});
const OPERATORS_HIDE_FIELDS: string[] = ['city', 'wellCount', 'wellStates'];

const DOCUMENTS_STORE = 'homeDocumentsStore';
const DOCUMENTS_QUERYSTRING = JSON.stringify({
  limit: 5,
});

const WELLS_STORE = 'homeWellsStore';
const WELLS_QUERYSTRING = JSON.stringify({
  skip: 0,
  limit: 10,
  sort: [{ 'prodSummary.boeTotal': 'desc' }],
  filters: { status: 'ACTV' },
});
const WELLS_HIDE_FIELDS: string[] = ['api', 'stateAbbrev', 'type', 'config', 'status', 'spud'];

const STATES_STORE = 'homeStatesStore';
const STATES_QUERYSTRING = JSON.stringify({
  skip: 0,
  limit: 10,
  sort: [{ boeTotal: 'desc' }],
  filters: { level: 2 },
});
const STATES_HIDE_FIELDS: string[] = ['stateAbbrev', 'dataFrom', 'dataTo', 'producingOperatorCount', 'wellCount'];

const COUNTIES_STORE = 'homeCountiesStore';
const COUNTIES_QUERYSTRING = JSON.stringify({
  skip: 0,
  limit: 10,
  sort: [{ boeTotal: 'desc' }],
  filters: { level: 3 },
});
const COUNTIES_HIDE_FIELDS: string[] = ['dataFrom', 'dataTo', 'producingOperatorCount', 'wellCount', 'stateAbbrev'];

const SOURCES_STORE = 'homeSourcesStore';
const SOURCES_QUERYSTRING = JSON.stringify({
  skip: 0,
  limit: 10,
  sort: [{ processedOn: 'desc' }, { stateAbbrev: 'asc' }, { dataType: 'asc' }],
});

const TRANSFERS_STORE = 'homeTransfersStore';
const TRANSFERS_QUERYSTRING = JSON.stringify({
  skip: 0,
  limit: 10,
  sort: [{ transferDate: 'desc' }],
});

const IMAGES_BASE_URL = `${process.env.PUBLIC_URL}/`;
const styles = {
  jumbo: {
    backgroundImage: `url(${IMAGES_BASE_URL}overview.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundBlendMode: 'lighten',
  },
};

const Home = () => {
  const authUser: UpstreamToken | undefined = useAppSelector((state) => state.auth.authUser);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={'mainContent'}>
      <Jumbotron style={styles.jumbo}>
        <h2>Welcome to The Capitol Forum Energy Platform</h2>
        <p>
          The TCF energy platform provides you with access to our investigative journalism in Energy, together with information
          on oil & gas production from {ROOT_REGION_TITLE} in the regions we cover.
        </p>
        <hr className="my-2" />
        <p className="lead">
          <a title={'Visit The Capitol Forum'} className={'btn btn-secondary'} href={paths.TCF}>
            Learn more
          </a>
          {!authUser && (
            <Link title={'Go to page to request a login email'} className={'btn btn-primary ml-4'} to={paths.LOGIN}>
              Login
            </Link>
          )}
        </p>
      </Jumbotron>
      {authUser ? (
        <div className={'mt-4'}>
          <Row>
            <Col lg={6} xl={4} className={'mb-4'}>
              <TopDocumentsList
                storeIdentifier={DOCUMENTS_STORE}
                queryString={DOCUMENTS_QUERYSTRING}
                label={'Recent Articles from TCF Energy Sector'}
                hideCount
              >
                <Link to={paths.LIST_DOCUMENTS}>
                  <Button>View All</Button>
                </Link>
              </TopDocumentsList>
            </Col>
            <Col lg={6} xl={4} className={'mb-4'}>
              {authUser?.canViewSources ? (
                <RecentSourceList
                  storeIdentifier={SOURCES_STORE}
                  queryString={SOURCES_QUERYSTRING}
                  label={'Recent Data Source Updates'}
                >
                  <Link to={paths.LIST_SOURCES}>
                    <Button>View All</Button>
                  </Link>
                </RecentSourceList>
              ) : (
                <p className="font-italic text-center pt-5">(Subscribe to see data sources)</p>
              )}
            </Col>
            <Col lg={6} xl={4} className={'mb-4'}>
              <TopLevelRegion />
            </Col>
            <Col lg={6} xl={4} className={'mb-4'}>
              <LocalOperatorsList
                storeIdentifier={OPERATORS_STORE}
                queryString={OPERATORS_QUERYSTRING}
                hideFields={OPERATORS_HIDE_FIELDS}
                label={'Top Operators'}
                hideCount
              >
                <Link to={paths.LIST_OPERATORS}>
                  <Button>View All</Button>
                </Link>
              </LocalOperatorsList>
            </Col>
            <Col lg={6} xl={4} className={'mb-4'}>
              <LocalWellList
                storeIdentifier={WELLS_STORE}
                queryString={WELLS_QUERYSTRING}
                hideFields={WELLS_HIDE_FIELDS}
                label={'Top Active Wells'}
                hideCount
              >
                <Link to={paths.LIST_WELLS}>
                  <Button>View All</Button>
                </Link>
              </LocalWellList>
            </Col>
            <Col lg={6} xl={4} className={'mb-4'}>
              <LocalRegionList
                storeIdentifier={STATES_STORE}
                queryString={STATES_QUERYSTRING}
                hideFields={STATES_HIDE_FIELDS}
                label={'Top States'}
                hideCount
              >
                <Link to={paths.LIST_REGIONS}>
                  <Button>View All</Button>
                </Link>
              </LocalRegionList>
            </Col>
            <Col lg={6} xl={4} className={'mb-4'}>
              <LocalRegionList
                storeIdentifier={COUNTIES_STORE}
                queryString={COUNTIES_QUERYSTRING}
                hideFields={COUNTIES_HIDE_FIELDS}
                label={'Top Counties'}
                hideCount
              >
                <Link to={paths.LIST_REGIONS}>
                  <Button>View All</Button>
                </Link>
              </LocalRegionList>
            </Col>
            <Col lg={6} xl={4} className={'mb-4'}>
              <RecentTransferList
                storeIdentifier={TRANSFERS_STORE}
                queryString={TRANSFERS_QUERYSTRING}
                label={'Recent Well Transfers'}
              >
                <Link to={paths.LIST_TRANSFERS}>
                  <Button>View All</Button>
                </Link>
              </RecentTransferList>
            </Col>
          </Row>
        </div>
      ) : null}
    </main>
  );
};

export default Home;
