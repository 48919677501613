import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

export { default as useDelayedRender } from './useDelayedRender';
export { default as useProductionUnits, BOE, MCFE, TITLES } from './useProductionUnits';
export { default as useKeywordDebouncer } from './useKeywordDebouncer';
export { default as usePopupToggler } from './usePopupToggler';
export { default as useInterval } from './useInterval';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
