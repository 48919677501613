import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { AppState } from '../reducers';
import { GET_DOWNLOAD_TOKEN, READ_SERVER } from './actionTypes';

export function readDataExport(storeIdentifier: string, id: string | number) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.dataExports.get(id)).data,
    storeIdentifier,
  });
}

export function updateDataExport(storeIdentifier: string, id: string | number, updatedAt: Date | string, patch: any) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.dataExports.patch(id, updatedAt, patch)).data,
    storeIdentifier,
  });
}

export function getDownloadToken(id: string) {
  return asyncAction<AppState>({
    actionType: GET_DOWNLOAD_TOKEN,
    func: async () => (await library.dataExports.getDownloadToken(id)).data,
  });
}
