import React from 'react';
import { Table, Card, CardBody, CardTitle } from 'reactstrap';

import { Operator } from 'tcf-upstream-shared/models';
import { formatYearMonthRange } from '../../../utils/formatting';
import Definition from '../../../components/Definition';

interface OwnProps {
  operator: Operator;
}

const OperatorDetails = (props: OwnProps) => {
  const { operator } = props;
  const aliases = operator?.alias?.filter((a) => a !== operator.name) || [];

  const operatorRegion = operator?.regionSummary?.[0];
  const dataFrom = operatorRegion?.dataFrom;
  const dataTo = operatorRegion?.dataTo;

  return operator ? (
    <Card className={'mb-4'}>
      <CardBody>
        <CardTitle>About</CardTitle>
        <Table responsive striped size={'sm'}>
          <tbody>
            {aliases && aliases.length > 0 ? (
              <>
                <tr>
                  <th colSpan={2}>
                    <Definition label="Name Variations">
                      Aliases and alternate names associated with the operator including previous names, common alternative
                      spellings or misspellings, and name changes due to mergers or acquisitions.
                    </Definition>
                  </th>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <ul>
                      {aliases.map((a: string) => (
                        <li key={a}>{a}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            ) : null}
            {operator.ticker ? (
              <tr>
                <th>
                  <Definition label="Ticker">
                    The stock symbol representing the operator's publicly listed security, where applicable.
                  </Definition>
                </th>
                <td style={{ textAlign: 'right' }}>
                  {operator.isTickerActive ? operator.ticker : <span style={{ color: '#777' }}>{operator.ticker}</span>}
                </td>
              </tr>
            ) : null}
            {operator.ticker && operator.tickerExchange ? (
              <tr>
                <th>Exchange</th>
                <td style={{ textAlign: 'right' }}>{operator.tickerExchange}</td>
              </tr>
            ) : null}
            {operator.isActive != null && (
              <tr>
                <th>
                  <Definition label="Status">
                    Operator status, reflected as "Active" or "Inactive" is reported by states or inferred by The Capitol Forum.
                    New York provides statuses as Active or Inactive. West Virginia, and Virginia provide an "Active Operators"
                    dataset that implies status of Active, and by omission, Inactive. Kentucky's "Active" list refers to the
                    operator's permit status. Pennsylvania and Tennessee do not provide a status. Ohio provides four statuses in
                    addition to Active and Inactive – Suspended, Defunct, Temporary, and Cancelled – which are reflected as
                    Inactive. If an operator is reported as Active by any state, it will be reported here as Active even if it
                    is reported as Inactive by other states.
                  </Definition>
                </th>
                <td style={{ textAlign: 'right' }}>{operator.isActive ? 'Active' : 'Inactive'}</td>
              </tr>
            )}
            <tr>
              <th>
                <Definition label="Producing">
                  A value of "Yes" reflects nonzero production records reported to a state by the operator and its acquired
                  entities and subsidiaries within the most recent full year or later, where applicable. "No" indicates that no
                  such records have been reported during the same time period. Note: an operator may have transferred any or all
                  of its producing wells to other operators since the date of the most recently released production record and
                  vice versa.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{operator.isProducing ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Producing Wells">
                  The current number of wells owned by the operator and its acquired entities and subsidiaries that have nonzero
                  production within the most recent full year or later, where applicable. Note: this count reflects recent
                  transfers to or from the operator to the extent such information is available, and therefore may not match the
                  number of wells for which production has been reported by this operator.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{operatorRegion?.producingWellCount?.toLocaleString() || 0}</td>
            </tr>
            <tr>
              <th>
                <Definition label="All Wells">
                  The current number of all wells owned by the operator and its acquired entities and subsidiaries.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{operatorRegion?.wellCount?.toLocaleString() || 0}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Well States">
                  States in which the operator and its acquired entities and subsidiaries' wells are located.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{operatorRegion?.wellStates?.join(', ') || ''}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Production Data">
                  Earliest and latest reported production periods by this operator and its acquired entities and subsidiaries.
                  The latest "to" month shown does not necessarily indicate that data is complete through that month, but simply
                  that the operator has submitted at least one production record for the period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{formatYearMonthRange(dataFrom, dataTo)}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Total BOE">
                  Barrels of oil equivalent of natural gas, oil, and natural gas liquids produced by this operator and its
                  acquired entities and subsidiaries during the Production Data period. Acquired and divested entities'
                  production volumes are added or subtracted in relation to the date of the event.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {operatorRegion?.boeTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 0}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Mcfe">
                  Thousands of cubic feet equivalent of natural gas, oil, and natural gas liquids produced by this operator and
                  its acquired entities and subsidiaries during the Production Data period. Acquired and divested entities'
                  production volumes are added or subtracted in relation to the date of the event.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {((operatorRegion?.boeTotal ?? 0) * 6).toLocaleString(undefined, { maximumFractionDigits: 0 }) || 0}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Gas (Mcf)">
                  Thousands of cubic feet of natural gas produced by this operator and its acquired entities and subsidiaries
                  during the Production Data period. Acquired and divested entities' production volumes are added or subtracted
                  in relation to the date of the event.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {operatorRegion?.gasTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 0}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Oil (Bbl)">
                  Barrels of oil produced by this operator and its acquired entities and subsidiaries during the Production Data
                  period. Acquired and divested entities' production volumes are added or subtracted in relation to the date of
                  the event.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {operatorRegion?.oilTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 0}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total NGL (Bbl)">
                  Barrels of natural gas liquids produced by this operator and its acquired entities and subsidiaries during the
                  Production Data period. Acquired and divested entities' production volumes are added or subtracted in relation
                  to the date of the event.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {operatorRegion?.nglTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 0}
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  ) : null;
};

export default OperatorDetails;
