import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import { SUPPORTED_STATES, ROOT_REGION_ID } from 'tcf-upstream-shared/constants';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { upsertQueryFilter } from '../../../actions/queryActions';

const REGION_SUMMARY_FILTER = 'regionSummary';
const WELL_STATES_FILTER = 'wellStates';

export interface OperatorStatesFilterProps {
  queryIdentifier: string;
  values?: { key: string; key_as_string: string; doc_count: number }[];
}

const OperatorStatesFilter = (props: OperatorStatesFilterProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier, values } = props;
  const filters: any = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters);
  const selectedStates = filters?.[REGION_SUMMARY_FILTER]?.[WELL_STATES_FILTER];

  const onStatesChanged = (event: any) => {
    const { value: state, checked } = event.target;
    let nextSelectedStates;
    if (checked) {
      nextSelectedStates = [...(selectedStates || [])];
      nextSelectedStates.push(state);
    } else {
      nextSelectedStates = (selectedStates || []).filter((w: any) => w !== state);
    }

    const summaryFilter = filters?.[REGION_SUMMARY_FILTER];
    let newSummaryFilter;
    if (nextSelectedStates?.length > 0) {
      newSummaryFilter = (summaryFilter && { ...summaryFilter }) || { regionId: ROOT_REGION_ID };
      newSummaryFilter.wellStates = nextSelectedStates;
    } else if (summaryFilter) {
      newSummaryFilter = { ...summaryFilter };
      delete newSummaryFilter.wellStates;
    }
    if (newSummaryFilter) {
      dispatch(upsertQueryFilter(queryIdentifier, REGION_SUMMARY_FILTER, newSummaryFilter));
    }
  };

  return (
    <>
      {SUPPORTED_STATES.map((state) => {
        const doc_count = values?.find((v) => v.key === state.code)?.doc_count;
        return (
          <FormGroup check key={state.code}>
            <Label check>
              <Input
                type="checkbox"
                value={state.code}
                onChange={onStatesChanged}
                checked={(selectedStates || []).includes(state.code)}
              />
              &nbsp;{state.name}
              {<span className={'text-muted'}> ({doc_count ? doc_count.toLocaleString() : 0})</span>}
            </Label>
          </FormGroup>
        );
      })}
    </>
  );
};

export default OperatorStatesFilter;
