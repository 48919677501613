import isString from 'lodash/isString';

export const qsToObject = (qs: string, keyWhitelist: string[] = []) => {
  qs = qs.startsWith('?') ? qs.substring(1) : qs;
  const result = {};
  qs.split('&')
    .map((pair) => pair.split('='))
    .forEach((pair) => {
      if (pair.length === 2 && pair[0].length >= 1) {
        const key = decodeURIComponent(pair[0]);
        keyWhitelist.some((k) => k === key);
        result[key] = decodeURIComponent(pair[1]);
      }
    });
  return result;
};

export const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

const normalize = (v: unknown) => (v === '' || v === undefined ? null : v);

export const diffObjects = (o1: { [key: string]: any }, o2: { [key: string]: any }): { [key: string]: any } => {
  // Returns all the keys/values from o1 that either do not exist in or are different than o2.  Performs a
  // shallow comparison.
  const keyList = Array.from(new Set([...Object.keys(o1), ...Object.keys(o2)]));
  return keyList.reduce((a: { [key: string]: any }, k: string) => {
    const v1 = normalize(o1?.[k]);
    const v2 = normalize(o2?.[k]);
    if (v1 !== v2) a[k] = v1;
    return a;
  }, {});
};

export const convertStringIdToNumeric = (id?: string) =>
  id !== null && id !== undefined && /^[0-9]{1,16}$/.test(id) ? Number.parseInt(id, 10) : undefined;

export const range = (start: number, end: number) => Array.from({ length: end - start + 1 }, (_, i) => start + i);

export const escapeRegExp = (s: string) => {
  // Escape RegExp specific characters in a string so the string can be used in a RegExp pattern.
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const isSafeIntegerString = (n: string) => {
  return (isString(n) && n.match(/^-?\d{1,16}$/) && Number.isSafeInteger(parseInt(n, 10))) || false;
};
