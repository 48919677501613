import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '../../hooks';
import { resetQueryFilter } from '../../actions/queryActions';
import Sidebar from '../../components/Sidebar';
import TransferList from './components/TransferList';
import TransfersFilterForm, { VisibleFilters } from './components/TransfersFilterForm';

const TRANSFERS_STORE = 'transfersListStore';
const TRANSFERS_QUERY = 'transfersListQuery';

const ListTransfers = () => {
  const dispatch = useAppDispatch();

  const initVisibleFilters: VisibleFilters = {
    location: true,
    config: false,
    wellType: false,
    status: false,
    producing: false,
  };
  const [visibleFilters, setVisibleFilters] = useState(initVisibleFilters);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onVisibleFilterToggle = (id: string) => {
    const newVisibleFilters = { ...visibleFilters };
    newVisibleFilters[id] = !visibleFilters[id];
    setVisibleFilters(newVisibleFilters);
  };

  const resetTransfersQuery = () => {
    dispatch(resetQueryFilter(TRANSFERS_QUERY));
  };

  return (
    <div className={'flexPage'}>
      <Sidebar>
        <TransfersFilterForm
          storeIdentifier={TRANSFERS_STORE}
          queryIdentifier={TRANSFERS_QUERY}
          visibleFilters={visibleFilters}
          onVisibleFilterToggle={onVisibleFilterToggle}
          resetQueryFilter={resetTransfersQuery}
          showLocationFilter
          operatorFilterType={'byName'}
        />
      </Sidebar>
      <main className={'mainContent'}>
        <TransferList storeIdentifier={TRANSFERS_STORE} queryIdentifier={TRANSFERS_QUERY} />
      </main>
    </div>
  );
};

export default ListTransfers;
