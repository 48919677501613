import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { ROOT_REGION_ID, ROOT_REGION_ID_STRING, ROOT_REGION_NAME } from 'tcf-upstream-shared/constants';
import { Region } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { readRegion } from '../../../actions/regionActions';
import { paths } from '../../../paths';
import RegionDetails from './RegionDetails';

const REGION_STORE = 'topLevelRegionStore';

const TopLevelRegion = () => {
  const dispatch = useAppDispatch();
  const region: Region = useAppSelector((state) => state.serverStores?.[REGION_STORE]?.payload);
  const regionId = region?.id;

  useEffect(() => {
    if (regionId !== ROOT_REGION_ID) {
      dispatch(readRegion(REGION_STORE, ROOT_REGION_ID_STRING));
    }
  }, [regionId, dispatch]);

  return (
    <RegionDetails storeIdentifier={REGION_STORE} title={`${ROOT_REGION_NAME} Summary`}>
      <Link to={paths.VIEW_REGION.replace(':id', ROOT_REGION_ID_STRING)}>
        <Button>View More</Button>
      </Link>
    </RegionDetails>
  );
};

export default TopLevelRegion;
