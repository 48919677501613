import React from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import { Table, Card, CardBody, CardTitle } from 'reactstrap';

import { Region } from 'tcf-upstream-shared/models';

import { useAppSelector } from '../../../hooks';
import { formatYearMonthRange } from '../../../utils/formatting';
import { paths } from '../../../paths';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import Definition from '../../../components/Definition';

interface RegionDetailsProps {
  storeIdentifier: string;
  title?: string;
  children?: any;
}

const RegionDetails = (props: RegionDetailsProps) => {
  const { storeIdentifier, title } = props;
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const region: Region = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);

  if (isFetching) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!region) {
    return null;
  }

  const dataFrom = region?.dataFrom;
  const dataTo = region?.dataTo;

  return (
    <Card>
      <CardBody>
        <CardTitle>{title || 'About'}</CardTitle>
        <Table responsive striped size={'sm'}>
          <tbody>
            {region.level === 3 ? (
              <tr>
                <th>State</th>
                <td style={{ textAlign: 'right' }}>
                  <Link to={paths.VIEW_REGION.replace(':id', region.apiCode.slice(0, 2))} title={'View state details'}>
                    {region.stateAbbrev}
                  </Link>
                </td>
              </tr>
            ) : null}
            <tr>
              <th>
                <Definition label="Production Data">
                  Earliest and latest reported production periods for this region, state, or county. The latest "to" month shown
                  does not necessarily indicate that data is complete through that month, but simply that there are responsive
                  production records from an operator with assets in the respective location for the period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{formatYearMonthRange(dataFrom, dataTo)}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Producing Wells">
                  The number of wells located in the region, state, or county that have nonzero production within the most
                  recent full year or later, where applicable.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{region.producingWellCount?.toLocaleString() || '0'}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Wells">The total number of wells in the region, state, or county.</Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{region.wellCount?.toLocaleString() || '0'}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Producing Operators">
                  The number of operators that operate an oil or gas well that reported nonzero production to the respective
                  region, state, or county within the most recent full year or later, where applicable.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{region.producingOperatorCount.toLocaleString() || 0}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Total BOE">
                  Barrels of oil equivalent of natural gas, oil, and natural gas liquids produced in the region, state, or
                  county during the Production Data period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {region.boeTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Mcfe">
                  Thousands of cubic feet equivalent of natural gas, oil, and natural gas liquids produced in the region, state,
                  or county during the Production Data period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {((region.boeTotal ?? 0) * 6)?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Gas (Mcf)">
                  Thousands of cubic feet of natural gas produced in the region, state, or county during the Production Data
                  period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {region.gasTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Oil (Bbl)">
                  Barrels of oil produced in the region, state, or county during the Production Data period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {region.oilTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total NGL (Bbl)">
                  Barrels of natural gas liquids produced in the region, state, or county during the Production Data period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {region.nglTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}
              </td>
            </tr>
          </tbody>
        </Table>
        {props.children}
      </CardBody>
    </Card>
  );
};

export default RegionDetails;
