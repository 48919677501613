import { Action } from './Action';
import { actions, AddToastPayload } from 'react-redux-toastr';
import merge from 'lodash/merge';

export const toast = {
  showSucceeded: (payload: Partial<AddToastPayload>) => {
    const finalPayload = merge(
      {
        type: 'success',
        message: 'Operation succeeded.',
        position: 'top-center',
        options: {
          showCloseButton: true,
          timeOut: 3000,
          transitionIn: 'fadeIn',
          transitionOut: 'fadeOut',
          className: 'successToast',
          progressBar: false,
        },
      },
      payload,
    );
    return actions.add(finalPayload as AddToastPayload) as Action;
  },

  showFailed: (payload: Partial<AddToastPayload>) => {
    const finalPayload = merge(
      {
        type: 'error',
        message: 'Operation failed.',
        position: 'top-center',
        options: {
          showCloseButton: true,
          timeOut: 0,
          transitionIn: 'fadeIn',
          transitionOut: 'fadeOut',
          className: 'errorToast',
          progressBar: false,
        },
      },
      payload,
    );
    return actions.add(finalPayload as AddToastPayload) as Action;
  },
};
