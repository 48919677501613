import React, { useState } from 'react';
import { XYPlot, HorizontalGridLines, VerticalGridLines, XAxis, YAxis, VerticalBarSeries, Hint } from 'react-vis';
import 'react-vis/dist/style.css';
import 'react-virtualized/styles.css';
import { AutoSizer } from 'react-virtualized';
import TCFChartLabel from './TCFChartLabel';

interface DeclineChartProps {
  label: string;
  benchmark: string;
  decline: any[];
  benchmarkDecline?: any[];
  yTitle: string;
  h: number;
  color?: string;
}

const numDigits = (x: number): number => {
  return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;
};

const formatTick = (x: number): string => {
  const digits = numDigits(x);
  const divisor = digits >= 10 ? 1000000000 : digits >= 7 ? 1000000 : digits >= 4 ? 1000 : 1;
  const suffix = divisor === 1000000000 ? 'B' : divisor === 1000000 ? 'M' : divisor === 1000 ? 'K' : '';
  return (x / divisor).toString().concat(suffix);
};

export const FIRST_DECLINE_COLOR = 'AliceBlue';
export const SECOND_DECLINE_COLOR = 'Cornsilk';

const DeclineChart = (props: DeclineChartProps) => {
  const { label, benchmark, decline, benchmarkDecline, h, yTitle } = props;
  const [mouseValue, setMouseValue] = useState<any>(undefined);

  if (decline?.length < 1) {
    return <h6>No data</h6>;
  }

  const maxYear = new Date().getFullYear() - 1;
  const allXs: number[] = Array.from(new Set([...decline.map((d) => d.x), ...(benchmarkDecline || []).map((d) => d.x)]))
    .filter((year) => year <= maxYear)
    .sort();
  const indexedDecline = decline.reduce((a, c) => ({ ...a, [c.x]: c }), {});
  const patchedDecline = allXs.map((x) => indexedDecline[x] ?? { x, y: 0 });

  const handleOnMouseOver = (value: any, series: string) => {
    setMouseValue({
      x: value.x,
      y: value.y,
      bar: series.startsWith('decline') ? label : benchmark,
      year: value.x,
      decline: value.y.toLocaleString('en-US', { maximumFractionDigits: 1 }) + '%',
    });
  };

  const handleOnMouseOut = () => {
    setMouseValue(null);
  };

  return (
    <AutoSizer disableHeight>
      {({ width }: any) =>
        width > 0 ? (
          <XYPlot
            height={h}
            width={width}
            yPadding={1}
            margin={{ left: 60, right: 20, top: 10, bottom: 45 }}
            xDomain={[Math.min(...allXs), Math.max(...allXs)]}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis tickValues={allXs} tickFormat={(v) => v.toString()} />
            <TCFChartLabel
              text="Year"
              className="alt-x-label"
              xPercent={0.5}
              yPercent={1}
              yPixels={-17}
              style={{
                textAnchor: 'middle',
              }}
            />
            <YAxis tickFormat={(v) => formatTick(v)} />
            <TCFChartLabel
              text={yTitle}
              className="alt-y-label"
              xPercent={0}
              yPercent={0.5}
              xPixels={9}
              style={{
                textAnchor: 'middle',
                transform: 'rotate(-90)',
              }}
            />
            <VerticalBarSeries
              data={patchedDecline}
              barWidth={0.9}
              color={FIRST_DECLINE_COLOR}
              stroke={'#888'}
              onValueMouseOver={(value) => handleOnMouseOver(value, 'decline')}
              onValueMouseOut={handleOnMouseOut}
            />
            {benchmarkDecline ? (
              <VerticalBarSeries
                data={benchmarkDecline}
                barWidth={0.9}
                color={SECOND_DECLINE_COLOR}
                stroke={'#888'}
                onValueMouseOver={(value) => handleOnMouseOver(value, 'benchmarkDecline')}
                onValueMouseOut={handleOnMouseOut}
              />
            ) : null}
            {mouseValue ? (
              <Hint value={mouseValue}>
                <div className={'rv-hint__content'}>
                  {mouseValue.bar}
                  <br />
                  Year: {mouseValue.year}
                  <br />
                  Decline: {mouseValue.decline}
                </div>
              </Hint>
            ) : null}
          </XYPlot>
        ) : null
      }
    </AutoSizer>
  );
};

export default DeclineChart;
