import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Button, Col, Container, Row, Spinner } from 'reactstrap';

import { getAuthError, getAuthUser, getIsLoggingIn } from '../../reducers/authReducer';
import { login } from '../../actions/authActions';
import { paths } from '../../paths';
import { useAppDispatch, useAppSelector } from '../../hooks';

const parseQS = (qs: string | undefined) => {
  if (!qs) {
    return {};
  }
  return (/^[?#]/.test(qs) ? qs.slice(1) : qs).split('&').reduce<{ [key: string]: string }>((params, param) => {
    const [key, value] = param.split('=');
    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
    return params;
  }, {});
};

enum PageState {
  noToken,
  expired,
  notMostRecent,
  used,
  unknownError,
  freshLogin,
}

const LoginWithToken = () => {
  const dispatch = useAppDispatch();
  const { hash, search } = useLocation();

  const error = useAppSelector((state) => getAuthError(state));
  const isLoggingIn = useAppSelector((state) => getIsLoggingIn(state));
  const authUser = useAppSelector((state) => getAuthUser(state));

  const isLoggedIn = !!authUser;

  const query: { r?: string } = parseQS(search);
  const redirectTo = query.r || '/';

  if (isLoggedIn) {
    return <Redirect to={redirectTo} />;
  }

  const logIn = () => {
    dispatch(login(hash, false));
  };

  const pageState = error?.includes('No token')
    ? PageState.noToken
    : error?.includes('Token has expired')
    ? PageState.expired
    : error?.includes('been used')
    ? PageState.used
    : error?.includes('not the most recently issued')
    ? PageState.notMostRecent
    : error
    ? PageState.unknownError
    : PageState.freshLogin;

  return (
    <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
      <Container id="content-container" fluid style={{ marginTop: '1rem', maxWidth: '1300px' }}>
        <Row>
          <Col sm={{ size: 6, offset: 3 }} className="pt-3">
            <p className="lead login-page">
              {pageState === PageState.noToken && (
                <>
                  Login link missing key information.
                  <br />
                  <br />
                  This page should not be visited directly. If you got here by clicking on a link in an email, please check with
                  your IT department to ensure The Capitol Forum is added to your organization's list of approved websites and
                  email sources and{' '}
                  <Link to={paths.LOGIN} replace={true}>
                    request a new login link
                  </Link>{' '}
                  to try again. If the issue persists, please contact us at{' '}
                  <a href="mailto:editorial@thecapitolforum.com">editorial@thecapitolforum.com</a> for further assistance.
                  <br />
                  <br />
                  <Link to={paths.LOGIN} replace={true} className="btn btn-primary">
                    Request Login Email
                  </Link>
                </>
              )}
              {pageState === PageState.expired && (
                <>
                  Login link expired.
                  <br />
                  <br />
                  Login links are only valid for 10 minutes from the moment they are requested. Please{' '}
                  <Link to={paths.LOGIN} replace={true}>
                    request a new login link
                  </Link>{' '}
                  to try again. If the issue persists, please contact your IT department to ensure your internal email is not
                  encountering delays or contact us at{' '}
                  <a href="mailto:editorial@thecapitolforum.com">editorial@thecapitolforum.com</a> for further assistance.
                  <br />
                  <br />
                  <Link to={paths.LOGIN} replace={true} className="btn btn-primary">
                    Request Login Email
                  </Link>
                </>
              )}
              {pageState === PageState.used && (
                <>
                  Login link has already been used.
                  <br />
                  <br />
                  Login links are only valid for a single login and this one appears to have already been clicked. Please{' '}
                  <Link to={paths.LOGIN} replace={true}>
                    request a new login link
                  </Link>{' '}
                  to try again. If the issue persists, please contact us at{' '}
                  <a href="mailto:editorial@thecapitolforum.com">editorial@thecapitolforum.com</a> for further assistance.
                  <br />
                  <br />
                  <Link to={paths.LOGIN} replace={true} className="btn btn-primary">
                    Request Login Email
                  </Link>
                </>
              )}
              {pageState === PageState.notMostRecent && (
                <>
                  Login link not most recent.
                  <br />
                  <br />
                  When a login link is requested, all unused login links are invalidated. This appears to be an older,
                  invalidated login link. Please delete any login emails you have received from us and{' '}
                  <Link to={paths.LOGIN} replace={true}>
                    request a new login link
                  </Link>{' '}
                  to try again. If the issue persists, please contact us at{' '}
                  <a href="mailto:editorial@thecapitolforum.com">editorial@thecapitolforum.com</a> for further assistance.
                  <br />
                  <br />
                  <Link to={paths.LOGIN} replace={true} className="btn btn-primary">
                    Request Login Email
                  </Link>
                </>
              )}
              {pageState === PageState.unknownError && (
                <>
                  Unexpected issue encountered while attempting to log in.
                  <br />
                  <br />
                  We were unable to log you in. Please{' '}
                  <Link to={paths.LOGIN} replace={true}>
                    request a new login link
                  </Link>{' '}
                  to try again. If the issue persists, please contact your IT department to ensure The Capitol Forum is added to
                  your organization's list of approved websites and email sources. If the issue continues to persist, please
                  contact us at <a href="mailto:editorial@thecapitolforum.com">editorial@thecapitolforum.com</a> for further
                  assistance.
                  <br />
                  <br />
                  <Link to={paths.LOGIN} replace={true} className="btn btn-primary">
                    Request Login Email
                  </Link>
                </>
              )}
              {pageState === PageState.freshLogin && (
                <>
                  Welcome to The Capitol Forum's energy platform with access to our investigative journalism in Energy, together
                  with information on oil & gas production from the United States in the regions we cover.
                  <br />
                  <br />
                  <Button disabled={isLoggingIn} onClick={logIn} color="primary">
                    {isLoggingIn ? (
                      <>
                        <Spinner size="sm" color="light" /> Please Wait...
                      </>
                    ) : (
                      'Click Here to Complete Log In'
                    )}
                  </Button>
                </>
              )}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginWithToken;
