export const paths = {
  TCF: 'https://thecapitolforum.com/energy',

  HOME: '/',

  LOGIN: '/login',
  LOGIN_WITH_TOKEN: '/auth/login/token',
  LOGOUT: '/auth/logout',

  LIST_DOCUMENTS: '/documents',
  // VIEW_DOCUMENT: '/documents/:id',
  VIEW_DOCUMENT: 'https://library.thecapitolforum.com/docs/:id',

  LIST_OPERATORS: '/operators',
  VIEW_OPERATOR: '/operators/:id',

  LIST_WELLS: '/wells',
  VIEW_WELL: '/wells/:id',

  LIST_REGIONS: '/regions',
  VIEW_REGION: '/regions/:id',

  LIST_SOURCES: '/sources',

  LIST_REPORTS: '/reports',

  LIST_TRANSFERS: '/transfers',

  admin: {
    ROOT: '/admin',

    CREATE_OPERATOR: '/admin/operators/new',
    EDIT_OPERATOR: '/admin/operators/:id',
    LIST_OPERATORS: '/admin/operators',
    MERGE_OPERATORS: '/admin/operators/:id/merge/:oid',
    LIST_EVENTS: '/admin/events',
    CREATE_FILE: '/admin/files/new',
    EDIT_FILE: '/admin/files/:id',
    LIST_FILES: '/admin/files',
    LIST_REPORT_FILES: '/admin/reports',
    CREATE_OPERATOR_EVENT: '/admin/events/new',
    EDIT_OPERATOR_EVENT: '/admin/events/:id',
    LIST_ETL_RUNS: '/admin/etl/runs',
    PRESENT_ETL_METRICS: '/admin/etl/runs/:id',
    COMPARE_ETL_METRICS: '/admin/etl/runs/:id/:oid',
  },
};
