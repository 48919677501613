import React from 'react';
import { FormGroup, Label, Input, Form } from 'reactstrap';

import { useAppSelector, useAppDispatch } from '../../../hooks';
import { upsertQueryFilter } from '../../../actions/queryActions';

const fromOperatorField = 'fromOperatorId';
const toOperatorField = 'toOperatorId';

export interface FromToOperatorIdFilterProps {
  queryIdentifier: string;
}

const FromToOperatorIdFilter = (props: FromToOperatorIdFilterProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier } = props;
  const operatorFilter: any = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.or);
  const operatorFilterFields: string[] = [];
  if (operatorFilter) {
    if (Object.keys(operatorFilter).includes(fromOperatorField)) {
      operatorFilterFields.push(fromOperatorField);
    }
    if (Object.keys(operatorFilter).includes(toOperatorField)) {
      operatorFilterFields.push(toOperatorField);
    }
  }
  if (operatorFilterFields.length < 1) {
    operatorFilterFields.push(fromOperatorField);
    operatorFilterFields.push(toOperatorField);
  }

  const changeOperatorFilterFields = (e: any) => {
    const _newFilterFields = JSON.parse(e.currentTarget.value);
    if (operatorFilter) {
      const _newOperatorFilter = {};
      const opIds = operatorFilter[fromOperatorField] || operatorFilter[toOperatorField];
      if (_newFilterFields.includes(fromOperatorField)) {
        _newOperatorFilter[fromOperatorField] = opIds;
      }
      if (_newFilterFields.includes(toOperatorField)) {
        _newOperatorFilter[toOperatorField] = opIds;
      }
      dispatch(upsertQueryFilter(queryIdentifier, 'or', _newOperatorFilter));
    }
  };

  return (
    <Form inline>
      <FormGroup>
        <Input
          type="radio"
          id="fromName"
          name="useName"
          value={JSON.stringify([fromOperatorField])}
          checked={JSON.stringify(operatorFilterFields) === JSON.stringify([fromOperatorField])}
          onChange={changeOperatorFilterFields}
        />
        <Label for="fromName">From&nbsp;&nbsp;&nbsp;</Label>
      </FormGroup>
      <FormGroup>
        <Input
          type="radio"
          id="toName"
          name="useName"
          value={JSON.stringify([toOperatorField])}
          checked={JSON.stringify(operatorFilterFields) === JSON.stringify([toOperatorField])}
          onChange={changeOperatorFilterFields}
        />
        <Label for="toName">To&nbsp;&nbsp;&nbsp;</Label>
      </FormGroup>
      <FormGroup>
        <Input
          type="radio"
          id="bothNames"
          name="useName"
          value={JSON.stringify([fromOperatorField, toOperatorField])}
          checked={JSON.stringify(operatorFilterFields) === JSON.stringify([fromOperatorField, toOperatorField])}
          onChange={changeOperatorFilterFields}
        />
        <Label for="bothNames">Both</Label>
      </FormGroup>
    </Form>
  );
};

export default FromToOperatorIdFilter;
