import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavItem, Container } from 'reactstrap';

import { ROOT_REGION_ID_STRING } from 'tcf-upstream-shared/constants';
import { UpstreamToken } from 'tcf-upstream-shared/models';

import { paths } from '../paths';

interface MainMenuProps {
  authUser?: UpstreamToken;
}

const MainMenu = (props: MainMenuProps) => {
  const { pathname } = useLocation();
  const { authUser } = props;

  return (
    <Navbar style={{ backgroundColor: '#eee', padding: '0.25rem 0rem' }}>
      <Container id={'main-menu-container'} fluid>
        <Nav pills>
          <NavItem>
            <Link
              title={'Learn about The Capitol Forum Energy Platform'}
              to={paths.HOME}
              className={'nav-link' + (pathname === paths.HOME ? ' active' : '')}
            >
              Home
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={'View operators'}
              to={paths.LIST_OPERATORS}
              className={'nav-link' + (pathname === paths.LIST_OPERATORS ? ' active' : '')}
            >
              Operators
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={'View wells'}
              to={paths.LIST_WELLS}
              className={'nav-link' + (pathname === paths.LIST_WELLS ? ' active' : '')}
            >
              Wells
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={'View regions'}
              to={paths.LIST_REGIONS}
              className={'nav-link' + (pathname === paths.VIEW_REGION.replace(':id', ROOT_REGION_ID_STRING) ? ' active' : '')}
            >
              Regions
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={'View transfers'}
              to={paths.LIST_TRANSFERS}
              className={'nav-link' + (pathname === paths.LIST_TRANSFERS ? ' active' : '')}
            >
              Transfers
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={'View articles'}
              to={paths.LIST_DOCUMENTS}
              className={'nav-link' + (pathname === paths.LIST_DOCUMENTS ? ' active' : '')}
            >
              Articles
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={(authUser?.canViewReports ? 'Download' : 'Subscribe to download') + ' reports'}
              to={authUser?.canViewReports ? paths.LIST_REPORTS : '#'}
              className={
                'nav-link' +
                (pathname === paths.LIST_REPORTS ? ' active' : '') +
                (!authUser?.canViewReports ? ' text-muted' : '')
              }
            >
              Reports
            </Link>
          </NavItem>
          <NavItem>
            <Link
              title={(authUser?.canViewSources ? 'Subscribe to view' : 'View') + ' data source updates'}
              to={authUser?.canViewSources ? paths.LIST_SOURCES : '#'}
              className={
                'nav-link' +
                (pathname === paths.LIST_SOURCES ? ' active' : '') +
                (authUser?.canViewSources ? '' : ' text-muted')
              }
            >
              Data
            </Link>
          </NavItem>
          {authUser?.canDataEntry && (
            <NavItem>
              <Link
                title={'Data management view'}
                to={paths.admin.LIST_OPERATORS}
                className={'nav-link' + (pathname === paths.admin.LIST_OPERATORS ? ' active' : '')}
              >
                Admin
              </Link>
            </NavItem>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
};

export default MainMenu;
