import React, { useContext } from 'react';
import Loader from 'react-loader';

import { Well, QueryFilters } from 'tcf-upstream-shared/models';

import { useAppSelector } from '../../../hooks';
import MapContext from '../../../components/App/MapContext';
import TcfMap from '../../../components/TcfMap';
import { sizes } from '../../../theme';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

interface WellsMapProps {
  storeIdentifier: string;
  queryIdentifier: string;
  mapTypeId?: string;
  defaultZoom?: number;
  height?: string;
  defaultCenter?: { lat: number; lng: number };
}

const WellsMap = (props: WellsMapProps) => {
  const isApiLoaded = useContext(MapContext);
  const { storeIdentifier, queryIdentifier, mapTypeId, defaultZoom, height, defaultCenter } = props;
  const searchResults: any = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const filters: QueryFilters = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters || {});

  let wells: Well[] | undefined;
  if (searchResults?.total) {
    // array of wells
    wells = searchResults?.results;
  } else if (searchResults?.id) {
    // single well; put it into an array
    wells = [searchResults];
  }

  if (!isApiLoaded || isFetching || !filters) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!wells || wells.length < 1) {
    return null;
  }

  const wellsWithCoords: any[] = wells.filter((w) => w.location && w.location.length > 1);
  if (wellsWithCoords.length < 1) {
    return <h4>(No well location(s) found)</h4>;
  }

  const { minLon, minLat, maxLon, maxLat } = wellsWithCoords.reduce(
    (area: any, well: any) => ({
      minLon: Math.min(area.minLon, well.location[0]),
      minLat: Math.min(area.minLat, well.location[1]),
      maxLon: Math.max(area.maxLon, well.location[0]),
      maxLat: Math.max(area.maxLat, well.location[1]),
    }),
    { minLon: 180, minLat: 90, maxLon: -180, maxLat: -90 },
  );
  const degreesLat = maxLat - minLat;
  const degreesLon = maxLon - minLon;

  const finalDefaultZoom =
    defaultZoom ||
    (degreesLat < 0.001
      ? 17
      : degreesLat < 0.01
      ? 15
      : degreesLat < 0.025
      ? 13
      : degreesLat < 0.05
      ? 12
      : degreesLat < 0.1
      ? 11
      : degreesLat < 0.25
      ? 10
      : degreesLat < 0.5
      ? 9
      : degreesLat < 1.0
      ? 8
      : degreesLat < 2.0
      ? 7
      : degreesLat < 4.0
      ? 6
      : 5);
  const finalMapTypeId = mapTypeId || (wellsWithCoords.length === 1 ? 'hybrid' : 'terrain');
  const finalHeight = height || Math.min(600, Math.max(200, window.innerHeight - sizes.appHeaderPadding - 100));

  const finalDefaultCenter = defaultCenter || {
    lng: minLon + degreesLon / 2.0,
    lat: minLat + degreesLat / 2.0,
  };

  return (
    <div style={{ height: finalHeight.toString() + 'px' }}>
      <TcfMap mapTypeId={finalMapTypeId} zoom={finalDefaultZoom} center={finalDefaultCenter} wellFilters={{ ...filters }} />
    </div>
  );
};

export default WellsMap;
