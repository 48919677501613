import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { Card, CardTitle, CardBody } from 'reactstrap';

import { SearchResults } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { readSources } from '../../../actions/sourceActions';
import TcfBootstrapTable, { formatStateName, formatDataType } from '../../../components/TcfBootstrapTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

export const availableFields = ['processedOn', 'stateAbbrev', 'dataType'];

interface RecentSourceListProps {
  storeIdentifier: string;
  queryString: string;
  label?: string;
  children?: any;
}

const RecentSourceList = (props: RecentSourceListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true);
  const { storeIdentifier, queryString, label } = props;
  const payload: SearchResults = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);

  useEffect(() => {
    const _query = JSON.parse(queryString);
    dispatch(readSources(storeIdentifier, _query));
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!payload?.total || payload.total < 1) {
    return null;
  }

  const dataSource = (payload?.results || []).map((o) => ({
    ...o,
  }));

  const columns = [
    {
      dataField: 'processedOn',
      text: 'Date',
      sort: false,
    },
    {
      dataField: 'stateAbbrev',
      text: 'State',
      formatter: formatStateName,
      sort: false,
    },
    {
      dataField: 'dataType',
      text: 'Data Type',
      formatter: formatDataType,
      sort: false,
    },
  ];

  const totalSize = payload?.total || 0;

  return (
    <Card className={'mb-4'}>
      <CardBody>
        {label ? <CardTitle>{label}:</CardTitle> : null}
        <TcfBootstrapTable allowWrap columns={columns} dataSource={dataSource} totalSize={totalSize} />
        {props.children}
      </CardBody>
    </Card>
  );
};

export default RecentSourceList;
