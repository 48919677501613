import React from 'react';
import { Field as RFField } from 'react-final-form';
import { FormGroup, Label, Input } from 'reactstrap';

const TcfCheckbox = (props: any) => {
  return <RFField {...props} component={renderTcfCheckbox} normalize={(value: any) => value === true} />;
};

const renderTcfCheckbox = (field: any) => {
  return (
    <FormGroup check>
      <Input
        id={field.input.name}
        type={'checkbox'}
        {...field.input}
        checked={field.input.value === true}
        value={field.input.value === true}
      />
      <Label check for={field.input.name}>
        {field.label}
      </Label>
    </FormGroup>
  );
};

export default TcfCheckbox;
