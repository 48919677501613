import { AxiosInstance } from 'axios';
import { ApiResponse } from 'tcf-upstream-shared/models';
import { CrudApiClient } from './CrudApiClient';

export class WellsApiClient extends CrudApiClient {
  constructor(readonly axios: AxiosInstance) {
    super(axios, '/wells');
  }

  async getWellLookups(): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/lookups`, {});
  }
}