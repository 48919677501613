import React from 'react';
import Loader from 'react-loader';

import { useAppSelector, useProductionUnits, BOE, MCFE } from '../../../hooks';
import { Production } from 'tcf-upstream-shared/models';
import ProductionChart from '../../Production/components/ProductionChart';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

const GRAPH_MIN_YEAR = 2010;

interface WellProductionChartProps {
  storeIdentifier: string;
  dataField: string;
  yTitle: string;
  h: number;
  color?: string;
  minYear?: number;
  lastFullYear?: number;
}

const WellProductionChart = (props: WellProductionChartProps) => {
  const { storeIdentifier, dataField, h, yTitle, color, minYear, lastFullYear } = props;
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const payload: any = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const valUnits = dataField?.startsWith('gas') ? MCFE : BOE;

  const { convertToSelectedUnits } = useProductionUnits();

  if (isFetching) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!payload) {
    return null;
  }

  const productions: Production[] = payload?.results;
  const totalSize = payload?.total || 0;

  if (totalSize < 1) {
    return <h5>No data found.</h5>;
  }

  const sortedProdYears =
    productions?.filter((a: any) => a.year >= (minYear || GRAPH_MIN_YEAR)).sort((a: any, b: any) => a.year - b.year) || [];
  const anyProductionOfType = sortedProdYears?.filter((a: any) => a[dataField] > 0)?.length > 0;

  if (!anyProductionOfType) {
    return <h5>No data from {minYear || GRAPH_MIN_YEAR} to present.</h5>;
  }

  const fieldData: any[] = sortedProdYears.map((p) => ({
    x: p.year,
    y: Math.round(convertToSelectedUnits(Math.max(0, p[dataField]), valUnits)),
  }));

  // Combine multiple values for a year (different operators) into a single value
  const plotData = fieldData.reduce((acc, cur) => {
    const found = acc.find((pt: any) => pt.x === cur.x);
    if (found) found.y += cur.y;
    else acc.push(cur);
    return acc;
  }, []);

  return <ProductionChart seriesData={plotData} yTitle={yTitle} h={h} color={color} lastFullYear={lastFullYear} />;
};

export default WellProductionChart;
