import { accessToken } from '../utils/Tokens';

import {
  LOGIN,
  LOGOUT,
  NEW_ACCESS_TOKEN_RECEIVED,
  API_REQUEST_NOT_AUTHORIZED,
  SEND_LOGIN_EMAIL,
  UPDATE_MAINTENANCE_WINDOW,
} from '../actions/actionTypes';
import config from '../config';
import { applyPermissions, ApiError, UpstreamToken, TokenScope } from 'tcf-upstream-shared/models';

export interface AuthState {
  accessToken?: string;
  error?: string;
  isLoggingIn: boolean;
  requestedFreeDoc?: boolean;
  authUser?: UpstreamToken;
  nextMaintenance?: Date;
}

const getLoggedOutState = () => ({
  isLoggingIn: false,
  error: '',
  requestedFreeDoc: false,
});

const getUserFromAccessToken = () => {
  const token = accessToken.decode();
  return (
    (token &&
      applyPermissions({
        userId: token.sub,
        email: token.email,
        scope: token.scope,
        firstName: token.firstName,
        lastName: token.lastName,
        subscription: token.subscription,
        entitlements: token.entitlements,
      })) ||
    undefined
  );
};

const getInitialState = (): AuthState => {
  try {
    if (config.DISABLE_AUTHENTICATION_IN_DEV) {
      return {
        ...getLoggedOutState(),
        authUser: {
          userId: '123',
          email: 'jane@example.com',
          isTrial: false,
          canView: true,
          canDataEntry: true,
          canExport: true,
          canViewSources: true,
          canViewReports: true,
          canBrowseLongLists: true,
          scope: TokenScope.ACCESS,
        },
      };
    }

    return {
      ...getLoggedOutState(),
      accessToken: accessToken.get(),
      authUser: getUserFromAccessToken(),
    };
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log('Error loading initial auth state.', err);
    return getLoggedOutState();
  }
};

export const authReducer = (state = getInitialState(), action: any) => {
  let error;

  switch (action.type) {
    case SEND_LOGIN_EMAIL.REQUESTED:
    case SEND_LOGIN_EMAIL.SUCCEEDED:
      return {
        ...getLoggedOutState(),
      };

    case LOGIN.REQUESTED:
      return {
        ...state,
        isLoggingIn: true,
      };

    case LOGIN.SUCCEEDED:
      return {
        ...state,
        isLoggingIn: false,
        requestedFreeDoc: false,
      };

    case NEW_ACCESS_TOKEN_RECEIVED:
      return {
        ...getLoggedOutState(),
        accessToken: accessToken.get(),
        authUser: getUserFromAccessToken(),
      };

    case UPDATE_MAINTENANCE_WINDOW:
      return {
        ...state,
        nextMaintenance: action.payload,
      };

    case API_REQUEST_NOT_AUTHORIZED:
      error = action.payload.error as ApiError;
      const requestedFreeDoc: boolean = action.payload.requestedFreeDoc;
      return {
        ...getLoggedOutState(),
        accessToken: accessToken.get(),
        authUser: getUserFromAccessToken(),
        error: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
        requestedFreeDoc,
      };

    case LOGIN.FAILED:
      error = action.payload as ApiError;
      return {
        ...getLoggedOutState(),
        accessToken: accessToken.get(),
        authUser: getUserFromAccessToken(),
        error: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
      };

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return {
        ...getLoggedOutState(),
      };

    default:
      return state;
  }
};

export const getAuthUser = (state: { auth: AuthState }) => {
  return state.auth.authUser;
};

export const getAuthError = (state: { auth: AuthState }) => {
  return state.auth.error;
};

export const getIsLoggingIn = (state: { auth: AuthState }) => {
  return state.auth.isLoggingIn;
};
