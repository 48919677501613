import React from 'react';
import { Button } from 'reactstrap';

import { useAppSelector } from '../../../hooks';
import Accordion from '../../../components/Accordion';
import StatesFilter from '../../../components/StatesFilter';
import KeywordFilter from '../../../components/KeywordFilter';
import WellsFilterLookup from './WellsFilterLookup';

const KEYWORD_FILTER_FIELDS = ['site.text', 'number.text', 'api.text', 'apiHyphenated.text'];

export interface VisibleFilters {
  location: boolean;
  config: boolean;
  wellType: boolean;
  status: boolean;
  producing: boolean;
}

export interface WellsFilterFormProps {
  storeIdentifier: string;
  queryIdentifier: string;
  visibleFilters: VisibleFilters;
  onVisibleFilterToggle: any;
  resetQueryFilter: any;
  showKeywordFilter?: boolean;
  showLocationFilter?: boolean;
}

type Aggs = { states: any; configs: any; types: any; statuses: any; producing: any };

const WellsFilterForm = (props: WellsFilterFormProps) => {
  const {
    storeIdentifier,
    queryIdentifier,
    visibleFilters,
    onVisibleFilterToggle,
    resetQueryFilter,
    showKeywordFilter,
    showLocationFilter,
  } = props;
  const aggregations: Aggs = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload?.aggregations);

  const onClearFiltersClick = () => {
    resetQueryFilter();
  };

  return (
    <div>
      <p>
        <b>Refine By:</b>
      </p>
      {showKeywordFilter && (
        <>
          <KeywordFilter queryIdentifier={queryIdentifier} searchFields={KEYWORD_FILTER_FIELDS} title={'Site/API search'} />
          <hr />
        </>
      )}
      {showLocationFilter && (
        <>
          <Accordion title={'Location:'} id={'location'} isOpen={visibleFilters.location} handleToggle={onVisibleFilterToggle}>
            <StatesFilter
              queryIdentifier={queryIdentifier}
              filterField={'stateAbbrev'}
              values={aggregations?.states?.buckets}
            />
          </Accordion>
          <hr />
        </>
      )}
      <Accordion title={'Type:'} id={'wellType'} isOpen={visibleFilters.wellType} handleToggle={onVisibleFilterToggle}>
        <WellsFilterLookup queryIdentifier={queryIdentifier} filterField={'type'} values={aggregations?.types?.buckets} />
      </Accordion>
      <hr />
      <Accordion title={'Configuration:'} id={'config'} isOpen={visibleFilters.config} handleToggle={onVisibleFilterToggle}>
        <WellsFilterLookup queryIdentifier={queryIdentifier} filterField={'config'} values={aggregations?.configs?.buckets} />
      </Accordion>
      <hr />
      <Accordion
        title={'Operational Status:'}
        id={'status'}
        isOpen={visibleFilters.status}
        handleToggle={onVisibleFilterToggle}
      >
        <WellsFilterLookup queryIdentifier={queryIdentifier} filterField={'status'} values={aggregations?.statuses?.buckets} />
      </Accordion>
      <hr />
      <Accordion
        title={'Production Status:'}
        id={'producing'}
        isOpen={visibleFilters.producing}
        handleToggle={onVisibleFilterToggle}
      >
        <WellsFilterLookup
          queryIdentifier={queryIdentifier}
          filterField={'producing'}
          values={aggregations?.producing?.buckets}
        />
      </Accordion>
      <hr />
      <Button size={'sm'} className={'mt-3 btn-block'} onClick={onClearFiltersClick}>
        Clear all filters
      </Button>
    </div>
  );
};

export default WellsFilterForm;
