import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import { ROOT_REGION_ID } from 'tcf-upstream-shared/constants';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { upsertQueryFilter } from '../../../actions/queryActions';

const WELL_COUNT_OPTIONS = {
  '1.0-10.0': { name: '1-10', range: { gte: 1, lt: 10 } },
  '10.0-25.0': { name: '10-25', range: { gte: 10, lt: 25 } },
  '25.0-100.0': { name: '25-100', range: { gte: 25, lt: 100 } },
  '100.0-250.0': { name: '100-250', range: { gte: 100, lt: 250 } },
  '250.0-500.0': { name: '250-500', range: { gte: 250, lt: 500 } },
  '500.0-1000.0': { name: '500-1000', range: { gte: 500, lt: 1000 } },
  '1000.0-*': { name: '1,000+', range: { gte: 1000 } },
};
const REGION_SUMMARY_FILTER = 'regionSummary';

export interface OperatorWellCountsFilterProps {
  queryIdentifier: string;
  filterField: string;
  values?: { key: string; key_as_string: string; doc_count: number }[];
}

const OperatorWellCountsFilter = (props: OperatorWellCountsFilterProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier, values, filterField } = props;
  const filters: any = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters);
  const selectedOptions = Object.keys(WELL_COUNT_OPTIONS).filter((k) =>
    filters?.[REGION_SUMMARY_FILTER]?.[filterField]?.find((f: any) => f.gte === WELL_COUNT_OPTIONS[k].range.gte),
  );

  const onOptionChanged = (event: any) => {
    const { value: rangeOptionKey, checked } = event.target;
    let nextSelectedOptions;
    if (checked) {
      nextSelectedOptions = [...(selectedOptions || [])];
      nextSelectedOptions.push(rangeOptionKey);
    } else {
      nextSelectedOptions = (selectedOptions || []).filter((o: any) => o !== rangeOptionKey);
    }

    const summaryFilter = filters?.[REGION_SUMMARY_FILTER];
    let newSummaryFilter;
    if (nextSelectedOptions?.length > 0) {
      newSummaryFilter = (summaryFilter && { ...summaryFilter }) || { regionId: ROOT_REGION_ID };
      newSummaryFilter[filterField] = nextSelectedOptions.map((o) => WELL_COUNT_OPTIONS[o].range);
    } else if (summaryFilter) {
      newSummaryFilter = { ...summaryFilter };
      delete newSummaryFilter[filterField];
    }
    if (newSummaryFilter) {
      dispatch(upsertQueryFilter(queryIdentifier, REGION_SUMMARY_FILTER, newSummaryFilter));
    }
  };

  return (
    <>
      {Object.keys(WELL_COUNT_OPTIONS).map((k) => {
        const doc_count = values?.find((v) => v.key === k)?.doc_count;
        return (
          <FormGroup check key={k}>
            <Label check>
              <Input type="checkbox" value={k} onChange={onOptionChanged} checked={(selectedOptions || []).includes(k)} />
              &nbsp;{WELL_COUNT_OPTIONS[k].name}
              {doc_count ? <span className={'text-muted'}> ({doc_count.toLocaleString()})</span> : ''}
            </Label>
          </FormGroup>
        );
      })}
    </>
  );
};

export default OperatorWellCountsFilter;
