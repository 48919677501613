import React from 'react';
import { Label } from 'reactstrap';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { useAppDispatch, useAppSelector } from '../hooks';
import { upsertQueryFilter, removeQueryFilter } from '../actions/queryActions';

interface DateRangeFilterProps {
  queryIdentifier: string;
  dateField: string;
}

const DateRangeFilter = (props: DateRangeFilterProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier, dateField } = props;
  const fromDateStr = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.dateRange?.[dateField]?.fromDate);
  const toDateStr = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.dateRange?.[dateField]?.toDate);
  let e = Date.parse(fromDateStr + 'T12:00:00Z');
  const fromDate = (e && new Date(e)) || null;
  e = Date.parse(toDateStr + 'T12:00:00Z');
  const toDate = (e && new Date(e)) || null;

  const changeDate = (which: string, value: Date | null) => {
    let newFromDate: any;
    let newToDate: any;
    if (which === 'from') {
      newFromDate = value;
      newToDate = toDate;
      // if (newFromDate >= newToDate) {
      //   newToDate = newFromDate;
      // }
    } else if (which === 'to') {
      newFromDate = fromDate;
      newToDate = value;
      // if (newFromDate >= newToDate) {
      //   newFromDate = newToDate;
      // }
    }
    let newFilter;
    if (newFromDate && newToDate) {
      newFilter = {
        [dateField]: {
          fromDate: newFromDate.toISOString().split('T')[0],
          toDate: newToDate.toISOString().split('T')[0],
        },
      };
    } else if (newFromDate) {
      newFilter = {
        [dateField]: { fromDate: newFromDate.toISOString().split('T')[0] },
      };
    } else if (newToDate) {
      newFilter = {
        [dateField]: { toDate: newToDate.toISOString().split('T')[0] },
      };
    }
    newFilter
      ? dispatch(upsertQueryFilter(queryIdentifier, 'dateRange', newFilter))
      : dispatch(removeQueryFilter(queryIdentifier, 'dateRange'));
  };

  return (
    <>
      <div className={'my-2'}>
        <Label>From date: </Label>
        <DatePicker
          selected={fromDate}
          selectsStart
          startDate={fromDate}
          endDate={toDate}
          onChange={(value) => changeDate('from', value)}
        />
      </div>
      <div className={'my-2'}>
        <Label>To date: </Label>
        <DatePicker
          selected={toDate}
          selectsEnd
          startDate={fromDate}
          endDate={toDate}
          minDate={fromDate}
          onChange={(value) => changeDate('to', value)}
        />
      </div>
    </>
  );
};

export default DateRangeFilter;
