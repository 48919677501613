import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from 'react-loader';
import { Container, Row, Col, Card, CardBody, CardTitle, TabContent, TabPane, Nav, NavItem, NavLink, Navbar } from 'reactstrap';

import { ROOT_REGION_ID, ROOT_REGION_NAME } from 'tcf-upstream-shared/constants';
import { Operator, Region } from 'tcf-upstream-shared/models';

import { useProductionUnits, useAppDispatch, useAppSelector } from '../../hooks';
import { readOperator } from '../../actions/operatorActions';
import { readRegion } from '../../actions/regionActions';
import { setQuery } from '../../actions/queryActions';
import { paths } from '../../paths';
import AsyncPage from '../AsyncPage/AsyncPage';
import ErrorComponent from '../AsyncPage/ErrorComponent';
import WellsFilterForm, { VisibleFilters } from '../Well/components/WellsFilterForm';
import WellList from '../Well/components/WellList';
import WellsMap from '../Well/components/WellsMap';
import TransfersFilterForm from '../Transfer/components/TransfersFilterForm';
import TransferList from '../Transfer/components/TransferList';
import EventList from '../Operator/components/EventList';
import DeclineChartLegendSwatch from '../Production/components/DeclineChartLegendSwatch';
import { FIRST_DECLINE_COLOR, SECOND_DECLINE_COLOR } from '../Production/components/DeclineChart';
import OperatorOrgChart from './components/OperatorOrgChart';
import OperatorOrgTable from './components/OperatorOrgTable';
import OperatorProductionChart from './components/OperatorProductionChart';
import OperatorProductionList from './components/OperatorProductionList';
import OperatorDeclineChart from './components/OperatorDeclineChart';
import OperatorDetails from './components/OperatorDetails';
import ListCount from '../../components/ListCount';

const OPERATOR_STORE = 'operatorDetailsStore';
// const SUBSIDIARIES_STORE = 'operatorSubsidiariesStore';
const REGION_STORE = 'operatorRegionStore';

const WELLS_STORE = 'operatorWellsStore';
const WELLS_QUERY = 'operatorsWellsQuery';
const WELLS_DEFAULT_SORT = [{ 'prodSummary.boeTotal': 'desc' }];
const DEFAULT_PAGE_SIZE = 25;

const TRANSFERS_STORE = 'operatorTransfersStore';
const TRANSFERS_QUERY = 'operatorTransfersQuery';

interface ParamTypes {
  id: string;
}

const ViewOperator = () => {
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState('Summary');
  const [delayRender, setDelayRender] = useState(true);
  const { id: urlId } = useParams<ParamTypes>();

  const authUser = useAppSelector((state) => state.auth.authUser);
  const isFetching = useAppSelector(
    (state) => state.serverStores?.[OPERATOR_STORE]?.isFetching || state.serverStores?.[REGION_STORE]?.isFetching,
  );
  const error = useAppSelector(
    (state) => state.serverStores?.[OPERATOR_STORE]?.error || state.serverStores?.[REGION_STORE]?.error,
  );
  const operator: Operator = useAppSelector((state) => state.serverStores?.[OPERATOR_STORE]?.payload);
  const region: Region = useAppSelector((state) => state.serverStores?.[REGION_STORE]?.payload);

  const operatorId = operator?.id;
  const subsidiaryIds = (operator?.subsidiaries || []).map((c: any) => c.id);
  const subsidiaryIdsJson = JSON.stringify(subsidiaryIds);
  const latestFullProductionYear = region?.latestFullProductionYear;

  const dataDisclaimer = `Note: production data and well counts are complete through
    ${latestFullProductionYear || 'the previous year'} for all states. Production totals and well counts beyond
    ${latestFullProductionYear || 'the previous year'} may not be indicative of final values.`;

  const { title: productionUnitsTitle } = useProductionUnits();

  const initVisibleFilters: VisibleFilters = {
    location: true,
    wellType: true,
    config: true,
    status: false,
    producing: false,
  };
  const [visibleFilters, setVisibleFilters] = useState(initVisibleFilters);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [urlId]);

  useEffect(() => {
    dispatch(readOperator(OPERATOR_STORE, urlId));
    dispatch(readRegion(REGION_STORE, ROOT_REGION_ID));
  }, [urlId, dispatch]);

  useEffect(() => {
    if (String(operatorId) === urlId) {
      const opIds = JSON.parse(subsidiaryIdsJson);
      opIds.push(operatorId);
      const wellFilters = { operatorId: opIds, isCurrentOperator: true };
      dispatch(setQuery(WELLS_QUERY, { limit: DEFAULT_PAGE_SIZE, sort: WELLS_DEFAULT_SORT, filters: wellFilters }));

      const transferFilters = { or: { fromOperatorId: opIds, toOperatorId: opIds } };
      dispatch(setQuery(TRANSFERS_QUERY, { sort: [{ transferDate: 'desc' }], filters: transferFilters }));
    }
  }, [operatorId, urlId, subsidiaryIdsJson, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!operator) {
    return null;
  }

  const resetWellsQuery = () => {
    const opIds = JSON.parse(subsidiaryIdsJson);
    opIds.push(operatorId);
    const filters = { operatorId: opIds, isCurrentOperator: true };
    dispatch(setQuery(WELLS_QUERY, { sort: [{ 'prodSummary.boeTotal': 'desc' }], filters }));
  };

  const resetTransfersQuery = () => {
    const opIds = JSON.parse(subsidiaryIdsJson);
    opIds.push(operatorId);
    const filters = { or: { fromOperatorId: opIds, toOperatorId: opIds } };
    dispatch(setQuery(TRANSFERS_QUERY, { sort: [{ transferDate: 'desc' }], filters }));
  };

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onVisibleFilterToggle = (id: string) => {
    const newVisibleFilters = { ...visibleFilters };
    newVisibleFilters[id] = !visibleFilters[id];
    setVisibleFilters(newVisibleFilters);
  };

  const operatorRegion = operator?.regionSummary?.[0];
  const hdrStyle = { display: 'inline-block', marginBottom: '0.5rem', marginRight: '0.5rem', marginLeft: '0.5rem' };

  return (
    <main className={'mainContent'}>
      <AsyncPage loading={isFetching} error={error}>
        <Container fluid={true}>
          <Row>
            <Col xs={12} sm={4} md={6}>
              <Link to={paths.LIST_OPERATORS} title={'View list of operators'}>
                Operators
              </Link>
              &nbsp;&gt;&nbsp;
              <h2 style={hdrStyle}>{operator.name}</h2>
            </Col>
            <Col xs={12} sm={8} md={6}>
              <Navbar>
                <Nav className={'ml-auto mr-0'}>
                  {operator && authUser?.canDataEntry && (
                    <NavItem>
                      <Link
                        className={'btn btn-outline-secondary ml-2'}
                        to={paths.admin.EDIT_OPERATOR.replace(':id', urlId)}
                        title={'Edit operator'}
                      >
                        Edit operator
                      </Link>
                    </NavItem>
                  )}
                </Nav>
              </Navbar>
            </Col>
          </Row>
          <>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === 'Summary' ? 'active' : ''}
                  onClick={() => toggle('Summary')}
                  title={'View operator summary'}
                >
                  Summary
                </NavLink>
              </NavItem>
              {operatorRegion ? (
                <>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'Wells' ? 'active' : ''}
                      onClick={() => toggle('Wells')}
                      title={'View operator wells list'}
                    >
                      Wells
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'Production' ? 'active' : ''}
                      onClick={() => toggle('Production')}
                      title={'View production table'}
                    >
                      Production
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'Decline' ? 'active' : ''}
                      onClick={() => toggle('Decline')}
                      title={'View decline chart'}
                    >
                      Decline
                    </NavLink>
                  </NavItem>
                </>
              ) : null}
              <NavItem>
                <NavLink
                  className={activeTab === 'Transfers' ? 'active' : ''}
                  onClick={() => toggle('Transfers')}
                  title={'View well transfers'}
                >
                  Transfers
                </NavLink>
              </NavItem>
              {(operator?.parents?.length ?? 0) > 0 || (operator?.subsidiaries?.length ?? 0) > 0 ? (
                <NavItem>
                  <NavLink
                    className={activeTab === 'OrgChart' ? 'active' : ''}
                    onClick={() => toggle('OrgChart')}
                    title={'View organization chart'}
                  >
                    Organization Chart
                  </NavLink>
                </NavItem>
              ) : null}
              {(operator?.events?.length ?? 0) > 0 ? (
                <NavItem>
                  <NavLink
                    className={activeTab === 'Events' ? 'active' : ''}
                    onClick={() => toggle('Events')}
                    title={'View events'}
                  >
                    Events
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>

            <div className={'mt-4'}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={'Summary'}>
                  <Row>
                    <Col md={4} lg={3} className={'mb-2'}>
                      <OperatorDetails operator={operator} />
                      {(operator?.parents?.length ?? 0) > 0 || (operator?.subsidiaries?.length ?? 0) > 0 ? (
                        <Card className={'mb-4'}>
                          <CardBody>
                            <CardTitle>Organization Hierarchy</CardTitle>
                            <hr />
                            <OperatorOrgTable storeIdentifier={OPERATOR_STORE} />
                          </CardBody>
                        </Card>
                      ) : null}
                    </Col>
                    <Col md={8} lg={9} className={'mb-4'}>
                      {operatorRegion ? (
                        <>
                          <div className={'mb-4'}>
                            <WellsMap storeIdentifier={WELLS_STORE} queryIdentifier={WELLS_QUERY} />
                          </div>
                          <div className={'mb-2 font-italic'}>{dataDisclaimer}</div>
                          <div className={'mb-4'}>
                            <Card>
                              <CardBody>
                                <CardTitle>Total Production ({productionUnitsTitle})</CardTitle>
                                <OperatorProductionChart
                                  storeIdentifier={OPERATOR_STORE}
                                  h={400}
                                  dataField={'boeTotal'}
                                  yTitle={`Production (${productionUnitsTitle})`}
                                  color={'lightblue'}
                                  regionId={ROOT_REGION_ID}
                                  lastFullYear={latestFullProductionYear}
                                />
                              </CardBody>
                            </Card>
                          </div>
                          <div className={'mb-4'}>
                            <Card>
                              <CardBody>
                                <CardTitle>Producing Wells</CardTitle>
                                <OperatorProductionChart
                                  storeIdentifier={OPERATOR_STORE}
                                  h={400}
                                  dataField={'wellCount'}
                                  yTitle={'Wells'}
                                  regionId={ROOT_REGION_ID}
                                  lastFullYear={latestFullProductionYear}
                                />
                              </CardBody>
                            </Card>
                          </div>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </TabPane>
                {operatorRegion ? (
                  <>
                    <TabPane tabId={'Wells'}>
                      <div style={{ display: 'flex', border: '1px solid #e8e8e8' }}>
                        <div
                          style={{
                            borderRight: '1px solid #e8e8e8',
                            padding: '1rem',
                            backgroundColor: '#f7f7f7',
                            width: '300px',
                            minWidth: '200px',
                          }}
                        >
                          <WellsFilterForm
                            storeIdentifier={WELLS_STORE}
                            queryIdentifier={WELLS_QUERY}
                            visibleFilters={visibleFilters}
                            onVisibleFilterToggle={onVisibleFilterToggle}
                            resetQueryFilter={resetWellsQuery}
                            showLocationFilter={true}
                          />
                        </div>
                        <div style={{ padding: '1rem', flexGrow: 1, width: '100%' }}>
                          <ListCount storeIdentifier={WELLS_STORE} label={'Well'} />
                          <WellList storeIdentifier={WELLS_STORE} queryIdentifier={WELLS_QUERY} />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={'Production'}>
                      <OperatorProductionList storeIdentifier={OPERATOR_STORE} disclaimer={dataDisclaimer} />
                    </TabPane>
                    <TabPane tabId={'Decline'}>
                      <Row>
                        <Col sm={12}>
                          <div className={'my-2 font-italic'}>{dataDisclaimer}</div>
                        </Col>
                        <Col md={12} className={'mb-2'}>
                          <OperatorDeclineChart
                            storeIdentifier={OPERATOR_STORE}
                            regionStoreIdentifier={REGION_STORE}
                            label={'Operator'}
                            title={
                              <>
                                YoY Pct Change, <DeclineChartLegendSwatch color={FIRST_DECLINE_COLOR} /> Operator vs{' '}
                                <DeclineChartLegendSwatch color={SECOND_DECLINE_COLOR} /> {ROOT_REGION_NAME}
                              </>
                            }
                            minYear={2010}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </>
                ) : null}
                <TabPane tabId={'Transfers'}>
                  <div style={{ display: 'flex', border: '1px solid #e8e8e8' }}>
                    <div
                      style={{
                        borderRight: '1px solid #e8e8e8',
                        padding: '1rem',
                        backgroundColor: '#f7f7f7',
                        width: '300px',
                        minWidth: '200px',
                      }}
                    >
                      <TransfersFilterForm
                        storeIdentifier={TRANSFERS_STORE}
                        queryIdentifier={TRANSFERS_QUERY}
                        visibleFilters={visibleFilters}
                        onVisibleFilterToggle={onVisibleFilterToggle}
                        resetQueryFilter={resetTransfersQuery}
                        showLocationFilter
                        operatorFilterType={'byId'}
                      />
                    </div>
                    <div style={{ padding: '1rem', flexGrow: 1, width: '100%' }}>
                      <TransferList storeIdentifier={TRANSFERS_STORE} queryIdentifier={TRANSFERS_QUERY} />
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId={'OrgChart'}>
                  <Row>
                    <Col md={12} className={'mb-2'}>
                      <OperatorOrgChart storeIdentifier={OPERATOR_STORE} />
                    </Col>
                  </Row>
                </TabPane>
                {(operator?.events?.length ?? 0) > 0 ? (
                  <TabPane tabId={'Events'}>
                    <Row>
                      <Col md={12} className={'mb-6'}>
                        <div>{operator.events.length.toLocaleString()} Events</div>
                        <EventList operatorId={operator.id} events={operator.events} />
                      </Col>
                    </Row>
                  </TabPane>
                ) : null}
              </TabContent>
            </div>
          </>
        </Container>
      </AsyncPage>
    </main>
  );
};

export default ViewOperator;
