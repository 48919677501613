import React from 'react';

import { useAppSelector } from '../hooks';

interface ListCountProps {
  label: string;
  labelPlural?: string;
  storeIdentifier: string;
}

const ListCount = (props: ListCountProps) => {
  const { label, labelPlural, storeIdentifier } = props;
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const total: number | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload?.total);

  return error || total === undefined ? null : (
    <h5 className={'mb-2'}>
      Found {total.toLocaleString()} {total === 1 ? label : labelPlural || label + 's'}
    </h5>
  );
};

export default ListCount;
