import React from 'react';
import { Col, Row, Container, Navbar } from 'reactstrap';

// const styles: StylesDictionary = {
//   footer: {
//     display: 'block',
//     width: '100%',
//   },
// };

const Footer = () => {
  return (
    <Navbar color="dark" dark>
      <Container id={'footer-container'} fluid style={{ color: '#dddddd' }}>
        <Row style={{ width: '100%' }}>
          <Col xs={{ size: 12 }}>
            <div style={{ textAlign: 'center' }}>
              <small>
                <a href="https://thecapitolforum.com/about-us/" target="tcf" style={{ color: '#dddddd' }}>
                  About TCF
                </a>
                &nbsp; &bull; &nbsp;
                <a href="https://twitter.com/Capitol_Forum" target="twitter" style={{ color: '#dddddd' }}>
                  <img src="/twitter.png" alt="@Capitol_Forum" style={{ marginTop: '-5px' }} /> @Capitol_Forum
                </a>
                &nbsp; &bull; &nbsp;
                <a href="https://www.linkedin.com/company/the-capitol-forum" target="linkedin" style={{ color: '#dddddd' }}>
                  <img src="/linkedin.png" alt="LinkedIn" style={{ marginTop: '-5px' }} /> LinkedIn
                </a>
                &nbsp; &bull; &nbsp;
                <a href="mailto:editorial@thecapitolforum.com" style={{ color: '#dddddd' }}>
                  Contact support
                </a>
              </small>
            </div>
            <div
              className={'mt-2 mb-2'}
              style={{
                fontSize: '0.7rem',
                lineHeight: '100%',
                textAlign: 'center',
              }}
            >
              &copy; 2012-{new Date().getFullYear()} The Capitol Forum. Direct or indirect reproduction or distribution of any
              article without prior written permission from The Capitol Forum is a violation of Federal Copyright Law.
            </div>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Footer;
