import React, { useEffect } from 'react';
import Loader from 'react-loader';

import { SearchResults } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { readDocuments } from '../../../actions/documentActions';
import { paths } from '../../../paths';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

interface DocumentListProps {
  storeIdentifier: string;
}

const styles: { [key: string]: React.CSSProperties } = {
  articleList: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  articleDate: {
    fontSize: '.75rem',
  },
  articleTitle: {
    fontSize: '1rem',
    lineHeight: '1.3',
    marginBottom: '.25rem',
    textAlign: 'justify',
  },
  articleText: {
    fontSize: '.9rem',
    lineHeight: '1.3',
    textAlign: 'justify',
    textJustify: 'inter-word',
  },
};

const DocumentList = (props: DocumentListProps) => {
  const dispatch = useAppDispatch();
  const { storeIdentifier } = props;

  const documents: SearchResults = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);

  useEffect(() => {
    dispatch(readDocuments(storeIdentifier, {}));
  }, [storeIdentifier, dispatch]);

  if (isFetching) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!documents?.total || documents.total < 1) {
    return null;
  }

  const results = documents?.results || [];

  return (
    <div style={styles.articleList}>
      {results.map((d: any) => (
        <div key={d.id}>
          <div style={styles.articleDate}>{d.meta.publishing?.displayPublishedAt.toString().slice(0, 10)}</div>
          <div style={styles.articleTitle}>
            <a href={paths.VIEW_DOCUMENT.replace(':id', d.id!)} title={'Read entire article'}>
              {d.title}
            </a>
          </div>
          {(d.attachment?.content?.length || 0) > 0 ? (
            <div style={styles.articleText}>
              {d.attachment.content}&nbsp;
              <a href={paths.VIEW_DOCUMENT.replace(':id', d.id)} title={'Read entire article'}>
                Read more
              </a>
            </div>
          ) : null}
          <hr />
        </div>
      ))}
    </div>
  );
};

export default DocumentList;
