import React, { useEffect } from 'react';

import ListCount from '../../components/ListCount';
import ReportList from './components/ReportList';

const REPORTS_STORE = 'reportsListStore';
const REPORTS_QUERY = 'reportsListQuery';

const ListReports = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={'flexPage'}>
      <main className={'mainContent'}>
        <ListCount storeIdentifier={REPORTS_STORE} label={'Data Report'} />
        <div className={'mt-4'}>
          <ReportList storeIdentifier={REPORTS_STORE} queryIdentifier={REPORTS_QUERY} />
        </div>
      </main>
    </div>
  );
};

export default ListReports;
