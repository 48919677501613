import React from 'react';

interface OwnProps {
  onPlus: () => void;
  onMinus: () => void;
}

const ZoomControl = (props: OwnProps) => {
  const { onPlus, onMinus } = props;

  return (
    <div
      className="gmnoprint gm-bundled-control gm-bundled-control-on-bottom"
      draggable="false"
      style={{ margin: '10px', userSelect: 'none', position: 'absolute', bottom: '154px', right: '40px' }}
    >
      <div className="gmnoprint" style={{ position: 'absolute', left: '0px', top: '72px' }}>
        <div
          draggable="false"
          style={{
            userSelect: 'none',
            boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
            borderRadius: '2px',
            cursor: 'pointer',
            backgroundColor: 'rgb(255, 255, 255)',
            width: '40px',
            height: '81px',
          }}
        >
          <button
            draggable="false"
            title="Zoom in"
            aria-label="Zoom in"
            type="button"
            className="gm-control-active"
            style={{
              background: 'none',
              display: 'block',
              border: '0px',
              margin: '0px',
              padding: '0px',
              textTransform: 'none',
              appearance: 'none',
              position: 'relative',
              cursor: 'pointer',
              userSelect: 'none',
              overflow: 'hidden',
              width: '40px',
              height: '40px',
              top: '0px',
              left: '0px',
              outline: 'none',
            }}
            onClick={onPlus}
          >
            <img
              src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M18%207h-7V0H7v7H0v4h7v7h4v-7h7z%22/%3E%3C/svg%3E"
              alt=""
              style={{ height: '18px', width: '18px' }}
            />
            <img
              src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M18%207h-7V0H7v7H0v4h7v7h4v-7h7z%22/%3E%3C/svg%3E"
              alt=""
              style={{ height: '18px', width: '18px' }}
            />
            <img
              src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23111%22%20d%3D%22M18%207h-7V0H7v7H0v4h7v7h4v-7h7z%22/%3E%3C/svg%3E"
              alt=""
              style={{ height: '18px', width: '18px' }}
            />
          </button>
          <div
            style={{
              position: 'relative',
              overflow: 'hidden',
              width: '30px',
              height: '1px',
              margin: '0px 5px',
              backgroundColor: 'rgb(230, 230, 230)',
              top: '0px',
            }}
          />
          <button
            draggable="false"
            title="Zoom out"
            aria-label="Zoom out"
            type="button"
            className="gm-control-active"
            style={{
              background: 'none',
              display: 'block',
              border: '0px',
              margin: '0px',
              padding: '0px',
              textTransform: 'none',
              appearance: 'none',
              position: 'relative',
              cursor: 'pointer',
              userSelect: 'none',
              overflow: 'hidden',
              width: '40px',
              height: '40px',
              top: '0px',
              left: '0px',
              outline: 'none',
            }}
            onClick={onMinus}
          >
            <img
              src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%207h18v4H0V7z%22/%3E%3C/svg%3E"
              alt=""
              style={{ height: '18px', width: '18px' }}
            />
            <img
              src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M0%207h18v4H0V7z%22/%3E%3C/svg%3E"
              alt=""
              style={{ height: '18px', width: '18px' }}
            />
            <img
              src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23111%22%20d%3D%22M0%207h18v4H0V7z%22/%3E%3C/svg%3E"
              alt=""
              style={{ height: '18px', width: '18px' }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ZoomControl;
