import React from 'react';
import { Button } from 'reactstrap';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { resetQueryFilter } from '../../../actions/queryActions';
import Accordion from '../../../components/Accordion';
import StatesFilter from '../../../components/StatesFilter';
import DataTypesFilter from '../../../components/DataTypesFilter';

export interface VisibleFilters {
  location: boolean;
  dataType: boolean;
}

export interface FilterSourcesFormProps {
  queryIdentifier: string;
  storeIdentifier: string;
  visibleFilters: VisibleFilters;
  onVisibleFilterToggle: any;
}

type Aggs = { states: any; dataTypes: any };

const FilterSourcesForm = (props: FilterSourcesFormProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier, storeIdentifier, visibleFilters, onVisibleFilterToggle } = props;
  const onClearFiltersClick = () => {
    dispatch(resetQueryFilter(queryIdentifier));
  };
  const aggregations: Aggs = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload?.aggregations);

  return (
    <div>
      <Accordion title={'State:'} id={'location'} isOpen={visibleFilters.location} handleToggle={onVisibleFilterToggle}>
        <StatesFilter queryIdentifier={queryIdentifier} filterField={'stateAbbrev'} values={aggregations?.states?.buckets} />
      </Accordion>
      <hr />
      <Accordion title={'Data Type:'} id={'dataType'} isOpen={visibleFilters.dataType} handleToggle={onVisibleFilterToggle}>
        <DataTypesFilter queryIdentifier={queryIdentifier} filterField={'dataType'} values={aggregations?.dataTypes?.buckets} />
      </Accordion>
      <hr />
      <Button size={'sm'} className={'mt-3 btn-block'} onClick={onClearFiltersClick}>
        Clear all filters
      </Button>
    </div>
  );
};

export default FilterSourcesForm;
