export class MemoryStorage {
  private storage = {};

  get length() {
    return Object.keys(this.storage).length;
  }

  clear() {
    this.storage = {};
  }

  getItem(key: string): string | null {
    return key ? this.storage[key] : null;
  }

  setItem(key: string, data: string) {
    if (!key) {
      return;
    }
    this.storage[key] = data;
  }

  removeItem(key: string) {
    this.storage[key] = undefined;
  }

  key(index: number) {
    return Object.keys(this.storage)[index];
  }
}

export const local = window.localStorage || new MemoryStorage();

export const session = window.sessionStorage || new MemoryStorage();
