import React from 'react';
import { Input, InputGroup, InputGroupAddon, Label, FormGroup, FormFeedback } from 'reactstrap';
import { Field as RFField } from 'react-final-form';

export interface TcfTextInputProps {
  disabled?: boolean;
  label?: string;
  className?: string;
  placeholder?: string;
  name: string;
  button?: React.ReactElement;
  bsSize?: 'lg' | 'sm';
  style?: object;
  required?: boolean;
  maxLength?: number;
  rows?: number;
  type:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'select'
    | 'submit'
    | 'tel'
    | 'text'
    | 'textarea'
    | 'time'
    | 'url'
    | 'week';
  validate?: (value: any) => string | undefined;
}

const TcfTextInput = (props: TcfTextInputProps) => {
  return (
    <RFField
      className={props.className}
      placeholder={props.placeholder}
      name={props.name}
      type={props.type}
      component={renderTcfTextInput}
      props={{
        label: props.label,
        name: props.name,
        button: props.button,
        bsSize: props.bsSize,
        style: props.style,
        required: props.required,
        maxLength: props.maxLength,
        rows: props.rows,
      }}
      disabled={props.disabled}
      validate={props.validate}
    />
  );
};

const renderInputGroup = (field: any, hasError: boolean) => {
  const input = (
    <Input
      id={field.props.name}
      className={field.className}
      type={field.type}
      placeholder={field.placeholder}
      invalid={hasError}
      {...field.input}
      bsSize={field.props.bsSize}
      style={field.props.style}
      disabled={field.disabled}
      required={field.props.required}
      maxLength={field.props.maxLength}
      rows={field.props.rows}
    />
  );

  if (!field.props.button) {
    return input;
  }

  return (
    <InputGroup>
      {input}
      <InputGroupAddon addonType={'append'}>{field.props.button}</InputGroupAddon>
    </InputGroup>
  );
};

const renderTcfTextInput = (field: any) => {
  const hasError = field.meta.touched && field.meta.error;
  const required = (field.props.required && <span style={{ color: 'red' }}> *</span>) || null;

  return (
    <FormGroup color={hasError ? 'danger' : ''} width={'500px'}>
      {field.props.label ? (
        <Label for={field.props.name} className="field-label">
          {field.props.label}
          {required}
        </Label>
      ) : null}
      {renderInputGroup(field, !!hasError)}
      {hasError && <FormFeedback>{field.meta.error}</FormFeedback>}
    </FormGroup>
  );
};

export default TcfTextInput;
