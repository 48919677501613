import { useEffect, useState } from 'react';

// Use this to delay refreshing asynchronous components that may refresh very quickly thus appearing to
// flicker.  It basically just waits delayMilliseconds before returning false which you can then use to
// display your "loading" graphic or text or whatever.
const useDelayedRender = (isDoingSomething: boolean, delayMilliseconds: number = 700) => {
  const [delayRender, setDelayRender] = useState(false);

  useEffect(() => {
    if (isDoingSomething) {
      setDelayRender(true);
      const timeout = setTimeout(() => setDelayRender(false), delayMilliseconds);
      return () => clearTimeout(timeout);
    }
  }, [isDoingSomething, delayMilliseconds]);

  return delayRender;
};

export default useDelayedRender;
