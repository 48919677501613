import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import { Card, CardTitle, CardBody } from 'reactstrap';

import { SearchResults } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector, useProductionUnits } from '../../../hooks';
import { readWells } from '../../../actions/wellActions';
import { paths } from '../../../paths';
import TcfBootstrapTable, {
  formatApi,
  formatSiteName,
  formatNumber,
  formatObjectCountyName,
} from '../../../components/TcfBootstrapTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

export const availableFields = [
  'api',
  'site',
  'countyName',
  'prodSummary.boeTotal',
  'type',
  'config',
  'status',
  'spud',
  'operators',
];

interface LocalWellListProps {
  storeIdentifier: string;
  queryString: string;
  label?: string;
  hideFields?: string[];
  hideCount?: boolean;
  children?: any;
}

const LocalWellList = (props: LocalWellListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true);
  const { storeIdentifier, queryString, label, hideCount } = props;
  const payload: SearchResults = useAppSelector((state) => state.serverStores?.[props.storeIdentifier]?.payload);
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[props.storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[props.storeIdentifier]?.isFetching);
  const hideFields = props.hideFields || [];
  const { title: productionUnitsTitle, convertToSelectedUnits } = useProductionUnits();

  useEffect(() => {
    const _query = JSON.parse(queryString);
    dispatch(readWells(storeIdentifier, _query));
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!payload?.total || payload.total < 1) {
    return null;
  }

  const formatOperator = (cell: any) =>
    cell && cell.length > 0
      ? cell
          .filter((c: any) => c.isCurrentOperator)
          .map((c: any) => (
            <div key={c.operatorId}>
              <Link to={paths.VIEW_OPERATOR.replace(':id', c.operatorId!.toString())} title={'View operator'}>
                {c.operatorName}
              </Link>
            </div>
          ))
      : '';

  const dataSource = (payload?.results || []).map((o) => ({
    ...o,
    prodSummary: o.prodSummary ? { ...o.prodSummary, boeTotal: convertToSelectedUnits(o.prodSummary.boeTotal) } : o.prodSummary,
  }));

  const columns = [
    {
      dataField: 'api',
      text: 'API',
      formatter: formatApi,
      sort: false,
      hidden: hideFields.includes('api'),
    },
    {
      dataField: 'site',
      text: 'Site Name',
      formatter: formatSiteName,
      sort: false,
      hidden: hideFields.includes('site'),
    },
    {
      dataField: 'operators',
      text: 'Operator',
      sort: false,
      hidden: hideFields.includes('operators'),
      formatter: formatOperator,
    },
    {
      dataField: 'countyName',
      text: 'Region',
      sort: false,
      formatter: formatObjectCountyName,
      hidden: hideFields.includes('countyName'),
    },
    {
      dataField: 'prodSummary.boeTotal',
      text: `Total ${productionUnitsTitle}`,
      formatter: formatNumber,
      sort: false,
      hidden: hideFields.includes('prodSummary.boeTotal'),
      align: 'right',
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: false,
      hidden: hideFields.includes('type'),
    },
    {
      dataField: 'config',
      text: 'Config',
      sort: false,
      hidden: hideFields.includes('config'),
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: false,
      hidden: hideFields.includes('status'),
    },
    {
      dataField: 'spud',
      text: 'Spud',
      sort: false,
      hidden: hideFields.includes('spud'),
    },
  ];

  const totalSize = payload?.total || 0;

  return (
    <Card className={'mb-4'}>
      <CardBody>
        {label ? (
          <CardTitle>
            {label}: {hideCount ? null : totalSize.toLocaleString()}
          </CardTitle>
        ) : null}
        <TcfBootstrapTable allowWrap columns={columns} dataSource={dataSource} totalSize={totalSize} />
        {props.children}
      </CardBody>
    </Card>
  );
};

export default LocalWellList;
