import { asyncAction } from './asyncAction';
import { library } from '../libraryApi';
import { LOG_USAGE } from './actionTypes';

export function visitPage(id: string, url: string) {
  return asyncAction({
    actionType: LOG_USAGE,
    func: async () => (await library.usage.post({ type: 'visit', product: 'upstream', id, url })).data,
  });
}

export function leavePage(id: string, url: string) {
  return asyncAction({
    actionType: LOG_USAGE,
    func: async () => (await library.usage.post({ type: 'leave', product: 'upstream', id, url })).data,
  });
}
