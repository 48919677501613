import { axios } from './axios';

import { REPORTS_URL, DATA_EXPORTS_URL } from 'tcf-upstream-shared/urls';

import { AuthApiClient } from './AuthApiClient';
import { CrudApiClient, CrudApiClient2 } from './CrudApiClient';
import { WellsApiClient } from './WellsApiClient';

export const library = {
  adminObjects: new CrudApiClient2(axios),
  auth: new AuthApiClient(axios),
  dataExports: new CrudApiClient(axios, DATA_EXPORTS_URL),
  operators: new CrudApiClient(axios, '/operators'),
  documents: new CrudApiClient(axios, '/docs'),
  productions: new CrudApiClient(axios, '/productions'),
  regions: new CrudApiClient(axios, '/regions'),
  reports: new CrudApiClient(axios, REPORTS_URL),
  sources: new CrudApiClient(axios, '/sources'),
  transfers: new CrudApiClient(axios, '/transfers'),
  usage: new CrudApiClient(axios, '/usage'),
  wells: new WellsApiClient(axios),
};
