import { Query, BoxQuery, MAX_WELLS_TO_MAP } from 'tcf-upstream-shared/models';
import { library } from '../libraryApi';
import { READ_WELL_LOOKUPS, READ_SERVER } from './actionTypes';
import { asyncAction } from './asyncAction';

// const reset = (actionType: string) => ({ type: actionType });
//
// export const resetWellStore = reset(a.RESET_WELL_STORE);
// export const resetWellsStore = reset(a.RESET_WELLS_STORE);
// export const resetWellsStatsStore = reset(a.RESET_WELLS_STATS_STORE);
// export const resetWellsForOperatorsStore = reset(a.RESET_WELLS_FOR_OPERATORS_STORE);

export function readWells(storeIdentifier: string, query: Query) {
  query.q = query.q || '*';
  query.skip = query.skip || 0;
  query.limit = query.limit || 25;
  query.filters = query.filters || {};

  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.wells.search(query)).data,
    storeIdentifier,
    requestPayload: { query },
  });
}

export function exportWells(
  storeIdentifier: string,
  query: Query,
  units: string,
  localDatetime: string,
  exportType?: string,
  exportParams?: object,
) {
  query.q = query.q || '*';
  query.filters = query.filters || {};

  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.wells.searchExport(query, units, localDatetime, exportType, exportParams)).data,
    storeIdentifier,
    requestPayload: { query, exportType, exportParams },
  });
}

export function readWellsStats(storeIdentifier: string, query: Query) {
  query.q = query.q || '*';
  query.skip = 0;
  query.limit = 0;
  query.filters = query.filters || {};

  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.wells.searchStats(query)).data,
    storeIdentifier,
    requestPayload: { query },
  });
}

export function readWell(storeIdentifier: string, id: string | number) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.wells.get(id)).data,
    storeIdentifier,
  });
}

export function readWellLookups() {
  return asyncAction({
    actionType: READ_WELL_LOOKUPS,
    func: async () => (await library.wells.getWellLookups()).data,
  });
}

export async function readWellsByBoundingBox(query: BoxQuery) {
  query.limit = query.limit || MAX_WELLS_TO_MAP;
  query.filters = query.filters || {};

  try {
    return (await library.wells.search(query)).data;
  } catch (err) {
    return null;
  }
}
