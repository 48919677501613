import { Query } from 'tcf-upstream-shared/models';
import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { READ_SERVER } from './actionTypes';

// const reset = (actionType: string) => ({ type: actionType });
//
// export const resetOperatorStore = reset(a.RESET_OPERATOR_STORE);
// export const resetOperatorsStore = reset(a.RESET_OPERATORS_STORE);
// export const resetOperatorsProductionStore = reset(a.RESET_OPERATORS_PRODUCTION_STORE);
// export const resetOperatorSubsidiariesStore = reset(a.RESET_OPERATOR_SUBSIDIARIES_STORE);

export function readOperator(storeIdentifier: string, id: string | number) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.operators.get(id)).data,
    storeIdentifier,
  });
}

export function readOperators(storeIdentifier: string, query: Query) {
  query.q = query.q || '*';
  query.skip = query.skip || 0;
  query.limit = query.limit || 25;
  query.filters = query.filters || {};

  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.operators.search(query)).data,
    storeIdentifier,
    requestPayload: { query },
  });
}

export function exportOperators(
  storeIdentifier: string,
  query: Query,
  units: string,
  localDatetime: string,
  exportType?: string,
  exportParams?: object,
) {
  query.q = query.q || '*';
  query.filters = query.filters || {};

  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.operators.searchExport(query, units, localDatetime, exportType, exportParams)).data,
    storeIdentifier,
    requestPayload: { query, exportType, exportParams },
  });
}
