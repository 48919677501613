import React, { useEffect } from 'react';
import Loader from 'react-loader';
import { Treebeard, theme } from 'react-treebeard';
import { useHistory } from 'react-router-dom';

import { Operator } from 'tcf-upstream-shared/models';

import { useAppSelector, useAppDispatch } from '../../../hooks';
import { readOperator } from '../../../actions/operatorActions';
import { resetStore } from '../../../actions/serverActions';
import { paths } from '../../../paths';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

const ROOT_PARENT_STORE = 'operatorRootParentStore';

interface OperatorOrgTableProps {
  storeIdentifier: string;
}

const getChildren = (nodeId: number, targetNodeId: number, subsidiaries: any[]): any => {
  return (
    subsidiaries
      .filter((s: any) => s.parentId === nodeId)
      .sort((a: any, b: any) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      .map((s: any) => ({
        ...s,
        toggled: true,
        active: s.id === targetNodeId,
        children: getChildren(s.id, targetNodeId, subsidiaries),
      })) ?? []
  );
};

const getTree = (rootParent: Operator, operator: Operator) => {
  // const parents = operator?.parents?.sort((a: any, b: any) => b.depth - a.depth) ?? [];
  const subsidiaries = (rootParent || operator)?.subsidiaries ?? [];
  if (subsidiaries.length < 1) return null;

  const tree: any = {};
  if (rootParent) {
    tree.id = rootParent.id;
    tree.name = rootParent.name;
    tree.active = false;
    tree.toggled = true;
  } else {
    tree.id = operator.id;
    tree.name = operator.name;
    tree.active = true;
    tree.toggled = true;
  }
  tree.children = subsidiaries.length > 0 ? getChildren(tree.id, operator.id, subsidiaries) : [];
  return tree;
};

const OperatorOrgTable = (props: OperatorOrgTableProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { storeIdentifier } = props;
  const error: string | undefined = useAppSelector(
    (state) => state.serverStores?.[storeIdentifier]?.error || state.serverStores?.[ROOT_PARENT_STORE]?.error,
  );
  const isFetching: boolean = useAppSelector(
    (state) => state.serverStores?.[storeIdentifier]?.isFetching || state.serverStores?.[ROOT_PARENT_STORE]?.isFetching,
  );
  const operator: Operator = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const operatorId = operator?.id;
  const operatorParents = operator?.parents?.sort((a: any, b: any) => b.depth - a.depth) ?? [];
  const rootParentId = operatorParents?.[0]?.id || null;
  const rootParent: Operator = useAppSelector((state) => state.serverStores?.[ROOT_PARENT_STORE]?.payload);

  useEffect(() => {
    if (operatorId && rootParentId) {
      dispatch(readOperator(ROOT_PARENT_STORE, rootParentId));
    }
    return () => {
      dispatch(resetStore(ROOT_PARENT_STORE));
    };
  }, [rootParentId, operatorId, dispatch]);

  if (isFetching) return <Loader loaded={false} />;
  else if (error) return <ErrorComponent error={error} />;
  else if (!operator) return null;

  const data = getTree(rootParent, operator);
  if (!data) return null;

  const onToggle = (node: any) => {
    if (node.id !== operator.id) {
      history.replace(paths.VIEW_OPERATOR.replace(':id', node.id.toString()));
    }
  };

  const styleTheme = theme;
  styleTheme.tree.base.backgroundColor = undefined;
  styleTheme.tree.base.fontSize = undefined;
  styleTheme.tree.base.fontFamily = undefined;
  styleTheme.tree.base.color = undefined;
  styleTheme.tree.node.header.base.color = '#007bff';
  styleTheme.tree.node.activeLink.color = '#212529';
  styleTheme.tree.node.activeLink.background = '#fff';
  styleTheme.tree.node.activeLink.fontWeight = 'bold';

  return (
    <div style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
      <Treebeard data={data} style={styleTheme} onToggle={onToggle} />
    </div>
  );
};

export default OperatorOrgTable;
