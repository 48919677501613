import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import { isString } from 'lodash';

import { ROOT_REGION_ID } from 'tcf-upstream-shared/constants';
import { Operator, OperatorRegionSummary, OperatorRegionYear } from 'tcf-upstream-shared/models';

import { useProductionUnits, useAppDispatch, useAppSelector } from '../../../hooks';
import { setQuery, setQueryPagingAndSorting } from '../../../actions/queryActions';
import { exportOperators, readOperators } from '../../../actions/operatorActions';
import { readRegion } from '../../../actions/regionActions';
import { paths } from '../../../paths';
import TcfBootstrapTable, {
  formatBoeYear,
  formatDeclineYear,
  formatNumber,
  formatOperatorName,
  formatStates,
  getPagingParamsForTable,
  TableState,
} from '../../../components/TcfBootstrapTable';
import DataExportButton from '../../../components/DataExportButton';
import ListCount from '../../../components/ListCount';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import { Button, Form, FormGroup, Input } from 'reactstrap';

const DATA_EXPORT_STORE = 'operatorListDataExportStore';
const REGION_STORE = 'operatorListRegionStore';
const NESTED_FIELDS = ['regionSummary', 'stateSummary', 'countySummary', 'prodYearRegion', 'prodYearState', 'prodYearCounty'];
const CHANGE_FIELDS = ['periodXBoe', 'periodYBoe'];

// const DEFAULT_SORT: any[] = [{ regionSummary: { field: 'boeTotal', regionId: ROOT_REGION_ID, order: 'desc' } }];
const DEFAULT_PROD_SORT: any[] = [{ boeChange: 'desc' }];
// const DEFAULT_FILTERS = { isProducing: true, parentId: null };
const DEFAULT_PAGE_SIZE = 25;

const CURRENT_DATE = new Date();
const CURRENT_YEAR = CURRENT_DATE.getFullYear();
const PROD_MIN_YEAR = 2020;
const PROD_MAX_YEAR = 2023;
const DEFAULT_LAST_FULL_PRODUCTION_YEAR = 2021;

const PERIOD_OPTIONS = {
  boeTotal: 'Total/YTD',
  // boeH2: '2nd Half',
  // boeH1: '1st Half',
  // boeQ1: 'Q1',
  // boeQ2: 'Q2',
  // boeQ3: 'Q3',
  // boeQ4: 'Q4',
  boeJan: 'Jan',
  boeFeb: 'Feb',
  boeMar: 'Mar',
  boeApr: 'Apr',
  boeMay: 'May',
  boeJun: 'Jun',
  boeJul: 'Jul',
  boeAug: 'Aug',
  boeSep: 'Sep',
  boeOct: 'Oct',
  boeNov: 'Nov',
  boeDec: 'Dec',
};

interface OperatorListProps {
  storeIdentifier: string;
  queryIdentifier: string;
  regionId: number;
  showAlias?: boolean;
  showWellStates?: boolean;
  showStatus?: boolean;
  showDecline?: boolean;
}

const OperatorList = (props: OperatorListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true); // Reduce chance of stale data being used
  const [listType, setListType] = useState('basic');
  const [yearX, setYearX] = useState((PROD_MAX_YEAR - 1).toString());
  const [yearY, setYearY] = useState(PROD_MAX_YEAR.toString());
  const [periodX, setPeriodX] = useState('boeTotal');
  const [periodY, setPeriodY] = useState('boeTotal');
  const [changeParams, setProdParams] = useState({
    yearX: PROD_MAX_YEAR - 1,
    yearY: PROD_MAX_YEAR,
    periodX: 'boeTotal',
    periodY: 'boeTotal',
  });
  const { storeIdentifier, queryIdentifier, regionId, showAlias, showWellStates, showStatus, showDecline } = props;
  const prodYearField = regionId >= ROOT_REGION_ID ? 'prodYearRegion' : regionId < 100 ? 'prodYearState' : 'prodYearCounty';
  const summaryField = regionId >= ROOT_REGION_ID ? 'regionSummary' : regionId < 100 ? 'stateSummary' : 'countySummary';
  const defaultBasicSort: any[] = [{ [summaryField]: { field: 'boeTotal', regionId, order: 'desc' } }];

  const authUser = useAppSelector((state) => state.auth.authUser);
  const query = useAppSelector((state) => state.queries[queryIdentifier]);
  const isFetching = useAppSelector(
    (state) => state.serverStores?.[storeIdentifier]?.isFetching || state.serverStores?.[REGION_STORE]?.isFetching,
  );
  const error = useAppSelector(
    (state) => state.serverStores?.[storeIdentifier]?.error || state.serverStores?.[REGION_STORE]?.error,
  );
  const payload = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const region = useAppSelector((state) => state.serverStores?.[REGION_STORE]?.payload);
  const { title: productionUnitsTitle, convertToSelectedUnits } = useProductionUnits();
  const queryString = JSON.stringify(query);

  useEffect(() => {
    dispatch(readRegion(REGION_STORE, regionId));
  }, [regionId, dispatch]);

  useEffect(() => {
    const _query = queryString ? JSON.parse(queryString) : undefined;
    const boeChange = _query?.dynamicFieldParams?.boeChange;
    if (_query) {
      if (listType === 'basic' && boeChange) {
        delete _query.dynamicFieldParams;
        const operatorSort = [{ [summaryField]: { field: 'boeTotal', regionId, order: 'desc' } }];
        dispatch(setQuery(queryIdentifier, { ..._query, skip: 0, limit: DEFAULT_PAGE_SIZE, sort: operatorSort }));
      } else if (
        listType === 'change' &&
        (boeChange?.yearX !== changeParams.yearX ||
          boeChange?.periodX !== changeParams.periodX ||
          boeChange?.yearY !== changeParams.yearY ||
          boeChange?.periodY !== changeParams.periodY)
      ) {
        const dynamicFieldParams = { boeChange: { ...changeParams }, prodYearField, regionId };
        dispatch(
          setQuery(queryIdentifier, {
            ..._query,
            dynamicFieldParams,
            skip: 0,
            limit: DEFAULT_PAGE_SIZE,
            sort: DEFAULT_PROD_SORT,
          }),
        );
      }
    }
  }, [summaryField, prodYearField, changeParams, listType, regionId, queryString, queryIdentifier, dispatch]);

  useEffect(() => {
    if (queryString) {
      const _query = JSON.parse(queryString);
      dispatch(readOperators(storeIdentifier, _query));
    }
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) return <Loader loaded={false} />;
  if (error) return <ErrorComponent error={error} />;
  if (!payload?.total || payload.total < 1) {
    return <ListCount storeIdentifier={storeIdentifier} label={'Operator'} />;
  }

  const handleListTypeChange = (event: any) => setListType(event.target.value);
  const handleYearXChange = (event: any) => setYearX(event.target.value);
  const handleYearYChange = (event: any) => setYearY(event.target.value);
  const handlePeriodXChange = (event: any) => setPeriodX(event.target.value);
  const handlePeriodYChange = (event: any) => setPeriodY(event.target.value);
  const updateProdParams = () =>
    setProdParams({
      yearX: parseInt(yearX, 10),
      yearY: parseInt(yearY, 10),
      periodX,
      periodY,
    });

  const latestFullProductionYear = region?.latestFullProductionYear || DEFAULT_LAST_FULL_PRODUCTION_YEAR;

  const operators = payload.results!.map((o: any) => {
    const regionSummary = o[summaryField]?.find((r: OperatorRegionSummary) => r.regionId === regionId);
    const prodYear = o[prodYearField]
      ?.filter((r: OperatorRegionYear) => r.regionId === regionId && r.year >= PROD_MIN_YEAR)
      .map((py: OperatorRegionYear) => ({ ...py, boeTotal: convertToSelectedUnits(py.boeTotal) }));
    const strippedName = o.name.replace(/[^a-zA-Z0-9]/g, '');
    return {
      id: o.id,
      name: o.name,
      ticker: o.ticker,
      isTickerActive: o.isTickerActive,
      city: o.city,
      stateAbbrev: o.stateAbbrev,
      [summaryField]: { ...regionSummary, boeTotal: convertToSelectedUnits(regionSummary.boeTotal) },
      prodYear,
      isActive: o.isActive,
      boeChange: o.boeChange,
      [prodYearField]: o[prodYearField],
      alias:
        o.alias?.length ?? 0 ? o.alias.filter((a: string) => a.replace(/[^a-zA-Z0-9]/g, '') !== strippedName).sort() : null,
    };
  });

  const formatTicker = (cell: string, row: Operator) =>
    row.ticker ? row.isTickerActive ? row.ticker : <span style={{ color: '#777' }}>{row.ticker}</span> : null;

  const formatAlias = (cell: string, row: Operator) => {
    if (!row) return null;
    const alias = row?.alias;
    return (
      <>
        {(alias?.length ?? 0) > 0
          ? alias?.map((a: string) => (
              <>
                {a}
                <br />
              </>
            ))
          : null}
      </>
    );
  };

  const formatStatus = (cell: boolean) => (cell == null ? 'n/a' : cell ? 'Active' : 'Inactive');

  const formatEdit = (cell: string, row: Operator) => (
    <Link title={'Edit operator'} to={paths.admin.EDIT_OPERATOR.replace(':id', row?.id?.toString() || '')}>
      Edit
    </Link>
  );

  const formatBoe = (cell: any, row: any, rowIndex: number, formatExtraData: any) =>
    formatNumber(
      convertToSelectedUnits(
        row?.[prodYearField]?.find((c: any) => c.year === formatExtraData.year && c.regionId === regionId)?.[
          formatExtraData.field
        ] || 0,
      ),
    );
  const formatBoeChange = (cell: any, row: any) => {
    return formatNumber(convertToSelectedUnits(row?.boeChange?.[0] || 0));
  };

  const { currentPage, limit, totalSize, sort } = getPagingParamsForTable(
    //  Convert payload/query (which?) to params for tablenext
    payload,
    query,
    DEFAULT_PAGE_SIZE,
    listType === 'basic' ? defaultBasicSort : DEFAULT_PROD_SORT,
  );

  const getPagingParamsForQuery = (newState: TableState) => {
    // Table params have changed; Get page, offset, and sort params from table.
    // Have to translate tablenext sort value to a query sort, possibly using a nested field.
    // See column defs below for e.g. stateSummary.producingWellCount,  periodXBoe, etc.
    const { page, sizePerPage, sortField, sortOrder } = newState;
    const finalSortOrder = isString(sortOrder) ? sortOrder : sortOrder?.order || 'desc';
    let _sort;
    if (sortField?.length) {
      const sortParts = sortField.split('.');
      const key = sortParts[0];
      if (NESTED_FIELDS.includes(key)) {
        _sort = [
          {
            [key]: {
              field: sortParts[1],
              regionId,
              year: key.startsWith('prodYear') ? sortParts[2] : undefined,
              order: finalSortOrder,
            },
          },
        ];
      } else if (CHANGE_FIELDS.includes(key)) {
        // determine query sort fields from the report (change) params
        // example:  periodXBoe => prodYearCounty.boeTotal.2019
        const sortYear = sortField === 'periodXBoe' ? changeParams.yearX : changeParams.yearY;
        const sortBoe = sortField === 'periodXBoe' ? changeParams.periodX : changeParams.periodY;
        _sort = [
          {
            [`${prodYearField}.${sortBoe}`]: {
              order: finalSortOrder,
              missing: 0,
              nested: {
                path: prodYearField,
                filter: {
                  bool: {
                    filter: [
                      { term: { [`${prodYearField}.year`]: sortYear } },
                      { term: { [`${prodYearField}.regionId`]: regionId } },
                    ],
                  },
                },
              },
            },
          },
        ];
      } else {
        _sort = [{ [sortField]: finalSortOrder }];
      }
    }
    return {
      skip: sizePerPage * (page - 1),
      limit: sizePerPage,
      sort: _sort,
    };
  };

  const onTableChange = (type: unknown, newState: any) => {
    const queryPagingParams = getPagingParamsForQuery(newState);
    dispatch(setQueryPagingAndSorting(queryIdentifier, queryPagingParams));
  };

  const getDefaultSorted = (querySort: any[]) => {
    // Translate query sort field back to a table sort field
    const firstSort = querySort?.[0];
    const sortKey = firstSort && Object.keys(firstSort)[0];
    const sortKeyParts = sortKey?.split('.');
    const sortKeyField = sortKeyParts?.[0];
    if (!sortKeyField) return undefined;

    const sortVal: any = firstSort && Object.values(firstSort)[0];
    let tableSort;

    if (NESTED_FIELDS.includes(sortKeyField)) {
      if (sortKeyField?.endsWith('Summary')) {
        tableSort = [{ dataField: `${sortKey}.${sortVal.field}`, order: sortVal.order }];
      } else if (sortKeyField.startsWith('prodYear')) {
        if (listType === 'change') {
          // Translate to either periodXBoe or periodYBoe - depending on match to change params
          const sortPeriod = sortKeyParts?.[1];
          const sortYearTerm = sortVal?.nested?.filter?.bool?.filter?.[0]?.term;
          const sortYear = sortYearTerm ? Object.values(sortYearTerm)?.[0] : null;
          const dataField =
            changeParams.periodX === sortPeriod && changeParams.yearX === sortYear ? 'periodXBoe' : 'periodYBoe';
          tableSort = [{ dataField, order: sortVal.order }];
        } else {
          tableSort = [{ dataField: `${sortKey}.${sortVal.field}.${sortVal.year}`, order: sortVal.order }];
        }
      }
    } else {
      tableSort = [{ dataField: sortKey, order: sortVal }];
    }
    return tableSort;
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: formatOperatorName,
      sort: true,
    } as any,
  ];

  if (showAlias) {
    columns.push({
      dataField: 'alias',
      text: 'Aliases',
      formatter: formatAlias,
      sort: false,
    });
  }

  columns.push(
    {
      dataField: 'ticker',
      text: 'TKR',
      formatter: formatTicker,
      sort: true,
    },
    {
      dataField: `${summaryField}.boeTotal`,
      text: `Total ${productionUnitsTitle}`,
      sort: true,
      align: 'right',
      formatter: formatNumber,
      headerStyle: { textAlign: 'right' },
      hidden: listType === 'change',
    },
  );

  for (let year = PROD_MAX_YEAR; year >= PROD_MIN_YEAR; year--) {
    columns.push({
      dataField: `${prodYearField}.boeTotal.${year}`,
      text: `${year} ${productionUnitsTitle}`,
      sort: true,
      align: 'right',
      formatter: (cell: any, row: any) => formatBoeYear(row, year),
      headerStyle: { textAlign: 'right' },
      hidden: listType === 'change',
    });
  }

  if (showDecline) {
    columns.push({
      dataField: `${prodYearField}.boeDecline2Pct.${latestFullProductionYear}`,
      text: `${latestFullProductionYear} Decline`,
      sort: true,
      align: 'right',
      formatter: (cell: any, row: any) => formatDeclineYear(row, latestFullProductionYear),
      headerStyle: { textAlign: 'right' },
      hidden: listType === 'change',
    });
  }

  columns.push(
    {
      dataField: `${summaryField}.producingWellCount`,
      text: 'Producing Wells',
      sort: true,
      align: 'right',
      formatter: formatNumber,
      headerStyle: { textAlign: 'right' },
      hidden: listType === 'change',
    },
    {
      dataField: `${summaryField}.wellCount`,
      text: 'All Wells',
      sort: true,
      align: 'right',
      formatter: formatNumber,
      headerStyle: { textAlign: 'right' },
      hidden: listType === 'change',
    },
  );

  if (listType === 'change') {
    columns.push(
      {
        dataField: 'periodXBoe',
        isDummyField: true,
        text: `${PERIOD_OPTIONS[changeParams.periodX].replace(
          changeParams.yearX !== CURRENT_YEAR ? '/YTD' : '',
          '',
        )} ${yearX} ${productionUnitsTitle}`,
        formatter: formatBoe,
        formatExtraData: { field: changeParams.periodX, year: changeParams.yearX },
        sort: true,
        align: 'right',
        headerStyle: { textAlign: 'right' },
      },
      {
        dataField: 'periodYBoe',
        isDummyField: true,
        text: `${PERIOD_OPTIONS[changeParams.periodY].replace(
          changeParams.yearY !== CURRENT_YEAR ? '/YTD' : '',
          '',
        )} ${yearY} ${productionUnitsTitle}`,
        formatter: formatBoe,
        formatExtraData: { field: changeParams.periodY, year: changeParams.yearY },
        sort: true,
        align: 'right',
        headerStyle: { textAlign: 'right' },
      },
      {
        dataField: 'boeChange',
        isDummyField: true,
        text: `Production Change ${productionUnitsTitle}`,
        // defaultYear === CURRENT_YEAR.toString()
        // ? `${defaultYear} YTD ${productionUnitsTitle}`
        // : `${defaultYear} Total ${productionUnitsTitle}`,
        formatter: formatBoeChange,
        sort: true,
        align: 'right',
        headerStyle: { textAlign: 'right' },
      },
    );
  }

  if (showStatus) {
    columns.push({
      dataField: 'isActive',
      text: 'Status',
      sort: true,
      formatter: formatStatus,
    });
  }

  if (showWellStates) {
    columns.push({
      dataField: `${summaryField}.wellStates`,
      text: 'Well States',
      sort: true,
      hidden: regionId < ROOT_REGION_ID && !showWellStates,
      formatter: formatStates,
    });
  }

  if (authUser?.canDataEntry) {
    columns.push({
      dataField: '',
      text: '',
      sort: false,
      align: 'right',
      formatter: formatEdit,
      headerStyle: { textAlign: 'right' },
    });
  }

  return (
    <>
      <DataExportButton
        dataExportStoreId={DATA_EXPORT_STORE}
        exportFunction={exportOperators}
        query={query}
        exportType={listType}
        exportParams={
          listType === 'basic'
            ? undefined
            : {
                fromYear: parseInt(yearX, 10),
                fromPeriod: periodX,
                toYear: parseInt(yearY, 10),
                toPeriod: periodY,
                regionId,
                prodYearField,
              }
        }
      />
      <ListCount storeIdentifier={storeIdentifier} label={'Operator'} />
      <Form inline>
        <FormGroup className={'mb-2 mr-4'}>
          <Input type="select" id="selectType" onChange={handleListTypeChange} value={listType}>
            <option value="basic">Basic details</option>
            <option value="change">Change in production</option>
          </Input>
        </FormGroup>
        {listType === 'change' && (
          <>
            <span className={'mb-2 mr-2'}>Compare</span>
            <FormGroup className={'mb-2 mr-2'}>
              <Input type="select" id="selectYearX" onChange={handleYearXChange} value={yearX}>
                {Array.from(Array(PROD_MAX_YEAR - PROD_MIN_YEAR + 1).keys()).map((x: number) => (
                  <option key={`yearX${x}`} value={`${PROD_MAX_YEAR - x}`} className={'py-2'}>{`${PROD_MAX_YEAR - x}`}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup className={'mb-2 mr-2'}>
              <Input type="select" id="selectPeriodX" onChange={handlePeriodXChange} value={periodX}>
                {Object.entries(PERIOD_OPTIONS).map(([key, value]) => (
                  <option key={`periodX${key}`} value={key}>
                    {value}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <span className={'mb-2 mr-2'}>to</span>
            <FormGroup className={'mb-2 mr-2'}>
              <Input type="select" id="selectYearY" onChange={handleYearYChange} value={yearY}>
                {Array.from(Array(PROD_MAX_YEAR - PROD_MIN_YEAR + 1).keys()).map((x: number) => (
                  <option key={`yearY${x}`} value={`${PROD_MAX_YEAR - x}`} className={'py-2'}>{`${PROD_MAX_YEAR - x}`}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup className={'mb-2 mr-2'}>
              <Input type="select" id="selectPeriodY" onChange={handlePeriodYChange} value={periodY}>
                {Object.entries(PERIOD_OPTIONS).map(([key, value]) => (
                  <option key={`periodY${key}`} value={key}>
                    {value}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <Button size={'sm'} className={'mb-2'} onClick={updateProdParams}>
              Go
            </Button>
          </>
        )}
      </Form>
      <TcfBootstrapTable
        allowPaging
        allowWrap
        columns={columns}
        dataSource={operators}
        defaultSorted={getDefaultSorted(sort)}
        onTableChange={onTableChange}
        page={currentPage}
        remote
        sizePerPage={limit}
        totalSize={authUser.canBrowseLongLists ? totalSize : Math.min(totalSize, 100)}
      />
    </>
  );
};

export default OperatorList;
