import React from 'react';
import { Container, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { isArray, isString, isDate } from 'lodash';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../hooks';

import { SUPPORTED_STATES_BY_CODE } from 'tcf-upstream-shared/constants';
import { Operator, SearchResults, Query, Well } from 'tcf-upstream-shared/models';

import { paths } from '../paths';

export interface TcfBootstrapTableProps {
  allowPaging?: boolean;
  allowWrap?: boolean;
  columns: any[];
  dataSource: any;
  defaultSorted?: any[];
  keyField?: string;
  onTableChange?: any;
  page?: number;
  remote?: boolean;
  sizePerPage?: number;
  totalSize: number;
  unlimited?: boolean;
  selectRowOptions?: any;
  sizePerPageList?: number[] | null;
  exportFileName?: string;
}

const MAX_SIZE = 10000;
const NOTE = `Note that paging is limited to first ${MAX_SIZE.toLocaleString()} rows based on selected sort column`;
const PROD_YEARS = 'prodYears';

export interface TableState {
  page: number;
  sizePerPage: number;
  sortField: string;
  sortOrder: any;
}

export const getPagingParamsForTable = (payload: SearchResults, query: Query, defaultPageSize: number, defaultSort: any) => {
  const skip = payload?.query?.skip || query?.skip || 0;
  const limit = payload?.query?.limit || query?.limit || defaultPageSize;
  return {
    currentPage: Math.floor(skip / limit) + 1,
    limit,
    totalSize: payload?.total || 0,
    // Comment out next line and replace with one after to prevent endless loop of changing sort order
    // which happens if we export results of a "basic" list and then change to a Change in Production list.
    // sort: payload?.query?.sort || query?.sort || defaultSort,
    sort: query?.sort || payload?.query?.sort || defaultSort,
  };
};

export const getPagingParamsForQuery = (newState: TableState) => {
  const { page, sizePerPage, sortField, sortOrder } = newState;
  const _sort = [];
  if (sortField && sortField.length > 0) {
    // Parse out if sortField is a nested field name
    const sortParts = sortField.split('.');
    const isNestedSort = sortParts[0] === PROD_YEARS;
    const key = isNestedSort ? sortParts[0] : sortField;
    _sort.push({
      [key]: isNestedSort
        ? {
            field: sortParts[1],
            year: sortParts[2],
            order: isString(sortOrder) ? sortOrder : sortOrder?.order || 'asc',
          }
        : sortOrder,
    });
  }
  return {
    skip: sizePerPage * (page - 1),
    limit: sizePerPage,
    sort: _sort,
  };
};

export const getDefaultSorted = (sort: any[]) => {
  const firstSort = sort?.length > 0 ? sort?.[0] : null;
  const sortKey = firstSort && Object.keys(firstSort)[0];
  const sortVal: any = firstSort && Object.values(firstSort)[0];
  const isNestedSort = sortKey === PROD_YEARS;
  return sortKey
    ? [
        {
          dataField: isNestedSort ? `${sortKey}.${sortVal.field}.${sortVal.year}` : sortKey,
          order: isNestedSort ? sortVal.order : sortVal,
        },
      ]
    : undefined;
};

export const formatNumber = (cell: number | string) =>
  (typeof cell !== 'undefined' &&
    cell !== null &&
    (typeof cell === 'string' ? cell : cell.toLocaleString('en-US', { maximumFractionDigits: 0 }))) ||
  '';

export const formatStates = (cell: string[]) => (cell?.length > 0 && cell.join(', ')) || '';
export const formatLocation = (cell: string, row: any) => (row.city ? `${row.city}, ${row.stateAbbrev || '??'}` : '');

// This is specifically for data sources that have a prodYear field (whether native or added)
export const formatBoeYear = (row: any, year: number) => {
  const cell = row?.prodYear;
  const y = isArray(cell) && cell.length > 0 && cell.find((c: any) => c.year === year);
  const boe = y?.boeTotal;
  return boe ? boe.toLocaleString('en-US', { maximumFractionDigits: boe > 9 ? 0 : 1 }) : 'n/a';
};

// This is specifically for data sources that have a prodYear field (whether native or added)
export const formatDeclineYear = (row: any, year: number) => {
  const cell = row?.prodYear;
  const y = isArray(cell) && cell.length > 0 && cell.find((c: any) => c.year === year);
  return y?.boeDecline2Pct == null
    ? 'n/a'
    : `${Math.max(-100, Math.min(100, y?.boeDecline2Pct * 100)).toLocaleString('en-US', { maximumFractionDigits: 0 })}%`;
};

export const formatApi = (cell: string, row: Well) =>
  cell && cell.length > 0 ? (
    <Link to={paths.VIEW_WELL.replace(':id', row.id!.toString())} title={'View well'}>
      {cell.slice(0, 2)}-{cell.slice(2, 5)}-{cell.slice(5, 10)}
    </Link>
  ) : (
    'n/a'
  );

export const formatSiteName = (cell: string, row: Well) =>
  cell && cell.length > 0 ? (
    <Link to={paths.VIEW_WELL.replace(':id', row.id!.toString())} title={'View well'}>
      {cell
        .concat(` ${row.number ?? ''}`)
        .replace('  ', ' ')
        .trim()}
    </Link>
  ) : (
    ''
  );

export const formatObjectCountyName = (cell: string, row: any) =>
  cell && cell.length > 0 ? (
    <Link to={paths.VIEW_REGION.replace(':id', row.countyApi || '')} title={'View region'}>
      {`${cell}, ${row.stateAbbrev}`}
    </Link>
  ) : (
    ''
  );

export const formatOperatorName = (cell: string, row: Operator) => (
  <Link to={paths.VIEW_OPERATOR.replace(':id', row?.id?.toString() || '')} title={'View operator'}>
    {cell}
  </Link>
);

export const formatDataType = (cell: string) => cell.concat(cell === 'Production' ? '' : 's');

export const formatStateName = (cell: string) => {
  const stateOption = SUPPORTED_STATES_BY_CODE[cell];
  return (
    <Link to={paths.VIEW_REGION.replace(':id', stateOption.regionId ?? '')} title={'View region details'}>
      {stateOption.name}
    </Link>
  );
};

export const formatMonthYear = (cell: Date | string | null) =>
  cell && (isDate(cell) || isString(cell)) ? moment.utc(cell).format('M/YYYY') : '';

const ExportButton = (props: any) => {
  const { authUser } = props;
  const handleClick = () => {
    props.onExport();
  };
  const doNothing = () => {
    return;
  };
  return (
    <Button
      size={'sm'}
      className={'float-right ml-2 mb-2'}
      title={authUser?.canExport ? 'Export list to .csv file' : 'Subscribe to export list'}
      onClick={authUser?.canExport ? handleClick : doNothing}
    >
      Export Results
    </Button>
  );
};

export const formatPct = (cell: number) => {
  if (cell == null) return '0%';
  if (cell > 9.99) return '> 999%';
  if (cell < -1) return '-100%';
  return (cell * 100).toLocaleString('en-US', { maximumFractionDigits: 0 }) + '%';
};

const TcfBootstrapTable = (props: TcfBootstrapTableProps) => {
  const {
    allowPaging,
    allowWrap,
    columns,
    dataSource,
    defaultSorted,
    keyField,
    onTableChange,
    page,
    remote,
    sizePerPage,
    totalSize,
    unlimited,
    selectRowOptions,
    sizePerPageList,
    exportFileName,
  } = props;
  const authUser = useAppSelector((state) => state.auth.authUser);

  return (
    <div style={{ marginLeft: '-15px', marginRight: '-15px' }}>
      <Container fluid={true}>
        <div>
          {dataSource && totalSize > 0 ? (
            <ToolkitProvider
              exportCSV={{ fileName: exportFileName || '' }}
              bootstrap4
              keyField={keyField || 'id'}
              data={dataSource}
              columns={columns}
            >
              {(tprops) => (
                <div>
                  {exportFileName && <ExportButton {...{ ...tprops.csvProps, authUser }} />}
                  <BootstrapTable
                    {...tprops.baseProps}
                    remote={remote || undefined}
                    wrapperClasses={'table-responsive'}
                    rowClasses={allowWrap ? undefined : 'text-nowrap'}
                    condensed
                    bootstrap4
                    striped
                    bordered={false}
                    classes={'remoteTable'}
                    selectRow={selectRowOptions}
                    pagination={
                      allowPaging
                        ? paginationFactory({
                            page,
                            sizePerPage,
                            totalSize: unlimited ? totalSize : Math.min(totalSize, MAX_SIZE),
                            sizePerPageList: sizePerPageList === undefined ? [5, 10, 25, 50, 100] : sizePerPageList,
                            hidePageListOnlyOnePage: true,
                          })
                        : undefined
                    }
                    defaultSorted={defaultSorted}
                    onTableChange={onTableChange}
                  />
                </div>
              )}
            </ToolkitProvider>
          ) : null}
          {allowPaging && !unlimited && totalSize >= MAX_SIZE && <div className={'font-italic'}>{NOTE}</div>}
        </div>
      </Container>
    </div>
  );
};

export default TcfBootstrapTable;
