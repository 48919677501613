import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import { isString } from 'lodash';
import moment from 'moment';

import { Region, OperatorRegionYear } from 'tcf-upstream-shared/models';

import { useProductionUnits, useAppDispatch, useAppSelector } from '../../../hooks';
import { setQueryPagingAndSorting } from '../../../actions/queryActions';
import { readRegions, exportRegions } from '../../../actions/regionActions';
import { paths } from '../../../paths';
import TcfBootstrapTable, {
  formatBoeYear,
  formatDeclineYear,
  formatNumber,
  TableState,
  getPagingParamsForTable,
} from '../../../components/TcfBootstrapTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import DataExportButton from '../../../components/DataExportButton';
import ListCount from '../../../components/ListCount';

const DATA_EXPORT_STORE = 'regionListDataExportStore';
const LEVEL_FILTER_FIELD = 'level';
const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_SORT = [{ boeTotal: 'desc' }];
const NESTED_FIELDS = ['prodYear'];
const PROD_MIN_YEAR = 2020;
const PROD_MAX_YEAR = 2023;

interface RegionListProps {
  storeIdentifier: string;
  queryIdentifier: string;
  latestFullProductionYear?: number;
}

export const getDefaultSorted = (sort: any[]) => {
  const firstSort = sort?.[0];
  const sortKey = firstSort && Object.keys(firstSort)[0];
  const sortVal: any = firstSort && Object.values(firstSort)[0];
  const isNestedSort = NESTED_FIELDS.includes(sortKey);
  let _sort;
  if (isNestedSort) {
    if (sortKey.startsWith('prodYear')) {
      _sort = [
        {
          dataField: `${sortKey}.${sortVal.field}.${sortVal.year}`,
          order: sortVal.order,
        },
      ];
    }
  } else {
    _sort = [
      {
        dataField: sortKey,
        order: sortVal,
      },
    ];
  }
  return _sort;
};

export const getPagingParamsForQuery = (newState: TableState) => {
  const { page, sizePerPage, sortField, sortOrder } = newState;
  const _sort = [];
  if (sortField && sortField.length > 0) {
    // Parse out if sortField is a nested field name
    const sortParts = sortField.split('.');
    const isNestedSort = NESTED_FIELDS.includes(sortParts[0]);
    if (isNestedSort) {
      const key = sortParts[0];
      _sort.push({
        [key]: {
          field: sortParts[1],
          year: key.startsWith('prodYear') ? sortParts[2] : undefined,
          order: isString(sortOrder) ? sortOrder : sortOrder?.order || 'asc',
        },
      });
    } else {
      _sort.push({
        [sortField]: sortOrder,
      });
    }
  }
  return {
    skip: sizePerPage * (page - 1),
    limit: sizePerPage,
    sort: _sort,
  };
};

const RegionList = (props: RegionListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true); // Reduce chance of stale data being used
  const { storeIdentifier, queryIdentifier, latestFullProductionYear } = props;
  const authUser = useAppSelector((state) => state.auth.authUser);
  const payload = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const error = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const query = useAppSelector((state) => state.queries[queryIdentifier]);
  const queryString = JSON.stringify(query);
  const selectedLevel = query?.filters?.[LEVEL_FILTER_FIELD];

  const { title: productionUnitsTitle, convertToSelectedUnits } = useProductionUnits();

  useEffect(() => {
    if (selectedLevel) {
      const _query = JSON.parse(queryString);
      dispatch(readRegions(storeIdentifier, _query));
    }
  }, [selectedLevel, queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender || !selectedLevel) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!payload?.total || payload.total < 1) {
    return null;
  }

  const formatName = (cell: any, row: Region) => (
    <Link to={paths.VIEW_REGION.replace(':id', row.id!.toString())} title={'View region'}>
      {cell}
    </Link>
  );

  const formatProductionDataFromTo = (cell: any, row: Region) => {
    const fromDate = row?.dataFrom ? moment.utc(row.dataFrom).format('M/YYYY') : '';
    const toDate = row?.dataTo ? moment.utc(row.dataTo).format('M/YYYY') : '';
    return fromDate || toDate ? `${fromDate}-${toDate}` : '';
  };

  const { currentPage, limit, totalSize, sort } = getPagingParamsForTable(payload, query, DEFAULT_PAGE_SIZE, DEFAULT_SORT);

  const onTableChange = (type: unknown, newState: any) => {
    const q = getPagingParamsForQuery(newState);
    dispatch(setQueryPagingAndSorting(queryIdentifier, q));
  };

  const dataSource = (payload?.results || []).map((o: any) => ({
    ...o,
    boeTotal: convertToSelectedUnits(o.boeTotal),
    prodYear: (o.prodYear || []).map((py: OperatorRegionYear) => ({ ...py, boeTotal: convertToSelectedUnits(py.boeTotal) })),
  }));

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: formatName,
      sort: true,
    },
    {
      dataField: 'stateAbbrev',
      text: 'State',
      sort: true,
      hidden: selectedLevel === 1,
    },
    {
      dataField: 'dataTo',
      text: 'Production Data',
      sort: true,
      align: 'right',
      formatter: formatProductionDataFromTo,
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'boeTotal',
      text: `Total ${productionUnitsTitle}`,
      sort: true,
      align: 'right',
      formatter: formatNumber,
      headerStyle: { textAlign: 'right' },
    },
  ];

  for (let year = PROD_MAX_YEAR; year >= PROD_MIN_YEAR; year--) {
    columns.push({
      dataField: `prodYear.boeTotal.${year}`,
      text: `${year} ${productionUnitsTitle}`,
      sort: true,
      align: 'right',
      formatter: (cell: any, row: any) => formatBoeYear(row, year),
      headerStyle: { textAlign: 'right' },
    });
  }

  columns.push({
    dataField: `prodYear.boeDecline2Pct.${latestFullProductionYear}`,
    text: `${latestFullProductionYear} Decline`,
    sort: true,
    align: 'right',
    formatter: (cell: any, row: any) => formatDeclineYear(row, latestFullProductionYear || PROD_MAX_YEAR),
    headerStyle: { textAlign: 'right' },
  } as any);

  columns.push(
    {
      dataField: 'producingWellCount',
      text: 'Producing Wells',
      sort: true,
      formatter: formatNumber,
      align: 'right',
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'wellCount',
      text: 'Wells',
      sort: true,
      formatter: formatNumber,
      align: 'right',
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'producingOperatorCount',
      text: 'Producing Operators',
      sort: true,
      formatter: formatNumber,
      align: 'right',
      headerStyle: { textAlign: 'right' },
    },
  );

  return (
    <>
      <DataExportButton dataExportStoreId={DATA_EXPORT_STORE} exportFunction={exportRegions} query={query} />
      <ListCount storeIdentifier={storeIdentifier} label={'Region'} />
      <TcfBootstrapTable
        allowPaging
        allowWrap
        columns={columns}
        dataSource={dataSource}
        defaultSorted={getDefaultSorted(sort)}
        onTableChange={onTableChange}
        page={currentPage}
        remote
        sizePerPage={limit}
        totalSize={authUser.canBrowseLongLists ? totalSize : Math.min(totalSize, 100)}
      />
    </>
  );
};

export default RegionList;
