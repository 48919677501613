import { ApiError, SearchResults } from 'tcf-upstream-shared/models';

import { Action } from '../actions/Action';
import {
  ADMIN_OBJECTS_CREATE,
  ADMIN_OBJECTS_READ,
  ADMIN_OBJECTS_UPDATE,
  ADMIN_OBJECTS_DELETE,
  ADMIN_OBJECTS_REMOVE_STORE,
  LOGOUT,
  ADMIN_LINK_EVENTS_AND_FILES,
} from '../actions/actionTypes';

export interface AdminObjectsElementState {
  payload?: SearchResults;
  isFetching: boolean;
  fetchError: string;
  isSaving: boolean;
  saveError: string;
  isDeleting: boolean;
  deleteError: string;
}

export const initialAdminObjectsElementState: AdminObjectsElementState = {
  payload: undefined,
  isFetching: false,
  fetchError: '',
  isSaving: false,
  saveError: '',
  isDeleting: false,
  deleteError: '',
};

export interface AdminObjectsState {
  [key: string]: AdminObjectsElementState;
}

const initialAdminObjectState: AdminObjectsState = {};

export const adminObjectsReducer = (state = initialAdminObjectState, action: Action): AdminObjectsState => {
  const storeIdentifier = action?.storeIdentifier;
  const currentState = (storeIdentifier && state[storeIdentifier]) || { ...initialAdminObjectsElementState };

  let newState;

  switch (action.type) {
    case ADMIN_OBJECTS_CREATE.REQUESTED:
    case ADMIN_OBJECTS_UPDATE.REQUESTED:
    case ADMIN_LINK_EVENTS_AND_FILES.REQUESTED:
      newState = {
        ...currentState,
        isSaving: true,
      };
      break;

    case ADMIN_OBJECTS_READ.REQUESTED:
      newState = {
        ...currentState,
        isFetching: true,
      };
      break;

    case ADMIN_OBJECTS_DELETE.REQUESTED:
      newState = {
        ...currentState,
        isDeleting: true,
      };
      break;

    case ADMIN_OBJECTS_CREATE.SUCCEEDED:
      newState = {
        ...currentState,
        payload: {
          ...currentState.payload,
          total: (currentState.payload?.total ?? 0) + 1,
          results: [...(currentState.payload?.results || []), action.payload],
        },
        saveError: '',
        isSaving: false,
      };
      break;

    case ADMIN_LINK_EVENTS_AND_FILES.SUCCEEDED:
      // Similar to CREATE but we will be receiving a list of responses instead of a single response.
      newState = {
        ...currentState,
        payload: {
          ...currentState.payload,
          total: (currentState.payload?.total ?? 0) + (action.payload?.total ?? 0),
          results: [...(currentState.payload?.results || []), ...(action.payload?.results || [])],
        },
        saveError: '',
        isSaving: false,
      };
      break;

    case ADMIN_OBJECTS_READ.SUCCEEDED:
      newState = {
        ...currentState,
        payload: action.payload,
        fetchError: '',
        isFetching: false,
      };
      break;

    case ADMIN_OBJECTS_UPDATE.SUCCEEDED:
      const replaceId = action.payload[action.idFieldName!];
      newState = {
        ...currentState,
        payload: {
          ...currentState.payload,
          results: (currentState.payload?.results || []).map((i) =>
            i[action.idFieldName!] === replaceId ? action.payload : i,
          ),
        },
        saveError: '',
        isSaving: false,
      };
      break;

    case ADMIN_OBJECTS_DELETE.SUCCEEDED:
      const deleteId = action.originalId;
      newState = {
        ...currentState,
        payload: {
          ...currentState.payload,
          total: (currentState.payload?.total ?? 1) - 1,
          results: (currentState.payload?.results || []).filter((i) => i[action.idFieldName!] !== deleteId),
        },
        deleteError: '',
        isDeleting: false,
      };
      break;

    case ADMIN_OBJECTS_CREATE.FAILED: {
      const error = action.payload as ApiError;
      newState = {
        ...currentState,
        saveError: error?.message || 'Create failed',
        isSaving: false,
      };
      break;
    }

    case ADMIN_LINK_EVENTS_AND_FILES.FAILED: {
      const error = action.payload as ApiError;
      newState = {
        ...currentState,
        saveError: error?.message || 'Save failed',
        isSaving: false,
      };
      break;
    }

    case ADMIN_OBJECTS_READ.FAILED: {
      const error = action.payload as ApiError;
      newState = {
        ...currentState,
        fetchError: error?.message || 'Not found',
        isFetching: false,
      };
      break;
    }

    case ADMIN_OBJECTS_UPDATE.FAILED: {
      const error = action.payload as ApiError;
      newState = {
        ...currentState,
        saveError: error?.message || 'Update failed',
        isSaving: false,
      };
      break;
    }

    case ADMIN_OBJECTS_DELETE.FAILED: {
      const error = action.payload as ApiError;
      newState = {
        ...currentState,
        deleteError: error?.message || 'Delete failed',
        isDeleting: false,
      };
      break;
    }

    case ADMIN_OBJECTS_REMOVE_STORE:
      if (storeIdentifier === undefined) return state;
      const { [storeIdentifier]: _, ...rest } = state;
      return rest;

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialAdminObjectState;

    default:
      return state;
  }

  if (!storeIdentifier) {
    throw new Error('No storeIdentifier provided to Redux action.');
  }

  return { ...(state || initialAdminObjectState), [storeIdentifier]: newState };
};
