import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Card, CardBody, CardTitle } from 'reactstrap';

import { ROOT_REGION_TITLE } from 'tcf-upstream-shared/constants';

import { Well } from 'tcf-upstream-shared/models';
import { paths } from '../../../paths';
import { formatYearRange } from '../../../utils/formatting';
import WellCodeDescription from './WellCodeDescription';
import Definition from '../../../components/Definition';

interface OwnProps {
  well: Well;
}

const WellDetails = (props: OwnProps) => {
  const { well } = props;

  const sortedOperators = well?.operators || [];
  sortedOperators.sort((a, b) => (b.toYear || 0) - (a.toYear || 0));
  const api = well?.api;
  const displayApi = api ? `${api.slice(0, 2)}-${api.slice(2, 5)}-${api.slice(5, 10)}` : 'n/a';

  const fromYear = well?.prodSummary?.fromYear;
  const toYear = well?.prodSummary?.toYear;

  return well ? (
    <Card>
      <CardBody>
        <CardTitle>About</CardTitle>
        <Table responsive striped size={'sm'}>
          <tbody>
            <tr>
              <th>
                <Definition label="API">
                  The numeric identifier assigned to a well drilled for oil and gas in {ROOT_REGION_TITLE}.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{displayApi} </td>
            </tr>
            <tr>
              <th>
                <Definition label="Operator(s)">
                  The operator(s) that operated the well and during the years listed in parentheses.
                </Definition>
              </th>
              {sortedOperators.length > 0 ? (
                <td style={{ textAlign: 'right' }}>
                  {sortedOperators.map((wo) => (
                    <div key={wo.operatorId}>
                      <Link to={paths.VIEW_OPERATOR.replace(':id', wo.operatorId!.toString())} title={'View operator'}>
                        {wo.operatorName}&nbsp;
                        {wo.fromYear
                          ? '(' +
                            wo.fromYear.toString() +
                            (wo.fromYear === wo.toYear ? '' : ' - ' + (wo.toYear?.toString() || '')) +
                            ')'
                          : ''}
                      </Link>
                    </div>
                  ))}
                </td>
              ) : (
                <td style={{ textAlign: 'right' }}>n/a</td>
              )}
            </tr>
            <tr>
              <th>State</th>
              <td style={{ textAlign: 'right' }}>{well.stateAbbrev}</td>
            </tr>
            <tr>
              <th>County</th>
              <td style={{ textAlign: 'right' }}>{well.countyName}</td>
            </tr>
            <tr>
              <th>Town</th>
              <td style={{ textAlign: 'right' }}>{well.town || 'n/a'}</td>
            </tr>
            <tr>
              <th>Type</th>
              <td style={{ textAlign: 'right' }}>
                <WellCodeDescription code={well.type} lookupType={'type'} />
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Configuration">Refers to the direction and characteristic of the wellbore.</Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                <WellCodeDescription code={well.config} lookupType={'config'} />
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td style={{ textAlign: 'right' }}>
                <WellCodeDescription code={well.status} lookupType={'status'} />
              </td>
            </tr>
            {well.status === 'PLUG' || well.plugDate ? (
              <tr>
                <th>
                  <Definition label="Date Plugged">
                    The date in which the well was reported to be plugged, if any. Note that not all states report this date.
                  </Definition>
                </th>
                <td style={{ textAlign: 'right' }}>{well.plugDate || 'n/a'}</td>
              </tr>
            ) : null}
            <tr>
              <th>
                <Definition label="Measured Depth">
                  The total length of the wellbore measured in feet along the actual well path. For certain wells in OH and NY,
                  where the underlying records reflected no measured depth, data populated from "logged depth," "drilled depth,"
                  or "driller depth" fields are mapped to the “Measured Depth” field.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {(well.measuredDepth || well.depth)?.toLocaleString('en-US', { maximumFractionDigits: 0 }) || 'n/a'}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Vertical Depth">
                  The total vertical depth measured in feet from the surface to a targeted point in the well (usually the final
                  depth) independent of the wellbore path.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {well.verticalDepth?.toLocaleString('en-US', { maximumFractionDigits: 0 }) || 'n/a'}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Spud">The date in which drilling operations commence.</Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{well.spud || 'n/a'}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Completion Date">
                  The date the well is ready for production or injection with permanent equipment installed and drilling
                  operations completed. If only completion year is provided, it will be treated as YYYY-01-01, or if only
                  month/year is provided, it will be treated as YYYY-MM-01.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{well.completionDate || 'n/a'}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Production Data">
                  Earliest and latest reported production periods for this well. The latest "to" year shown does not necessarily
                  indicate that data is complete through that year, but simply that the operator has submitted at least one
                  production record for a month in the year displayed.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>{formatYearRange(fromYear, toYear)}</td>
            </tr>
            <tr>
              <th>
                <Definition label="Total BOE">
                  Barrels of oil equivalent of natural gas, oil, and natural gas liquids produced by this well during the
                  Production Data period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {well.prodSummary?.boeTotal?.toLocaleString('en-US', { maximumFractionDigits: 0 }) || '0'}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Mcfe">
                  Thousands of cubic feet equivalent of natural gas, oil, and natural gas liquids produced by this well during
                  the Production Data period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {((well.prodSummary?.boeTotal ?? 0) * 6).toLocaleString('en-US', { maximumFractionDigits: 0 }) || '0'}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Gas (Mcf)">
                  Thousands of cubic feet of natural gas produced by this well during the Production Data period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {well.prodSummary?.gasTotal?.toLocaleString('en-US', { maximumFractionDigits: 0 }) || '0'}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total Oil (Bbl)">
                  Barrels of oil produced by this well during the Production Data period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {well.prodSummary?.oilTotal?.toLocaleString('en-US', { maximumFractionDigits: 0 }) || '0'}
              </td>
            </tr>
            <tr>
              <th>
                <Definition label="Total NGL (Bbl)">
                  Barrels of natural gas liquids produced by this well during the Production Data period.
                </Definition>
              </th>
              <td style={{ textAlign: 'right' }}>
                {well.prodSummary?.nglTotal?.toLocaleString('en-US', { maximumFractionDigits: 0 }) || '0'}
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  ) : null;
};

export default WellDetails;
