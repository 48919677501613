import { ApiError } from 'tcf-upstream-shared/models';
import {
  API_REQUEST_NOT_AUTHORIZED,
  GET_DOWNLOAD_TOKEN,
  NEW_ACCESS_TOKEN_RECEIVED,
  UPDATE_MAINTENANCE_WINDOW,
} from '../actions/actionTypes';
import { accessToken } from '../utils/Tokens';
import { asyncAction } from '../actions/asyncAction';
import { AppState } from '../reducers';
import { library } from './index';

export const newAccessTokenReceived = (token: string) => {
  accessToken.set(token);
  return { type: NEW_ACCESS_TOKEN_RECEIVED, payload: { token } };
};

export const apiRequestNotAuthorized = (apiError: ApiError, forceLogout: boolean = true, requestedFreeDoc: boolean = false) => {
  if (forceLogout) {
    accessToken.delete();
  }
  return {
    type: API_REQUEST_NOT_AUTHORIZED,
    error: true,
    payload: { error: apiError, requestedFreeDoc },
  };
};

export const updateMaintenanceWindow = (date?: Date) => {
  return { type: UPDATE_MAINTENANCE_WINDOW, payload: date };
};

export function getDownloadToken(baseUrl: string, id: string) {
  return asyncAction<AppState>({
    actionType: GET_DOWNLOAD_TOKEN,
    func: async () => (await library.adminObjects.getDownloadToken(baseUrl, id)).data,
  });
}
