import { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';

import { upsertQueryFilter } from '../actions/queryActions';
import { useAppDispatch } from './index';

const useKeywordDebouncer = (storeId: string, initialKeywords: string = '') => {
  const dispatch = useAppDispatch();

  const [localKeywords, setLocalKeywords] = useState(initialKeywords);

  const handleKeywordsChange = (newKeywords: string) => {
    dispatch(upsertQueryFilter(storeId, 'keywords', newKeywords));
  };

  const _debounceKeywords = useCallback(
    debounce((newKeywords) => handleKeywordsChange(newKeywords), 700),
    [],
  );

  const updateKeywordsImmediately = (newKeywords: string) => {
    setLocalKeywords(newKeywords);
    handleKeywordsChange(newKeywords);
  };

  const debounceKeywords = (newKeywords: string) => {
    setLocalKeywords(newKeywords);
    _debounceKeywords(newKeywords);
  };

  const debounceKeywordsFromEvent = (event: any) => {
    const newKeywords = event?.target?.value;
    if (isString(newKeywords)) debounceKeywords(newKeywords);
  };

  return { localKeywords, debounceKeywords, debounceKeywordsFromEvent, updateKeywordsImmediately };
};

export default useKeywordDebouncer;
