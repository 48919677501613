import React from 'react';
import moment from 'moment';
import { shallowEqual } from 'react-redux';

import { useAppSelector } from '../hooks';

const DEFAULT_TITLE = 'UPSTREAM ADMIN';

const createTitle = (nextMaintenance: Date) => {
  if (!nextMaintenance) return DEFAULT_TITLE;
  const _nextMaintenance = moment(nextMaintenance);
  if (_nextMaintenance.toDate() < new Date()) return `MAINTENANCE STARTED ${_nextMaintenance.fromNow()}`;
  return `MAINTENANCE BEGINS ${_nextMaintenance.fromNow()}`;
};

const HeaderAdminTitle = () => {
  const nextMaintenance = useAppSelector((state) => state?.auth?.nextMaintenance, shallowEqual);

  const [now, setNow] = React.useState(new Date());

  React.useEffect(() => {
    const timer = setTimeout(() => setNow(new Date()), 5000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [nextMaintenance, now]);

  return <>{createTitle(nextMaintenance)}</>;
};

export default HeaderAdminTitle;
