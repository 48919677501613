import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { Link } from 'react-router-dom';

import { SortFields, Transfer } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setQueryPagingAndSorting } from '../../../actions/queryActions';
import { readTransfers, exportTransfers } from '../../../actions/transferActions';
import { paths } from '../../../paths';
import TcfBootstrapTable, {
  getDefaultSorted,
  getPagingParamsForQuery,
  getPagingParamsForTable,
  formatObjectCountyName,
} from '../../../components/TcfBootstrapTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import WellCodeDescription from '../../Well/components/WellCodeDescription';
import DataExportButton from '../../../components/DataExportButton';
import ListCount from '../../../components/ListCount';

const DATA_EXPORT_STORE = 'transferListDataExportStore';
const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_SORT: SortFields = [{ transferDate: 'desc' }];
export const availableFields = [
  'config',
  'countyName',
  'fromOperatorName',
  'stateAbbrev',
  'status',
  'toOperatorName',
  'transferDate',
  'type',
  'wellApi',
];

interface TransferListProps {
  storeIdentifier: string;
  queryIdentifier: string;
  hideFields?: string[];
}

const TransferList = (props: TransferListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true);
  const { storeIdentifier, queryIdentifier } = props;
  const hideFields = props.hideFields || [];
  const authUser = useAppSelector((state) => state.auth.authUser);
  const payload = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const error = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const query = useAppSelector((state) => state.queries[queryIdentifier]);
  const queryString = JSON.stringify(query);

  useEffect(() => {
    const _query = queryString ? JSON.parse(queryString) : undefined;
    if (!_query) {
      dispatch(setQueryPagingAndSorting(queryIdentifier, { skip: 0, limit: DEFAULT_PAGE_SIZE, sort: DEFAULT_SORT }));
    }
  }, [queryString, queryIdentifier, dispatch]);

  useEffect(() => {
    const _query = queryString ? JSON.parse(queryString) : {};
    _query.sort = _query.sort || DEFAULT_SORT;
    dispatch(readTransfers(storeIdentifier, _query));
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!payload?.total || payload.total < 1) {
    return <ListCount storeIdentifier={storeIdentifier} label={'Transfer'} />;
  }

  const formatWellStatus = (cell: string) => <WellCodeDescription code={cell} lookupType={'status'} />;
  const formatWellConfig = (cell: string) => <WellCodeDescription code={cell} lookupType={'config'} />;
  const formatWellType = (cell: string) => <WellCodeDescription code={cell} lookupType={'type'} />;

  const formatApi = (cell: number, row: Transfer) =>
    cell?.toString()?.length > 0 ? (
      <Link to={paths.VIEW_WELL.replace(':id', cell.toString())} title={'View well'}>
        {cell.toString().slice(0, 2)}-{cell.toString().slice(2, 5)}-{cell.toString().slice(5, 10)}
      </Link>
    ) : (
      'n/a'
    );

  const formatFromOperatorName = (cell: string, row: Transfer) =>
    cell && cell.length > 0 ? (
      <Link to={paths.VIEW_OPERATOR.replace(':id', row?.fromOperatorId?.toString() || '')} title={'View operator'}>
        {cell}
      </Link>
    ) : (
      ''
    );

  const formatToOperatorName = (cell: string, row: Transfer) =>
    cell && cell.length > 0 ? (
      <Link to={paths.VIEW_OPERATOR.replace(':id', row?.toOperatorId?.toString() || '')} title={'View operator'}>
        {cell}
      </Link>
    ) : (
      ''
    );

  const { currentPage, limit, totalSize, sort } = getPagingParamsForTable(payload, query, DEFAULT_PAGE_SIZE, DEFAULT_SORT);

  const onTableChange = (type: unknown, newState: any) => {
    const q = getPagingParamsForQuery(newState);
    dispatch(setQueryPagingAndSorting(queryIdentifier, q));
  };

  const dataTransfer = (payload?.results || []).map((o: any) => ({
    ...o,
  }));

  const columns = [
    {
      dataField: 'transferDate',
      text: 'Transfer Date',
      sort: true,
    },
    {
      dataField: 'fromOperatorName',
      text: 'From Operator',
      formatter: formatFromOperatorName,
      sort: true,
    },
    {
      dataField: 'toOperatorName',
      text: 'To Operator',
      formatter: formatToOperatorName,
      sort: true,
    },
    {
      dataField: 'wellApi',
      text: 'Well API',
      formatter: formatApi,
      sort: true,
      hidden: hideFields.includes('wellApi'),
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      formatter: formatWellType,
      hidden: hideFields.includes('type'),
    },
    {
      dataField: 'config',
      text: 'Config',
      sort: true,
      formatter: formatWellConfig,
      hidden: hideFields.includes('config'),
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: formatWellStatus,
      hidden: hideFields.includes('status'),
    },
    {
      dataField: 'countyName',
      text: 'Region',
      formatter: formatObjectCountyName,
      sort: true,
      hidden: hideFields.includes('countyName'),
    },
  ];

  return (
    <>
      <DataExportButton dataExportStoreId={DATA_EXPORT_STORE} exportFunction={exportTransfers} query={query} />
      <ListCount storeIdentifier={storeIdentifier} label={'Transfer'} />
      <TcfBootstrapTable
        allowPaging
        allowWrap
        columns={columns}
        dataSource={dataTransfer}
        defaultSorted={getDefaultSorted(sort)}
        onTableChange={onTableChange}
        page={currentPage}
        remote
        sizePerPage={limit}
        totalSize={authUser.canBrowseLongLists ? totalSize : Math.min(totalSize, 100)}
      />
      <div className={'font-italic'}>
        <br />
        Historical transfer records are not comprehensive as many states only supply records from recent years. Current well
        ownership is extrapolated from multiple state well records which is not always reflected in transfer records.
      </div>
    </>
  );
};

export default TransferList;
