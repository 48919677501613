import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Container,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';

import { UpstreamToken } from 'tcf-upstream-shared/models';

import { paths } from '../paths';
import { StylesDictionary } from '../theme';
import MainMenu from './MainMenu';
import { BOE, MCFE, useProductionUnits } from '../hooks';

interface HeaderProps {
  authUser?: UpstreamToken;
}

const DropdownItemLink = (props: { title?: string; to: string; children?: React.ReactNode }) => {
  return <DropdownItem tag={Link} {...props} />;
};

const styles: StylesDictionary = {
  upstream: {
    fontSize: '1.4rem',
    color: 'white',
    textTransform: 'uppercase',
    marginTop: '10px',
  },
  brand: {
    height: '30px',
    width: '268px',
    marginTop: '-12px',
    marginBottom: '-10px', // media query removes this under lg.
  },
};

const Header = (props: HeaderProps) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { authUser } = props;
  const { units, setProductionUnits } = useProductionUnits();

  const setProductionUnitsMCFE = () => {
    setProductionUnits(MCFE);
  };

  const setProductionUnitsBOE = () => {
    setProductionUnits(BOE);
  };

  const getDisplayName = () => {
    const name = (authUser && authUser.email) || 'user@example.com';
    return name.substring(0, name.indexOf('@'));
  };

  return (
    <div className={'contentPageHeader'}>
      <Navbar color={'dark'} light={false} dark={true} expand={'md'}>
        <Container id={'header-container'} fluid>
          <Link to={paths.HOME} className={'navbar-brand'} style={{ zIndex: 1000 }}>
            <img style={styles.brand} src={`${process.env.PUBLIC_URL}/TCF-Masthead-white@2Xv3.png`} alt={'The Capitol Forum'} />
          </Link>
          <span style={styles.upstream}>Upstream</span>

          <NavbarToggler onClick={() => setIsOpen(!isOpen)} style={{ zIndex: 1000 }} />

          <Collapse isOpen={isOpen} navbar>
            <Nav navbar className={'ml-auto'}>
              {!authUser && pathname !== paths.LOGIN && (
                <NavItem>
                  <Link title={'Go to page to request a login email'} className={'btn btn-secondary'} to={paths.LOGIN}>
                    Login
                  </Link>
                </NavItem>
              )}
              {authUser && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    {getDisplayName()}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem disabled>{(authUser && authUser.email) || 'user@example.com'}</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem text>
                      <div onClick={setProductionUnitsMCFE} style={{ cursor: 'pointer' }}>
                        Production Units as Mcfe{units === MCFE && ' ✓'}
                      </div>
                    </DropdownItem>
                    <DropdownItem text>
                      <div onClick={setProductionUnitsBOE} style={{ cursor: 'pointer' }}>
                        Production Units as BOE{units === BOE && ' ✓'}
                      </div>
                    </DropdownItem>
                    <DropdownItem divider />
                    <a href="mailto:editorial@thecapitolforum.com" className="dropdown-item">
                      Contact Support
                    </a>
                    <DropdownItem divider />
                    <DropdownItemLink to={paths.LOGOUT}>Logout</DropdownItemLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <MainMenu authUser={authUser} />
    </div>
  );
};

export default Header;
