import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { isString } from 'lodash';

import { SortFields } from 'tcf-upstream-shared/models';

import { useProductionUnits, useAppDispatch, useAppSelector } from '../../../hooks';
import { setQuery, setQueryPagingAndSorting } from '../../../actions/queryActions';
import { exportWells, readWells } from '../../../actions/wellActions';
import { paths } from '../../../paths';
import TcfBootstrapTable, {
  formatNumber,
  formatApi,
  formatSiteName,
  formatObjectCountyName,
  getPagingParamsForTable,
  TableState,
} from '../../../components/TcfBootstrapTable';
import DataExportButton from '../../../components/DataExportButton';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import WellCodeDescription from './WellCodeDescription';

const DATA_EXPORT_STORE = 'wellListDataExportStore';
const CHANGE_FIELDS = ['periodXBoe', 'periodYBoe'];

const CURRENT_DATE = new Date();
const CURRENT_YEAR = CURRENT_DATE.getFullYear();
const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_BASIC_SORT: SortFields = [{ 'prodSummary.boeTotal': 'desc' }];
const DEFAULT_PROD_SORT: SortFields = [{ boeChange: 'desc' }];
export const availableFields = [
  'api',
  'site',
  'countyName',
  'prodSummary.boeTotal',
  'type',
  'config',
  'status',
  'spud',
  'operators',
];
const PERIOD_OPTIONS = {
  boeTotal: 'Total/YTD',
  boeH2: '2nd Half',
  boeH1: '1st Half',
  boeQ1: 'Q1',
  boeQ2: 'Q2',
  boeQ3: 'Q3',
  boeQ4: 'Q4',
  boeJan: 'Jan',
  boeFeb: 'Feb',
  boeMar: 'Mar',
  boeApr: 'Apr',
  boeMay: 'May',
  boeJun: 'Jun',
  boeJul: 'Jul',
  boeAug: 'Aug',
  boeSep: 'Sep',
  boeOct: 'Oct',
  boeNov: 'Nov',
  boeDec: 'Dec',
};

interface WellListProps {
  storeIdentifier: string;
  queryIdentifier: string;
  hideFields?: string[];
  showTotal?: boolean;
}

const WellList = (props: WellListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true);
  const [listType, setListType] = useState('basic');
  const [yearX, setYearX] = useState((CURRENT_YEAR - 1).toString());
  const [yearY, setYearY] = useState(CURRENT_YEAR.toString());
  const [periodX, setPeriodX] = useState('boeTotal');
  const [periodY, setPeriodY] = useState('boeTotal');
  const [changeParams, setProdParams] = useState({
    yearX: CURRENT_YEAR - 1,
    yearY: CURRENT_YEAR,
    periodX: 'boeTotal',
    periodY: 'boeTotal',
  });

  const { storeIdentifier, queryIdentifier, showTotal } = props;
  const authUser = useAppSelector((state) => state.auth.authUser);
  const payload = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const error = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const query = useAppSelector((state) => state.queries[queryIdentifier]);
  const hideFields = props.hideFields || [];
  const { title: productionUnitsTitle, convertToSelectedUnits } = useProductionUnits();
  const queryString = JSON.stringify(query);

  useEffect(() => {
    const _query = queryString ? JSON.parse(queryString) : undefined;
    const boeChange = _query?.dynamicFieldParams?.boeChange;
    if (_query) {
      if (listType === 'basic' && boeChange) {
        delete _query.dynamicFieldParams;
        dispatch(setQuery(queryIdentifier, { ..._query, skip: 0, limit: DEFAULT_PAGE_SIZE, sort: DEFAULT_BASIC_SORT }));
      } else if (
        listType === 'change' &&
        (boeChange?.yearX !== changeParams.yearX ||
          boeChange?.periodX !== changeParams.periodX ||
          boeChange?.yearY !== changeParams.yearY ||
          boeChange?.periodY !== changeParams.periodY)
      ) {
        const dynamicFieldParams = { boeChange: { ...changeParams } };
        dispatch(
          setQuery(queryIdentifier, {
            ..._query,
            dynamicFieldParams,
            skip: 0,
            limit: DEFAULT_PAGE_SIZE,
            sort: DEFAULT_PROD_SORT,
          }),
        );
      }
    }
  }, [changeParams, listType, queryString, queryIdentifier, dispatch]);

  useEffect(() => {
    if (queryString) {
      const _query = JSON.parse(queryString);
      // _query.sort = _query.sort || (_query?.dynamicFieldParams ? DEFAULT_PROD_SORT : DEFAULT_BASIC_SORT);
      dispatch(readWells(storeIdentifier, _query));
    }
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  const timeoutError = error?.startsWith('timeout');

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error && !timeoutError) {
    return <ErrorComponent error={error} />;
  } else if (!timeoutError && (!payload?.total || payload.total < 1)) {
    return null;
  }

  const handleListTypeChange = (event: any) => setListType(event.target.value);
  const handleYearXChange = (event: any) => setYearX(event.target.value);
  const handleYearYChange = (event: any) => setYearY(event.target.value);
  const handlePeriodXChange = (event: any) => setPeriodX(event.target.value);
  const handlePeriodYChange = (event: any) => setPeriodY(event.target.value);
  const updateProdParams = () =>
    setProdParams({
      yearX: parseInt(yearX, 10),
      yearY: parseInt(yearY, 10),
      periodX,
      periodY,
    });

  const formatOperator = (cell: any) =>
    cell && cell.length > 0
      ? cell
          .filter((c: any) => c.isCurrentOperator)
          .map((c: any) => (
            <div key={c.operatorId}>
              <Link to={paths.VIEW_OPERATOR.replace(':id', c.operatorId!.toString())} title={'View operator'}>
                {c.operatorName}
              </Link>
            </div>
          ))
      : '';
  const formatWellStatus = (cell: string) => <WellCodeDescription code={cell} lookupType={'status'} />;
  const formatWellConfig = (cell: string) => <WellCodeDescription code={cell} lookupType={'config'} />;
  const formatWellType = (cell: string) => <WellCodeDescription code={cell} lookupType={'type'} />;
  const formatBoe = (cell: any, row: any, rowIndex: number, formatExtraData: any) =>
    formatNumber(
      convertToSelectedUnits(row?.prodYear?.find((c: any) => c.year === formatExtraData.year)?.[formatExtraData.field] || 0),
    );
  const formatBoeChange = (cell: any, row: any) => {
    return formatNumber(convertToSelectedUnits(row?.boeChange?.[0] || 0));
  };

  const { currentPage, limit, totalSize, sort } = getPagingParamsForTable(
    payload,
    query,
    DEFAULT_PAGE_SIZE,
    listType === 'basic' ? DEFAULT_BASIC_SORT : DEFAULT_PROD_SORT,
  );

  const getPagingParamsForQuery = (newState: TableState) => {
    // Get page, offset, and sort params from table and convert to query params, possibly using a nested field.
    const { page, sizePerPage, sortField, sortOrder } = newState;
    const finalSortOrder = isString(sortOrder) ? sortOrder : sortOrder?.order || 'desc';
    let querySort;
    if (sortField?.length) {
      // Parse out if sortField is a nested field name
      if (CHANGE_FIELDS.includes(sortField)) {
        const sortYear = sortField === 'periodXBoe' ? changeParams.yearX : changeParams.yearY;
        const sortBoe = sortField === 'periodXBoe' ? changeParams.periodX : changeParams.periodY;
        querySort = [
          {
            [`prodYear.${sortBoe}`]: {
              order: finalSortOrder,
              missing: 0,
              nested: {
                path: 'prodYear',
                filter: {
                  term: { 'prodYear.year': sortYear },
                },
              },
            },
          },
        ];
      } else {
        querySort = [{ [sortField]: finalSortOrder }];
      }
    }
    return {
      skip: sizePerPage * (page - 1),
      limit: sizePerPage,
      sort: querySort,
    };
  };

  const onTableChange = (type: unknown, newState: any) => {
    // Update query based on change to page number or sorting column or sort order.
    const queryPagingParams = getPagingParamsForQuery(newState);
    dispatch(setQueryPagingAndSorting(queryIdentifier, queryPagingParams));
  };

  const getDefaultSorted = (querySort: any[]) => {
    // Translate query sort field back to a table sort field
    const firstSort = querySort?.[0];
    const sortKey = firstSort && Object.keys(firstSort)[0];
    const sortKeyParts = sortKey?.split('.');
    const sortKeyField = sortKeyParts?.[0];
    if (!sortKeyField) return undefined;

    const sortVal: any = firstSort && Object.values(firstSort)[0];
    let tableSort;

    if (sortKeyField.startsWith('prodYear') && listType === 'change') {
      // Translate to either periodXBoe or periodYBoe - depending on match to change params
      const sortPeriod = sortKeyParts?.[1];
      const sortYearTerm = sortVal?.nested?.filter?.term;
      const sortYear = sortYearTerm ? Object.values(sortYearTerm)?.[0] : null;
      const dataField = changeParams.periodX === sortPeriod && changeParams.yearX === sortYear ? 'periodXBoe' : 'periodYBoe';
      tableSort = [{ dataField, order: sortVal.order }];
    } else {
      tableSort = [{ dataField: sortKey, order: sortVal }];
    }
    return tableSort;
  };

  const dataSource = (payload?.results || []).map((o: any) => ({
    ...o,
    prodSummary: o.prodSummary ? { ...o.prodSummary, boeTotal: convertToSelectedUnits(o.prodSummary.boeTotal) } : o.prodSummary,
  }));

  const columns = [
    {
      dataField: 'api',
      text: 'API',
      formatter: formatApi,
      sort: true,
      hidden: hideFields.includes('api'),
    },
    {
      dataField: 'site',
      text: 'Site Name',
      formatter: formatSiteName,
      sort: true,
      hidden: hideFields.includes('site'),
    },
    {
      dataField: 'countyName',
      text: 'Region',
      sort: false,
      formatter: formatObjectCountyName,
      hidden: hideFields.includes('countyName'),
    },
    {
      dataField: 'prodSummary.boeTotal',
      text: `Total ${productionUnitsTitle}`,
      formatter: formatNumber,
      sort: true,
      align: 'right',
      headerStyle: { textAlign: 'right' },
      hidden: hideFields.includes('prodSummary.boeTotal') || listType === 'change',
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      formatter: formatWellType,
      hidden: hideFields.includes('type') || listType === 'change',
    },
    {
      dataField: 'config',
      text: 'Config',
      sort: true,
      formatter: formatWellConfig,
      hidden: hideFields.includes('config') || listType === 'change',
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: formatWellStatus,
      hidden: hideFields.includes('status') || listType === 'change',
    },
    {
      dataField: 'operators',
      text: 'Operator(s)',
      sort: false,
      formatter: formatOperator,
      hidden: hideFields.includes('operators'),
    },
    {
      dataField: 'spud',
      text: 'Spud',
      sort: true,
      hidden: hideFields.includes('spud'),
    },
    {
      dataField: 'periodXBoe',
      isDummyField: true,
      text: `${PERIOD_OPTIONS[changeParams.periodX].replace(
        changeParams.yearX !== CURRENT_YEAR ? '/YTD' : '',
        '',
      )} ${yearX} ${productionUnitsTitle}`,
      formatter: formatBoe,
      formatExtraData: { field: changeParams.periodX, year: changeParams.yearX },
      sort: true,
      align: 'right',
      headerStyle: { textAlign: 'right' },
      hidden: listType === 'basic',
    },
    {
      dataField: 'periodYBoe',
      isDummyField: true,
      text: `${PERIOD_OPTIONS[changeParams.periodY].replace(
        changeParams.yearY !== CURRENT_YEAR ? '/YTD' : '',
        '',
      )} ${yearY} ${productionUnitsTitle}`,
      formatter: formatBoe,
      formatExtraData: { field: changeParams.periodY, year: changeParams.yearY },
      sort: true,
      align: 'right',
      headerStyle: { textAlign: 'right' },
      hidden: listType === 'basic',
    },
    {
      dataField: 'boeChange',
      isDummyField: true,
      text: `Production Change ${productionUnitsTitle}`,
      // defaultYear === CURRENT_YEAR.toString()
      // ? `${defaultYear} YTD ${productionUnitsTitle}`
      // : `${defaultYear} Total ${productionUnitsTitle}`,
      formatter: formatBoeChange,
      sort: true,
      align: 'right',
      headerStyle: { textAlign: 'right' },
      hidden: listType === 'basic',
    },
  ];

  return (
    <>
      {showTotal && <div>{totalSize?.toLocaleString() || 0} Wells</div>}
      <div>
        <DataExportButton
          dataExportStoreId={DATA_EXPORT_STORE}
          exportFunction={exportWells}
          query={query}
          exportType={listType}
          exportParams={
            listType === 'basic'
              ? undefined
              : {
                  fromYear: parseInt(yearX, 10),
                  fromPeriod: periodX,
                  toYear: parseInt(yearY, 10),
                  toPeriod: periodY,
                }
          }
        />
        <Form inline>
          <FormGroup className={'mb-2 mr-4'}>
            <Input type="select" id="selectType" onChange={handleListTypeChange} value={listType}>
              <option value="basic">Basic details</option>
              <option value="change">Change in production</option>
            </Input>
          </FormGroup>
          {listType === 'change' && (
            <>
              <span className={'mb-2 mr-2'}>Compare</span>
              <FormGroup className={'mb-2 mr-2'}>
                <Input type="select" id="selectYearX" onChange={handleYearXChange} value={yearX}>
                  {Array.from(Array(5).keys()).map((x: number) => (
                    <option key={`yearX${x}`} value={`${CURRENT_YEAR - x}`} className={'py-2'}>{`${CURRENT_YEAR - x}`}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className={'mb-2 mr-2'}>
                <Input type="select" id="selectPeriodX" onChange={handlePeriodXChange} value={periodX}>
                  {Object.entries(PERIOD_OPTIONS).map(([key, value]) => (
                    <option key={`periodX${key}`} value={key}>
                      {value}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <span className={'mb-2 mr-2'}>to</span>
              <FormGroup className={'mb-2 mr-2'}>
                <Input type="select" id="selectYearY" onChange={handleYearYChange} value={yearY}>
                  {Array.from(Array(5).keys()).map((x: number) => (
                    <option key={`yearY${x}`} value={`${CURRENT_YEAR - x}`} className={'py-2'}>{`${CURRENT_YEAR - x}`}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className={'mb-2 mr-2'}>
                <Input type="select" id="selectPeriodY" onChange={handlePeriodYChange} value={periodY}>
                  {Object.entries(PERIOD_OPTIONS).map(([key, value]) => (
                    <option key={`periodY${key}`} value={key}>
                      {value}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <Button size={'sm'} className={'mb-2'} onClick={updateProdParams}>
                Go
              </Button>
            </>
          )}
        </Form>
      </div>
      {isFetching || delayRender ? (
        <Loader loaded={false} />
      ) : timeoutError ? (
        <div>
          <h3>Request timed out.</h3>
          <p>Try selecting filters to reduce the number of wells or choose alternate list from dropdown.</p>
        </div>
      ) : (
        <TcfBootstrapTable
          allowPaging
          allowWrap
          columns={columns}
          dataSource={dataSource}
          defaultSorted={getDefaultSorted(sort)}
          onTableChange={onTableChange}
          page={currentPage}
          remote
          sizePerPage={limit}
          totalSize={authUser.canBrowseLongLists ? totalSize : Math.min(totalSize, 100)}
        />
      )}
    </>
  );
};

export default WellList;
