import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';

import { Query, SearchResults, SortFields } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setQueryPagingAndSorting } from '../../../actions/queryActions';
import { readSources } from '../../../actions/sourceActions';
import TcfBootstrapTable, {
  getDefaultSorted,
  getPagingParamsForQuery,
  getPagingParamsForTable,
  formatStateName,
  formatDataType,
} from '../../../components/TcfBootstrapTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_SORT: SortFields = [{ processedOn: 'desc' }];
export const availableFields = ['stateAbbrev', 'dataType', 'processedOn'];

interface SourceListProps {
  storeIdentifier: string;
  queryIdentifier: string;
}

const SourceList = (props: SourceListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true);
  const { storeIdentifier, queryIdentifier } = props;
  const payload: SearchResults = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const query: Query = useAppSelector((state) => state.queries[queryIdentifier]);
  const queryString = JSON.stringify(query);

  useEffect(() => {
    const _query = queryString ? JSON.parse(queryString) : undefined;
    if (!_query) {
      dispatch(setQueryPagingAndSorting(queryIdentifier, { skip: 0, limit: DEFAULT_PAGE_SIZE, sort: DEFAULT_SORT }));
    }
  }, [queryString, queryIdentifier, dispatch]);

  useEffect(() => {
    const _query = queryString ? JSON.parse(queryString) : {};
    _query.sort = _query.sort || DEFAULT_SORT;
    dispatch(readSources(storeIdentifier, _query));
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!payload?.total || payload.total < 1) {
    return null;
  }

  const { currentPage, limit, totalSize, sort } = getPagingParamsForTable(payload, query, DEFAULT_PAGE_SIZE, DEFAULT_SORT);

  const onTableChange = (type: unknown, newState: any) => {
    const q = getPagingParamsForQuery(newState);
    dispatch(setQueryPagingAndSorting(queryIdentifier, q));
  };

  const dataSource = (payload?.results || []).map((o) => ({
    ...o,
  }));

  const columns = [
    {
      dataField: 'processedOn',
      text: 'Updated On',
      sort: true,
    },
    {
      dataField: 'stateAbbrev',
      text: 'State',
      formatter: formatStateName,
      sort: true,
    },
    {
      dataField: 'dataType',
      text: 'Data Type',
      formatter: formatDataType,
      sort: true,
    },
  ];

  return (
    <TcfBootstrapTable
      allowPaging
      allowWrap
      columns={columns}
      dataSource={dataSource}
      defaultSorted={getDefaultSorted(sort)}
      onTableChange={onTableChange}
      page={currentPage}
      remote
      sizePerPage={limit}
      totalSize={totalSize}
    />
  );
};

export default SourceList;
