import React, { useEffect, useState } from 'react';

import { Query, QueryFilters, Region } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setQuery } from '../../../actions/queryActions';
import RegionList from './RegionList';

const LEVEL_FILTER_FIELD = 'level';
const STATE_FILTER_FIELD = 'stateAbbrev';
const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_SORT = [{ boeTotal: 'desc' }];

interface SubRegionListProps {
  regionStoreIdentifier: string;
  subRegionLevel: number;
  subRegionsStoreIdentifier: string;
  subRegionsQueryIdentifier: string;
}

const SubRegionList = (props: SubRegionListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true); // Reduce chance of stale data being used
  const { regionStoreIdentifier, subRegionsStoreIdentifier, subRegionsQueryIdentifier, subRegionLevel } = props;
  const region: Region = useAppSelector((state) => state.serverStores?.[regionStoreIdentifier]?.payload);
  const regionLevel = region?.level;
  const stateAbbrev = region?.stateAbbrev;

  useEffect(() => {
    if (regionLevel) {
      const filters: QueryFilters = { [LEVEL_FILTER_FIELD]: subRegionLevel };
      if (regionLevel === 2) {
        filters[STATE_FILTER_FIELD] = stateAbbrev;
      }
      const _query: Query = {
        skip: 0,
        limit: DEFAULT_PAGE_SIZE,
        sort: DEFAULT_SORT,
        filters,
      };
      dispatch(setQuery(subRegionsQueryIdentifier, _query));
      setDelayRender(false);
    }
  }, [regionLevel, stateAbbrev, subRegionLevel, subRegionsQueryIdentifier, dispatch]);

  return delayRender ? null : (
    <RegionList
      storeIdentifier={subRegionsStoreIdentifier}
      queryIdentifier={subRegionsQueryIdentifier}
      latestFullProductionYear={region?.latestFullProductionYear}
    />
  );
};

export default SubRegionList;
