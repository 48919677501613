import React from 'react';
import { Button } from 'reactstrap';

import { SUPPORTED_STATES } from 'tcf-upstream-shared/constants';

import { useAppSelector } from '../../../hooks';
import Accordion from '../../../components/Accordion';
import StatesFilter from '../../../components/StatesFilter';
import KeywordFilter from '../../../components/KeywordFilter';
import DateRangeFilter from '../../../components/DateRangeFilter';
import WellsFilterLookup from '../../Well/components/WellsFilterLookup';
import FromToOperatorIdFilter from './FromToOperatorIdFilter';
import FromToOperatorNameFilter from './FromToOperatorNameFilter';

const operatorSearchFields = {
  byName: {
    fromField: 'fromOperatorName.text',
    toField: 'toOperatorName.text',
  },
  byId: {
    fromField: 'fromOperatorId',
    toField: 'toOperatorId',
  },
};

export interface VisibleFilters {
  location: boolean;
  config: boolean;
  wellType: boolean;
  status: boolean;
  producing: boolean;
}

export interface TransfersFilterFormProps {
  storeIdentifier: string;
  queryIdentifier: string;
  visibleFilters: VisibleFilters;
  onVisibleFilterToggle: any;
  resetQueryFilter: any;
  showLocationFilter?: boolean;
  operatorFilterType: 'byName' | 'byId';
}

type Aggs = { states: any; configs: any; types: any; statuses: any; producing: any };

const TransfersFilterForm = (props: TransfersFilterFormProps) => {
  const {
    storeIdentifier,
    queryIdentifier,
    visibleFilters,
    onVisibleFilterToggle,
    resetQueryFilter,
    showLocationFilter,
    operatorFilterType,
  } = props;
  const defaultOperatorFilterFields = [
    operatorSearchFields[operatorFilterType].fromField,
    operatorSearchFields[operatorFilterType].toField,
  ];
  const operatorNameFields =
    useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.prefixKeywords?.fields) || defaultOperatorFilterFields;
  const aggregations: Aggs | undefined = useAppSelector(
    (state) => state.serverStores?.[storeIdentifier]?.payload?.aggregations,
  );
  const statesHelpText = SUPPORTED_STATES.reduce((a, c) => {
    if (c.transfersDisclaimer) a[c.code] = c.transfersDisclaimer;
    return a;
  }, {});

  const onClearFiltersClick = () => {
    resetQueryFilter();
  };

  return (
    <div>
      {operatorFilterType === 'byName' ? (
        <>
          <KeywordFilter queryIdentifier={queryIdentifier} title={'Operator name'} searchFields={operatorNameFields} />
          <FromToOperatorNameFilter queryIdentifier={queryIdentifier} />
        </>
      ) : (
        <FromToOperatorIdFilter queryIdentifier={queryIdentifier} />
      )}
      <hr />
      <DateRangeFilter queryIdentifier={queryIdentifier} dateField={'transferDate'} />
      <hr />
      {showLocationFilter && (
        <>
          <Accordion title={'State:'} id={'location'} isOpen={visibleFilters.location} handleToggle={onVisibleFilterToggle}>
            <StatesFilter
              queryIdentifier={queryIdentifier}
              filterField={'stateAbbrev'}
              values={aggregations?.states?.buckets}
              statesHelpText={statesHelpText}
            />
          </Accordion>
          <hr />
        </>
      )}
      <Accordion title={'Type:'} id={'wellType'} isOpen={visibleFilters.wellType} handleToggle={onVisibleFilterToggle}>
        <WellsFilterLookup queryIdentifier={queryIdentifier} filterField={'type'} values={aggregations?.types?.buckets} />
      </Accordion>
      <hr />
      <Accordion title={'Configuration:'} id={'config'} isOpen={visibleFilters.config} handleToggle={onVisibleFilterToggle}>
        <WellsFilterLookup queryIdentifier={queryIdentifier} filterField={'config'} values={aggregations?.configs?.buckets} />
      </Accordion>
      <hr />
      <Accordion
        title={'Operational Status:'}
        id={'status'}
        isOpen={visibleFilters.status}
        handleToggle={onVisibleFilterToggle}
      >
        <WellsFilterLookup queryIdentifier={queryIdentifier} filterField={'status'} values={aggregations?.statuses?.buckets} />
      </Accordion>
      <hr />
      <Accordion
        title={'Production Status:'}
        id={'producing'}
        isOpen={visibleFilters.producing}
        handleToggle={onVisibleFilterToggle}
      >
        <WellsFilterLookup
          queryIdentifier={queryIdentifier}
          filterField={'producing'}
          values={aggregations?.producing?.buckets}
        />
      </Accordion>
      <hr />
      <Button size={'sm'} className={'mt-3 btn-block'} onClick={onClearFiltersClick}>
        Clear all filters
      </Button>
    </div>
  );
};

export default TransfersFilterForm;
