import React, { useEffect, useState } from 'react';
import { Button, Label, FormGroup, Input } from 'reactstrap';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setQueryFilter } from '../../../actions/queryActions';
import Accordion from '../../../components/Accordion';
import KeywordFilter from '../../../components/KeywordFilter';
import OperatorStatesFilter from './OperatorStatesFilter';
import OperatorWellCountsFilter from './OperatorWellCountsFilter';
import ProducingFilter from './ProducingFilter';
import SubsidiaryFilter from './SubsidiaryFilter';
import Loader from 'react-loader';

const DEFAULT_FILTERS = { isProducing: true, parentId: null };
const SEARCH_NAME_FIELD = ['name.text'];
const SEARCH_ALIAS_FIELD = ['alias.text'];

export interface VisibleFilters {
  location: boolean;
  wellCount: boolean;
  producingWellCount: boolean;
}

export interface FilterOperatorsFormProps {
  storeIdentifier: string;
  queryIdentifier: string;
  visibleFilters: VisibleFilters;
  onVisibleFilterToggle: any;
  showLocationFilter?: boolean;
}

const FilterOperatorsForm = (props: FilterOperatorsFormProps) => {
  const dispatch = useAppDispatch();
  const { storeIdentifier, queryIdentifier, visibleFilters, onVisibleFilterToggle, showLocationFilter } = props;
  const aggregations: { regionSummary: any } | undefined = useAppSelector(
    (state) => state.serverStores?.[storeIdentifier]?.payload?.aggregations,
  );
  const [nameSearchFields, setNameSearchFields] = useState(SEARCH_NAME_FIELD);
  const [delayRender, setDelayRender] = useState(true);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  const onClearFiltersClick = () => {
    dispatch(setQueryFilter(queryIdentifier, DEFAULT_FILTERS));
  };

  const onSearchAliases = (event: any) => {
    const newNameSearchFields = event.target.checked ? SEARCH_ALIAS_FIELD : SEARCH_NAME_FIELD;
    setNameSearchFields(newNameSearchFields);
  };

  if (delayRender) return <Loader loaded={false} />;

  return (
    <div>
      <KeywordFilter queryIdentifier={queryIdentifier} title={'Name search'} searchFields={nameSearchFields} />
      <Label>Include in search:</Label>
      <FormGroup check>
        <Input
          type="checkbox"
          onChange={onSearchAliases}
          checked={JSON.stringify(nameSearchFields) === JSON.stringify(SEARCH_ALIAS_FIELD)}
        />
        &nbsp;Aliases
      </FormGroup>
      <ProducingFilter queryIdentifier={queryIdentifier} />
      <SubsidiaryFilter queryIdentifier={queryIdentifier} />
      <hr />
      {showLocationFilter && (
        <>
          <Accordion
            title={'With wells in:'}
            id={'location'}
            isOpen={visibleFilters.location}
            handleToggle={onVisibleFilterToggle}
          >
            <OperatorStatesFilter queryIdentifier={queryIdentifier} values={aggregations?.regionSummary?.wellStates?.buckets} />
          </Accordion>
          <hr />
        </>
      )}
      <Accordion title={'Well counts:'} id={'wellCount'} isOpen={visibleFilters.wellCount} handleToggle={onVisibleFilterToggle}>
        <OperatorWellCountsFilter
          queryIdentifier={queryIdentifier}
          values={aggregations?.regionSummary?.wellCountRanges?.buckets}
          filterField={'wellCount'}
        />
      </Accordion>
      <hr />
      <Accordion
        title={'Producing wells:'}
        id={'producingWellCount'}
        isOpen={visibleFilters.producingWellCount}
        handleToggle={onVisibleFilterToggle}
      >
        <OperatorWellCountsFilter
          queryIdentifier={queryIdentifier}
          values={aggregations?.regionSummary?.producingWellCountRanges?.buckets}
          filterField={'producingWellCount'}
        />
      </Accordion>
      <hr />
      <Button size={'sm'} style={{ width: '100%' }} onClick={onClearFiltersClick}>
        Clear all filters
      </Button>
    </div>
  );
};

export default FilterOperatorsForm;
