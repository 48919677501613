// I have opted to keep all the store ids in their own file to cut down on prop drilling and prevent circular
// imports.  Plus it's just generally easier to maintain them if they're all in one place.
export const ADMIN_OPERATOR_EVENT_TYPE_STORE_ID = 'ADMIN_OPERATOR_EVENT_TYPE';
export const COMPARE_ETL_METRICS_STORE_ID = 'COMPARE_ETL_METRICS';
export const CREATE_OPERATOR_STORE_ID = 'CREATE_OPERATOR';
export const EDIT_EVENT_CANDIDATE_FILE_LIST_STORE_ID = 'EDIT_EVENT_CANDIDATE_FILE_LIST';
export const EDIT_FILE_CANDIDATE_EVENT_LIST_STORE_ID = 'EDIT_FILE_CANDIDATE_EVENT_LIST';
export const EDIT_FILE_EVENT_LIST_STORE_ID = 'EDIT_FILE_EVENT_LIST';
export const EDIT_FILE_STORE_ID = 'EDIT_FILE';
export const EDIT_OPERATOR_ADDRESS_LIST_STORE_ID = 'EDIT_OPERATOR_ADDRESS_LIST';
export const EDIT_OPERATOR_ALIAS_LIST_STORE_ID = 'EDIT_OPERATOR_ALIAS_LIST';
export const EDIT_OPERATOR_CHILD_LIST_STORE_ID = 'EDIT_OPERATOR_CHILD_LIST';
export const EDIT_OPERATOR_CONTACT_LIST_STORE_ID = 'EDIT_OPERATOR_CONTACT_LIST';
export const EDIT_OPERATOR_EVENT_FILE_LIST_STORE_ID = 'EDIT_OPERATOR_EVENT_FILE_LIST';
export const EDIT_OPERATOR_EVENT_LIST_STORE_ID = 'EDIT_OPERATOR_EVENT_LIST';
export const EDIT_OPERATOR_EVENT_STORE_ID = 'EDIT_OPERATOR_EVENT';
export const EDIT_OPERATOR_STORE_ID = 'EDIT_OPERATOR';
export const LIST_ETL_METRICS_STORE_ID = 'LIST_ETL_METRICS';
export const LIST_ETL_RUNS_STORE_ID = 'LIST_ETL_RUNS';
export const LIST_EVENTS_STORE_ID = 'LIST_EVENTS';
export const LIST_FILES_STORE_ID = 'LIST_FILES';
export const LIST_REPORT_FILES_STORE_ID = 'LIST_REPORT_FILES';
export const LIST_OPERATORS_STORE_ID = 'LIST_OPERATORS';
export const MERGE_OPERATOR1_ADDRESSES_STORE_ID = 'MERGE_OPERATOR1_ADDRESSES';
export const MERGE_OPERATOR1_CONTACTS_STORE_ID = 'MERGE_OPERATOR1_CONTACTS';
export const MERGE_OPERATOR1_STORE_ID = 'MERGE_OPERATOR1';
export const MERGE_OPERATOR2_ADDRESSES_STORE_ID = 'MERGE_OPERATOR2_ADDRESSES';
export const MERGE_OPERATOR2_CONTACTS_STORE_ID = 'MERGE_OPERATOR2_CONTACTS';
export const MERGE_OPERATOR2_STORE_ID = 'MERGE_OPERATOR2';
