import { ApiError, SearchResult } from 'tcf-upstream-shared/models';

import { Action } from '../actions/Action';
import {
  ADMIN_OBJECT_CREATE,
  ADMIN_OBJECT_READ,
  ADMIN_OBJECT_UPDATE,
  ADMIN_OBJECT_DELETE,
  ADMIN_OBJECT_REMOVE_STORE,
  LOGOUT,
  ADMIN_OBJECT_UPLOAD,
} from '../actions/actionTypes';

interface AdminObjectElementState {
  payload?: SearchResult;
  isFetching: boolean;
  fetchError: string;
  isSaving: boolean;
  saveError: string;
  isDeleting: boolean;
  deleteError: string;
}

export const initialAdminObjectElementState: AdminObjectElementState = {
  payload: undefined,
  isFetching: false,
  fetchError: '',
  isSaving: false,
  saveError: '',
  isDeleting: false,
  deleteError: '',
};

export interface AdminObjectState {
  [key: string]: AdminObjectElementState;
}

export const initialAdminObjectState: AdminObjectState = {};

export const adminObjectReducer = (state = initialAdminObjectState, action: Action): AdminObjectState => {
  const storeIdentifier = action?.storeIdentifier;
  const currentState = (storeIdentifier && state[storeIdentifier]) || initialAdminObjectElementState;

  let newState;

  switch (action.type) {
    case ADMIN_OBJECT_CREATE.REQUESTED:
    case ADMIN_OBJECT_UPDATE.REQUESTED:
    case ADMIN_OBJECT_UPLOAD.REQUESTED:
      newState = {
        ...currentState,
        isSaving: true,
      };
      break;

    case ADMIN_OBJECT_READ.REQUESTED:
      newState = {
        ...currentState,
        isFetching: true,
      };
      break;

    case ADMIN_OBJECT_DELETE.REQUESTED:
      newState = {
        ...currentState,
        isDeleting: true,
      };
      break;

    case ADMIN_OBJECT_CREATE.SUCCEEDED:
    case ADMIN_OBJECT_UPDATE.SUCCEEDED:
    case ADMIN_OBJECT_UPLOAD.SUCCEEDED:
      newState = {
        ...currentState,
        payload: action.payload,
        saveError: '',
        isSaving: false,
      };
      break;

    case ADMIN_OBJECT_READ.SUCCEEDED:
      newState = {
        ...currentState,
        payload: action.payload,
        fetchError: '',
        isFetching: false,
      };
      break;

    case ADMIN_OBJECT_DELETE.SUCCEEDED:
      newState = {
        ...currentState,
        payload: undefined,
        deleteError: '',
        isDeleting: false,
      };
      break;

    case ADMIN_OBJECT_CREATE.FAILED: {
      const error = action.payload as ApiError;
      newState = {
        ...currentState,
        saveError: error?.message || 'Create failed',
        isSaving: false,
      };
      break;
    }

    case ADMIN_OBJECT_READ.FAILED: {
      const error = action.payload as ApiError;
      newState = {
        ...currentState,
        fetchError: error?.message || 'Not found',
        isFetching: false,
      };
      break;
    }

    case ADMIN_OBJECT_UPDATE.FAILED: {
      const error = action.payload as ApiError;
      newState = {
        ...currentState,
        saveError: error?.message || 'Update failed',
        isSaving: false,
      };
      break;
    }

    case ADMIN_OBJECT_UPLOAD.FAILED: {
      const error = action.payload as ApiError;
      newState = {
        ...currentState,
        saveError: error?.message || 'Upload failed',
        isSaving: false,
      };
      break;
    }

    case ADMIN_OBJECT_DELETE.FAILED: {
      const error = action.payload as ApiError;
      newState = {
        ...currentState,
        deleteError: error?.message || 'Delete failed',
        isDeleting: false,
      };
      break;
    }

    case ADMIN_OBJECT_REMOVE_STORE:
      if (storeIdentifier === undefined) return state;
      const { [storeIdentifier]: _, ...rest } = state;
      return rest;

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialAdminObjectState;

    default:
      return state;
  }

  if (!storeIdentifier) {
    throw new Error('No storeIdentifier provided to Redux action.');
  }

  return { ...(state || initialAdminObjectState), [storeIdentifier]: newState };
};
