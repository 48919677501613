import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import { useAppDispatch, useAppSelector } from '../hooks';
import { upsertQueryFilter, removeQueryFilter } from '../actions/queryActions';

const dataTypeOptions = {
  Application: 'Applications',
  Location: 'Locations',
  Operator: 'Operators',
  Orphan: 'Orphans',
  Permit: 'Permits',
  'Permit Issuance': 'Permit Issuances',
  'Permit Listing': 'Permit Listings',
  Plug: 'Plugs',
  Production: 'Production',
  Shapefile: 'Shapefiles',
  Transfer: 'Transfers',
  Well: 'Wells',
};

export interface DataTypesFilterProps {
  queryIdentifier: string;
  filterField: string; // dataType
  values?: { key: string; key_as_string: string; doc_count: number }[];
}

const DataTypesFilter = (props: DataTypesFilterProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier, filterField, values } = props;
  const selectedDataTypes = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.[filterField]);

  const onDataTypesChanged = (event: any) => {
    const { value: dataType, checked } = event.target;
    let nextSelectedDataTypes;
    if (checked) {
      nextSelectedDataTypes = [...(selectedDataTypes || [])];
      nextSelectedDataTypes.push(dataType);
    } else {
      nextSelectedDataTypes = (selectedDataTypes || []).filter((w: any) => w !== dataType);
    }
    if (nextSelectedDataTypes && nextSelectedDataTypes.length > 0) {
      dispatch(upsertQueryFilter(queryIdentifier, filterField, nextSelectedDataTypes));
    } else {
      dispatch(removeQueryFilter(queryIdentifier, filterField));
    }
  };

  return (
    <>
      {Object.keys(dataTypeOptions).map((k) => {
        const doc_count = values?.find((v) => v.key === k)?.doc_count;
        return (
          <FormGroup check key={k}>
            <Label check>
              <Input type="checkbox" value={k} onChange={onDataTypesChanged} checked={(selectedDataTypes || []).includes(k)} />
              &nbsp;{dataTypeOptions[k]}
              <span className={'text-muted'}> ({doc_count ? doc_count.toLocaleString() : 0})</span>
            </Label>
          </FormGroup>
        );
      })}
    </>
  );
};

export default DataTypesFilter;
