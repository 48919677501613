import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import {
  DataExport,
  DATA_EXPORT_STATUS_CANCELLED,
  DATA_EXPORT_STATUS_COMPLETED,
  DATA_EXPORT_STATUS_PROCESSING,
  DATA_EXPORT_STATUS_FAILED,
  DATA_EXPORT_STATUS_WAITING,
} from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector, useInterval, useProductionUnits } from '../hooks';
import { readDataExport, updateDataExport } from '../actions/dataExportActions';
import { resetStore } from '../actions/serverActions';
import DataExportLink from './DataExportLink';
import { formatDateTimeForFileName } from '../utils/formatting';

interface DataExportButtonProps {
  dataExportStoreId: string;
  exportFunction: (
    id: string,
    query: any,
    units: string,
    localDatetime: string,
    exportType?: string,
    exportParams?: object,
  ) => any;
  query: any;
  exportType?: string;
  exportParams?: object;
}

const DataExportButton = (props: DataExportButtonProps) => {
  const dispatch = useAppDispatch();
  const [isExportRunning, setIsExportRunning] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());
  const [elapsedTime, setElapsedTime] = useState(0);
  const { units } = useProductionUnits();
  const { query, dataExportStoreId, exportFunction, exportType, exportParams } = props;

  const authUser = useAppSelector((state) => state.auth.authUser);
  const dataExport: DataExport = useAppSelector((state) => state.serverStores?.[dataExportStoreId]?.payload);
  const dataExportId: string = dataExport?.id;
  const status = dataExport?.status || DATA_EXPORT_STATUS_WAITING;
  const rowsRetrieved = dataExport?.rows_retrieved;
  const rowCount = dataExport?.row_count;

  useEffect(() => {
    return () => dispatch(resetStore(dataExportStoreId));
  }, [dataExportStoreId, dispatch]);

  const callbackCheck = () => {
    if (dataExportId) {
      dispatch(readDataExport(dataExportStoreId, dataExportId));
    }
  };

  const callbackTime = () => {
    setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
  };

  const startExport = () => {
    dispatch(exportFunction(dataExportStoreId, query!, units, formatDateTimeForFileName(new Date()), exportType, exportParams));
    setIsExportRunning(true);
    setStartTime(Date.now());
    setElapsedTime(0);
  };

  useInterval(
    callbackCheck,
    isExportRunning && dataExportId && [DATA_EXPORT_STATUS_WAITING, DATA_EXPORT_STATUS_PROCESSING].includes(status)
      ? 3000
      : null,
  );
  useInterval(
    callbackTime,
    isExportRunning && [DATA_EXPORT_STATUS_WAITING, DATA_EXPORT_STATUS_PROCESSING].includes(status) ? 1000 : null,
  );

  const onClose = () => {
    setIsExportRunning(false);
    if ([DATA_EXPORT_STATUS_WAITING, DATA_EXPORT_STATUS_PROCESSING].includes(status)) {
      dispatch(
        updateDataExport(dataExportStoreId, dataExportId, dataExport.updated_on, {
          id: dataExportId,
          status: DATA_EXPORT_STATUS_CANCELLED,
        }),
      );
    }
    dispatch(resetStore(dataExportStoreId));
  };

  if (!authUser?.canExport) {
    return (
      <Button size={'sm'} className={'float-right ml-2 mb-2'} title={'Subscribe to export data'}>
        Export results
      </Button>
    );
  }

  return (
    <>
      <Button
        size={'sm'}
        className={'float-right ml-2 mb-2'}
        title={'Export results to zipped .csv file'}
        onClick={startExport}
      >
        Export results
      </Button>
      {isExportRunning && (
        <Modal isOpen={true} toggle={onClose} backdrop={false}>
          <Form>
            <ModalHeader toggle={onClose}>Export results</ModalHeader>
            <ModalBody>
              {status === DATA_EXPORT_STATUS_COMPLETED ? (
                <div>
                  <p className={'text-center'}>File is ready.</p>
                  <p className={'text-center'}>Zip file size: {dataExport?.zip_file_size}</p>
                  <DataExportLink
                    dataExport={dataExport}
                    className={'d-block btn btn-link'}
                    style={{ border: 0, marginLeft: 0 }}
                    title={'Download export file'}
                    afterDownload={onClose}
                  >
                    Click to download
                  </DataExportLink>
                </div>
              ) : status === DATA_EXPORT_STATUS_FAILED ? (
                <p className={'text-center'}>Export failed. Please try again later.</p>
              ) : (
                <>
                  <p className={'text-center'}>Exporting results to zipped .csv file...please wait</p>
                  {rowsRetrieved ? (
                    <p className={'text-center'}>
                      Records retrieved: {rowsRetrieved} of {rowCount}
                    </p>
                  ) : (
                    ''
                  )}
                  {elapsedTime > 0 ? (
                    <p className={'text-center'}>
                      Elapsed time:&nbsp;
                      {`${Math.floor(elapsedTime / 60)
                        .toString()
                        .padStart(2, '0')}:${(elapsedTime % 60).toString().padStart(2, '0')}`}
                    </p>
                  ) : (
                    ''
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default DataExportButton;
