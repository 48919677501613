import { Action } from '../actions/Action';
import { ApiError } from 'tcf-upstream-shared/models';
import { READ_WELL_LOOKUPS } from '../actions/actionTypes';

export interface WellLookupCode {
  lookup: string;
  id: string;
  description: string;
  help_text: string;
}

export interface SearchPayload {
  query: { q?: string };
  results: WellLookupCode[];
  total: number;
}

export interface WellLookupDescription {
  description: string;
  helpText: string;
}

export interface WellLookup {
  [key: string]: WellLookupDescription;
}

export interface WellLookupsState {
  error: string;
  isFetching: boolean;
  configValues: WellLookup;
  statusValues: WellLookup;
  typeValues: WellLookup;
  producingValues: WellLookup;
}

const initialState: WellLookupsState = {
  error: '',
  isFetching: false,
  configValues: {},
  statusValues: {},
  typeValues: {},
  producingValues: {},
};

export const wellLookupsReducer = (state: WellLookupsState = initialState, action: Action) => {
  switch (action.type) {
    case READ_WELL_LOOKUPS.REQUESTED:
      return {
        ...initialState,
        isFetching: true,
      };

    case READ_WELL_LOOKUPS.SUCCEEDED: {
      const searchPayload = action.payload as SearchPayload;
      return {
        ...initialState,
        configValues: Object.fromEntries(
          searchPayload.results
            .filter((item: WellLookupCode) => item.lookup === 'well_config')
            .map((item: WellLookupCode) => [item.id, { description: item.description, helpText: item.help_text }]),
        ),
        statusValues: Object.fromEntries(
          searchPayload.results
            .filter((item: WellLookupCode) => item.lookup === 'well_status')
            .map((item: WellLookupCode) => [item.id, { description: item.description, helpText: item.help_text }]),
        ),
        typeValues: Object.fromEntries(
          searchPayload.results
            .filter((item: WellLookupCode) => item.lookup === 'well_type')
            .map((item: WellLookupCode) => [item.id, { description: item.description, helpText: item.help_text }]),
        ),
        producingValues: Object.fromEntries(
          searchPayload.results
            .filter((item: WellLookupCode) => item.lookup === 'well_producing')
            .map((item: WellLookupCode) => [item.id, { description: item.description, helpText: item.help_text }]),
        ),
      };
    }

    case READ_WELL_LOOKUPS.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...initialState,
        error: error?.message ?? 'Not found',
      };
    }

    default:
      return state;
  }
};
