import { Dispatch } from 'redux';

import { AsyncActionType } from './getAsyncActionTypes';

export interface AsyncActionOptions {
  actionType: AsyncActionType;
  func: (dispatch: Dispatch<any>, getState: () => any) => Promise<any>;
  requestPayload?: {};
  storeIdentifier?: string;
  onSucceeded?: (result: any, dispatch: Dispatch<any>, getState: () => any) => Promise<any>;
  onFailed?: (error: any, dispatch: Dispatch<any>, getState: () => any) => Promise<any>;
  idFieldName?: string;
  originalId?: number | string;
}

export const asyncAction = <AppState>(options: AsyncActionOptions) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch({
      type: options.actionType.REQUESTED,
      storeIdentifier: options.storeIdentifier,
      payload: options.requestPayload,
      idFieldName: options.idFieldName,
      originalId: options.originalId,
    });

    let result;
    try {
      result = await options.func(dispatch, getState as () => AppState);
      if (options.onSucceeded) {
        await options.onSucceeded(result, dispatch, getState);
      }
    } catch (err) {
      if (options.onFailed) {
        await options.onFailed(err, dispatch, getState);
      }
      dispatch({
        type: options.actionType.FAILED,
        storeIdentifier: options.storeIdentifier,
        error: true,
        payload: err?.response?.data ? err.response.data : { message: err.message || 'Unknown error' },
        idFieldName: options.idFieldName,
        originalId: options.originalId,
      });

      throw err;
    }

    dispatch({
      type: options.actionType.SUCCEEDED,
      storeIdentifier: options.storeIdentifier,
      error: false,
      payload: result,
      idFieldName: options.idFieldName,
      originalId: options.originalId,
    });
    return result;
  };
};
