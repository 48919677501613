import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import { Card, CardTitle, CardBody } from 'reactstrap';

import { SearchResults, Transfer } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { paths } from '../../../paths';
import { readTransfers } from '../../../actions/transferActions';
import TcfBootstrapTable, { formatStateName } from '../../../components/TcfBootstrapTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

export const availableFields = ['transferDate', 'fromOperatorName', 'toOperatorName'];

interface RecentTransferListProps {
  storeIdentifier: string;
  queryString: string;
  label?: string;
  children?: any;
}

const RecentTransferList = (props: RecentTransferListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true);
  const { storeIdentifier, queryString, label } = props;
  const payload: SearchResults = useAppSelector((state) => state.serverStores?.[props.storeIdentifier]?.payload);
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[props.storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[props.storeIdentifier]?.isFetching);

  useEffect(() => {
    const _query = JSON.parse(queryString);
    dispatch(readTransfers(storeIdentifier, _query));
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!payload?.total || payload.total < 1) {
    return null;
  }

  const dataTransfer = (payload?.results || []).map((o) => ({
    ...o,
  }));

  const formatApi = (cell: number) =>
    cell?.toString()?.length > 0 ? (
      <Link to={paths.VIEW_WELL.replace(':id', cell.toString())} title={'View well'}>
        {cell.toString().slice(0, 2)}-{cell.toString().slice(2, 5)}-{cell.toString().slice(5, 10)}
      </Link>
    ) : (
      'n/a'
    );

  const formatFromOperatorName = (cell: string, row: Transfer) =>
    cell && cell.length > 0 ? (
      <Link to={paths.VIEW_OPERATOR.replace(':id', row?.fromOperatorId?.toString() || '')} title={'View operator'}>
        {cell}
      </Link>
    ) : (
      'n/a'
    );

  const formatToOperatorName = (cell: string, row: Transfer) =>
    cell && cell.length > 0 ? (
      <Link to={paths.VIEW_OPERATOR.replace(':id', row?.toOperatorId?.toString() || '')} title={'View operator'}>
        {cell}
      </Link>
    ) : (
      'n/a'
    );

  const columns = [
    {
      dataField: 'transferDate',
      text: 'Date',
      sort: false,
    },
    {
      dataField: 'wellApi',
      text: 'API',
      formatter: formatApi,
      sort: false,
    },
    {
      dataField: 'fromOperatorName',
      text: 'From Operator',
      formatter: formatFromOperatorName,
      sort: false,
    },
    {
      dataField: 'toOperatorName',
      text: 'To Operator',
      formatter: formatToOperatorName,
      sort: false,
    },
    {
      dataField: 'stateAbbrev',
      text: 'State',
      formatter: formatStateName,
      sort: false,
    },
  ];

  const totalSize = payload?.total || 0;

  return (
    <Card className={'mb-4'}>
      <CardBody>
        {label ? <CardTitle>{label}:</CardTitle> : null}
        <TcfBootstrapTable allowWrap columns={columns} dataSource={dataTransfer} totalSize={totalSize} />
        {props.children}
      </CardBody>
    </Card>
  );
};

export default RecentTransferList;
