import React from 'react';
import { Form, Button, Row, Col } from 'reactstrap';
import { Form as RFForm } from 'react-final-form';

import * as Inputs from '../../../components/Inputs';

interface OwnProps {
  onSubmit: any;
  initialValues: any;
}

const validateLogin = (value: string) => {
  const email = (value || '').trim();
  if (!/^.+@.+\..+$/.test(email)) return 'Please enter a valid email address';
};

const LoginForm = (props: OwnProps) => (
  <RFForm onSubmit={props.onSubmit} initialValues={props.initialValues}>
    {({ handleSubmit, invalid, submitting }) => (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Inputs.TcfTextInput type={'email'} placeholder={'jane@example.com'} name={'email'} validate={validateLogin} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Button
              disabled={invalid || submitting}
              type="submit"
              color={'primary'}
              style={{ whiteSpace: 'nowrap' }}
              title="Please enter your email address above"
            >
              Send login email
            </Button>
          </Col>
          <Col xs={12} md={6} className="text-md-right">
            <a
              href="https://thecapitolforum.com/request-a-trial/?m=I%E2%80%99d+like+admission+to+The+Capitol+Forum%E2%80%99s+energy+platform+with+full+access+to+information+on+oil+%26+gas+production."
              target="_blank"
              rel="noopener noreferrer"
            >
              Not a Subscriber?
            </a>
          </Col>
        </Row>
      </Form>
    )}
  </RFForm>
);

export default LoginForm;
