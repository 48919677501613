import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { upsertQueryFilter, removeQueryFilter } from '../../../actions/queryActions';
import { FormGroup, Input, Label } from 'reactstrap';

const IS_PRODUCING = true;
const FILTER_FIELD = 'isProducing';

interface FilterProducingProps {
  queryIdentifier: string;
}

const ProducingFilter = (props: FilterProducingProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier } = props;
  const isProducing: boolean = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.[FILTER_FIELD]);

  const onProducingChange = (event: any) => {
    if (event.target?.checked) {
      dispatch(removeQueryFilter(queryIdentifier, FILTER_FIELD));
    } else {
      dispatch(upsertQueryFilter(queryIdentifier, FILTER_FIELD, IS_PRODUCING));
    }
  };

  return (
    <FormGroup check>
      <Label check>
        <Input type="checkbox" onChange={onProducingChange} checked={isProducing !== IS_PRODUCING} />
        &nbsp;Non-producing operators
      </Label>
    </FormGroup>
  );
};

export default ProducingFilter;
