import * as React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { UpstreamToken } from 'tcf-upstream-shared/models';

import { sendLoginEmail } from '../../actions/authActions';
import { getIsLoggingIn, getAuthUser } from '../../reducers/authReducer';
import LoginForm from './components/LoginForm';
import { useAppDispatch, useAppSelector } from '../../hooks';

const styles = {
  layout: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    marginTop: '2rem',
  },
  content: {
    flex: '0 1 400px',
  },
};

const Login = () => {
  const dispatch = useAppDispatch();
  const { state }: any = useLocation();
  const isLoggingIn = useAppSelector((appState) => getIsLoggingIn(appState));
  const authUser: UpstreamToken | undefined = useAppSelector((appState) => getAuthUser(appState));
  const redirectTo: string = (state && state.redirectTo) || undefined;

  const submit = async (payload: any) => {
    if (payload) {
      await dispatch(sendLoginEmail(payload));
    }
  };

  return (
    <main className={'mainContent'}>
      <div style={styles.layout}>
        {authUser ? (
          <Redirect to="/" />
        ) : isLoggingIn ? (
          <div style={styles.content}>
            <p>Logging in...</p>
          </div>
        ) : (
          <div style={styles.content}>
            <h2>Subscriber Login</h2>
            <p>Enter your email to request a login link.</p>
            <LoginForm onSubmit={submit} initialValues={{ redirectTo }} />
          </div>
        )}
      </div>
    </main>
  );
};

export default Login;
