import React, { useState } from 'react';
import {
  GradientDefs,
  Hint,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesPoint,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';
import 'react-vis/dist/style.css';
import 'react-virtualized/styles.css';
import { AutoSizer } from 'react-virtualized';
import TCFChartLabel from './TCFChartLabel';

interface ProductionChartProps {
  seriesData: any[];
  yTitle: string;
  h: number;
  color?: string;
  lastFullYear?: number;
}

const firstDigit = (x: number): number => {
  return parseInt(('' + Math.max(0, x))[0], 10);
};

const firstTwoDigits = (x: number): number => {
  return parseInt(('' + Math.max(0, x)).slice(0, 2), 10);
};

const numDigits = (x: number): number => {
  return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;
};

const formatTick = (x: number): string => {
  const digits = numDigits(x);
  const divisor = digits >= 10 ? 1000000000 : digits >= 7 ? 1000000 : digits >= 4 ? 1000 : 1;
  const suffix = divisor === 1000000000 ? 'B' : divisor === 1000000 ? 'M' : divisor === 1000 ? 'K' : '';
  return (x / divisor).toString().concat(suffix);
};

const getYDomain = (x: number): number => {
  const n = numDigits(x);
  const f = firstDigit(x);
  if (n < 2 || f >= 5) return Math.max(10, Math.pow(10, Math.max(1, n - 1)) * (f + 1));
  return Math.max(10, Math.pow(10, Math.max(1, n - 2)) * (firstTwoDigits(x) + 1));
};

const ProductionChart = (props: ProductionChartProps) => {
  const { seriesData, h, yTitle, color = 'darkgrey', lastFullYear } = props;
  const [mouseValue, setMouseValue] = useState<VerticalBarSeriesPoint | undefined>(undefined);

  if (seriesData?.length < 1) {
    return <h6>No data</h6>;
  }

  const handleValueMouseOver = (value: VerticalBarSeriesPoint) => setMouseValue(value);
  const handleMouseOut = () => setMouseValue(undefined);
  const formatTickLabel = (v: number) => (lastFullYear && v > lastFullYear ? `${v}*` : v.toString());

  const exceedsLastFullYear = seriesData.some((sd) => lastFullYear && sd.x > lastFullYear);

  const hashId = `${yTitle.replace(/[^a-zA-Z0-9]/g, '')}HashId`;
  const filledSeriesData = seriesData.map((sd) =>
    !lastFullYear || sd.x <= lastFullYear ? sd : { ...sd, color: `url(#${hashId})` },
  );

  const maxY = Math.max(...seriesData.map((d: any) => d.y));

  return (
    <AutoSizer disableHeight>
      {({ width }: any) =>
        width > 0 ? (
          <div style={{ width, paddingBottom: '2rem' }}>
            <XYPlot
              height={h}
              width={width}
              yPadding={1}
              margin={{ left: 60, right: 20, top: 10, bottom: 45 }}
              yDomain={[0, getYDomain(maxY)]}
            >
              <GradientDefs>
                <pattern id={hashId} width={5} height={5} patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
                  <rect height={5} width={5} style={{ stroke: color, fill: 'white' }} />
                </pattern>
              </GradientDefs>
              <VerticalGridLines tickValues={seriesData.map((b: any) => b.x)} />
              <HorizontalGridLines />
              <XAxis tickValues={seriesData.map((b: any) => b.x)} tickFormat={formatTickLabel} />
              <TCFChartLabel
                text="Year"
                className="alt-x-label"
                xPercent={0.5}
                yPercent={1}
                yPixels={-17}
                style={{
                  textAnchor: 'middle',
                }}
              />
              <YAxis tickFormat={(v) => formatTick(v)} />
              <TCFChartLabel
                text={yTitle}
                className="alt-y-label"
                xPercent={0}
                yPercent={0.5}
                xPixels={9}
                style={{
                  textAnchor: 'middle',
                  transform: 'rotate(-90)',
                }}
              />
              <VerticalBarSeries
                data={filledSeriesData}
                barWidth={0.85}
                colorType="literal"
                color={color}
                stroke={'#888'}
                onValueMouseOver={handleValueMouseOver}
                onValueMouseOut={handleMouseOut}
              />
              {mouseValue ? (
                <Hint value={mouseValue}>
                  <div className={'rv-hint__content'}>{mouseValue.y.toLocaleString()}</div>
                </Hint>
              ) : null}
            </XYPlot>
            {exceedsLastFullYear ? (
              <div style={{ color: '#888', fontSize: '0.7rem', textAlign: 'center', width }}>* source reporting incomplete</div>
            ) : null}
          </div>
        ) : null
      }
    </AutoSizer>
  );
};

export default ProductionChart;
