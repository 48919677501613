import * as React from 'react';
import Loader from 'react-loader';

import ErrorComponent from './ErrorComponent';

export interface AsyncPageProps {
  children?: any;
  error?: string;
  loading?: boolean;
  title?: string;
}

export default (props: AsyncPageProps) => {
  const { children, error, loading, title } = props;

  return (
    <Loader loaded={!loading}>
      <div title={title}>{error ? <ErrorComponent error={error} /> : children}</div>
    </Loader>
  );
};
