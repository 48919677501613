import { Query, SearchResults } from 'tcf-upstream-shared/models';

import {
  ADMIN_OBJECTS_CREATE,
  ADMIN_OBJECTS_READ,
  ADMIN_OBJECTS_UPDATE,
  ADMIN_OBJECTS_DELETE,
  ADMIN_OBJECTS_REMOVE_STORE,
} from './actionTypes';
import { asyncAction } from './asyncAction';
import { library } from '../libraryApi';
import { toast } from './toastActions';

// Name is a bit of a misnomer.  Creates a single object and adds the resulting object to the multiple object store.
export const createAdminObjects = <T>(
  baseUrl: string,
  storeIdentifier: string,
  item: Partial<T>,
  name = 'object',
  idFieldName = 'id',
) => {
  return asyncAction({
    actionType: ADMIN_OBJECTS_CREATE,
    storeIdentifier,
    func: async () => (await library.adminObjects.post(baseUrl, item)).data as T,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Success',
          message: `Successfully created ${name}.`,
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            result?.response?.status === 503
              ? 'Site is currently undergoing maintenance.  Please try again later.'
              : `There was an error creating ${name}.`,
        }),
      ),
    idFieldName,
  });
};

export const readAdminObjects = (baseUrl: string, storeIdentifier: string, query?: Query) => {
  return asyncAction({
    actionType: ADMIN_OBJECTS_READ,
    storeIdentifier,
    func: async () => (await library.adminObjects.search(baseUrl, query)).data as SearchResults,
  });
};

export const readAdminDiffObjects = (baseUrl: string, storeIdentifier: string, id: number | string, oid: number | string) => {
  return asyncAction({
    actionType: ADMIN_OBJECTS_READ,
    storeIdentifier,
    func: async () => (await library.adminObjects.diff(baseUrl, id, oid)).data as SearchResults,
  });
};

// Name is a bit of a misnomer.  Saves a single object and updates that object in the multiple object store.
export const updateAdminObjects = <T>(
  baseUrl: string,
  storeIdentifier: string,
  id: number | string,
  updateDate: Date,
  item: Partial<T>,
  name = 'object',
  idFieldName = 'id',
) => {
  return asyncAction({
    actionType: ADMIN_OBJECTS_UPDATE,
    storeIdentifier,
    func: async () => (await library.adminObjects.patch(baseUrl, id, updateDate, item)).data as T,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Success',
          message: `Successfully updated ${name}.`,
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            result?.response?.status === 503
              ? 'Site is currently undergoing maintenance.  Please try again later.'
              : `There was an error updating ${name}.`,
        }),
      ),
    idFieldName,
    originalId: id,
  });
};

// Name is a bit of a misnomer.  Deletes a single object and removes that object from the multiple object store.
export const deleteAdminObjects = (
  baseUrl: string,
  storeIdentifier: string,
  id: number | string,
  name = 'object',
  idFieldName = 'id',
) => {
  return asyncAction({
    actionType: ADMIN_OBJECTS_DELETE,
    storeIdentifier,
    func: async () => (await library.adminObjects.delete(baseUrl, id)).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Success',
          message: `Successfully deleted ${name}.`,
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            result?.response?.status === 503
              ? 'Site is currently undergoing maintenance.  Please try again later.'
              : `There was an error deleting ${name}.`,
        }),
      ),
    idFieldName,
    originalId: id,
  });
};

export const removeAdminObjectsStore = (storeIdentifier: string) => ({ type: ADMIN_OBJECTS_REMOVE_STORE, storeIdentifier });
