import React, { useContext } from 'react';
import Loader from 'react-loader';

import { Region, QueryFilters } from 'tcf-upstream-shared/models';

import { useAppSelector } from '../../../hooks';
import MapContext from '../../../components/App/MapContext';
import TcfMap from '../../../components/TcfMap';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import { sizes } from '../../../theme';

interface RegionMapProps {
  storeIdentifier: string;
  queryIdentifier: string;
  mapTypeId?: string;
  defaultZoom?: number;
  height?: string;
}

const RegionMap = (props: RegionMapProps) => {
  const isApiLoaded = useContext(MapContext);
  const { storeIdentifier, queryIdentifier, mapTypeId, defaultZoom, height } = props;
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const filters: QueryFilters = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const region: Region = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);

  if (!isApiLoaded || isFetching || !filters) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!region || !region.centerLocation) {
    return null;
  }

  const zoom = defaultZoom || (region.level === 1 ? 5 : region.level === 2 ? 7 : 10);
  const finalMapTypeId = mapTypeId || 'terrain';
  const finalHeight = height || Math.min(600, Math.max(200, window.innerHeight - sizes.appHeaderPadding - 100));

  const center = {
    lng: region.centerLocation[0],
    lat: region.centerLocation[1],
  };

  return (
    <div style={{ height: finalHeight.toString() + 'px' }}>
      <TcfMap mapTypeId={finalMapTypeId} zoom={zoom} center={center} wellFilters={{ ...filters }} />
    </div>
  );
};

export default RegionMap;
