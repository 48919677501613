import React from 'react';
import Loader from 'react-loader';

import { Region, RegionYear } from 'tcf-upstream-shared/models';

import { useAppSelector, useProductionUnits } from '../../../hooks';
import ProductionChart from '../../Production/components/ProductionChart';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

const GRAPH_MIN_YEAR = 2010;

interface RegionProductionChartProps {
  storeIdentifier: string;
  dataField: string;
  yTitle: string;
  h: number;
  color?: string;
  minYear?: number;
  lastFullYear?: number;
}

const RegionProductionChart = (props: RegionProductionChartProps) => {
  const { storeIdentifier, dataField, h, yTitle, color, minYear, lastFullYear } = props;
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const region: Region = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);

  const { convertToSelectedUnits } = useProductionUnits();

  if (isFetching) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!region) {
    return null;
  }

  const plotData = region.prodYear
    ?.filter((ry: RegionYear) => ry.year >= (minYear || GRAPH_MIN_YEAR))
    .sort((a: RegionYear, b: RegionYear) => a.year - b.year)
    .map((ry: RegionYear) => ({
      ...ry,
      x: ry.year,
      y:
        dataField === 'wellCount'
          ? Math.round(Math.max(0, ry[dataField]))
          : convertToSelectedUnits(Math.round(Math.max(0, ry[dataField]))),
    }));
  const hasValues = plotData?.find((t: any) => t.y > 0);

  if (!hasValues) {
    return <h5>No data from {minYear || GRAPH_MIN_YEAR} to present.</h5>;
  }

  return <ProductionChart lastFullYear={lastFullYear} seriesData={plotData!} yTitle={yTitle} h={h} color={color} />;
};

export default RegionProductionChart;
