import React from 'react';
import Loader from 'react-loader';

import { ROOT_REGION_ID } from 'tcf-upstream-shared/constants';
import { Operator, OperatorRegionYear } from 'tcf-upstream-shared/models';

import { useAppSelector, useProductionUnits } from '../../../hooks';
import { range } from '../../../utils';
import ProductionChart from '../../Production/components/ProductionChart';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

interface OperatorProductionChartProps {
  storeIdentifier: string;
  regionId: number;
  dataField: string;
  yTitle: string;
  h: number;
  color?: string;
  minYear?: number;
  lastFullYear?: number;
}

const OperatorProductionChart = (props: OperatorProductionChartProps) => {
  const { storeIdentifier, dataField, h, yTitle, color, regionId, lastFullYear } = props;
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const operator: Operator = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);

  const { convertToSelectedUnits } = useProductionUnits();

  if (isFetching) return <Loader loaded={false} />;
  if (error) return <ErrorComponent error={error} />;
  if (!operator) return null;

  const prodYearField = 'prodYear' + (regionId >= ROOT_REGION_ID ? 'Region' : regionId < 100 ? 'State' : 'County');
  const operatorRegionYearData = (operator[prodYearField] || []).filter((o: OperatorRegionYear) => o.regionId === regionId);

  if (operatorRegionYearData.length < 1) {
    return <h5>No data.</h5>;
  }

  const minDataYear = operatorRegionYearData.reduce(
    (a: number, o: OperatorRegionYear) => (o.year < a ? o.year : a),
    Number.MAX_SAFE_INTEGER,
  );

  const maxDataYear = operatorRegionYearData.reduce(
    (a: number, o: OperatorRegionYear) => (o.year > a ? o.year : a),
    Number.MIN_SAFE_INTEGER,
  );

  const minEffectiveYear = props.minYear ?? Math.max(minDataYear, maxDataYear - 14);
  const allYears = range(minEffectiveYear, maxDataYear);

  const plotData = allYears.map((year) => {
    const ory = operatorRegionYearData.find((o: OperatorRegionYear) => o.year === year);
    const rawValue = ory && Math.round(Math.max(0, ory[dataField]));
    return ory
      ? {
          ...ory,
          x: ory.year,
          y: dataField === 'wellCount' ? rawValue : convertToSelectedUnits(rawValue),
        }
      : {
          x: year,
          y: 0,
        };
  });

  const hasValues = plotData.find((t: any) => t.y > 0);
  if (!hasValues) return <h5>No data from {minEffectiveYear} to present.</h5>;

  return <ProductionChart lastFullYear={lastFullYear} seriesData={plotData!} yTitle={yTitle} h={h} color={color} />;
};

export default OperatorProductionChart;
