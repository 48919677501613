import React, { useContext, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';

import { Well, QueryFilters } from 'tcf-upstream-shared/models';
import MapContext from './App/MapContext';
import { paths } from '../paths';
import { readWellsByBoundingBox } from '../actions/wellActions';
import MapControl from './MapControl';

const infoStyle = {
  background: 'white',
  border: '1px solid #ccc',
  padding: 15,
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const controlStyle = {
  padding: '5px 10px',
  backgroundColor: '#fff',
  border: '2px solid #fff',
  borderRadius: '3px',
  boxShadow: '0 2px 6px rgba(0,0,0,.3)',
  marginRight: '10px',
  lineHeight: '24px',
};

interface TcfMapProps {
  center: google.maps.LatLngLiteral;
  zoom: number;
  mapTypeId?: string;
  wellFilters?: QueryFilters;
  children?: any;
}

// Using markers from https://sites.google.com/site/gmapsdevelopment/
const MARKERS_BASE_URL = `${process.env.PUBLIC_URL}/markers`;
const MARKER_URLS = {
  xs: {
    horiz: {
      ACTV: `${MARKERS_BASE_URL}/xs/yellow-dot-test45.png`,
      PLUG: `${MARKERS_BASE_URL}/xs/orange-dot-test45.png`,
      ABAN: `${MARKERS_BASE_URL}/xs/blue-dot-test45.png`,
      INAC: `${MARKERS_BASE_URL}/xs/red-dot-test45.png`,
      DEFAULT: `${MARKERS_BASE_URL}/xs/white-dot-test45.png`,
    },
    vert: {
      ACTV: `${MARKERS_BASE_URL}/xs/yellow-dot-test.png`,
      PLUG: `${MARKERS_BASE_URL}/xs/orange-dot-test.png`,
      ABAN: `${MARKERS_BASE_URL}/xs/blue-dot-test.png`,
      INAC: `${MARKERS_BASE_URL}/xs/red-dot-test.png`,
      DEFAULT: `${MARKERS_BASE_URL}/xs/white-dot-test.png`,
    },
  },
  sm: {
    horiz: {
      ACTV: `${MARKERS_BASE_URL}/sm/yellow-dot-ang.png`,
      PLUG: `${MARKERS_BASE_URL}/sm/orange-dot-ang.png`,
      ABAN: `${MARKERS_BASE_URL}/sm/blue-dot-ang.png`,
      INAC: `${MARKERS_BASE_URL}/sm/red-dot-ang.png`,
      DEFAULT: `${MARKERS_BASE_URL}/sm/white-dot-ang.png`,
    },
    vert: {
      ACTV: `${MARKERS_BASE_URL}/sm/yellow-dot.png`,
      PLUG: `${MARKERS_BASE_URL}/sm/orange-dot.png`,
      ABAN: `${MARKERS_BASE_URL}/sm/blue-dot.png`,
      INAC: `${MARKERS_BASE_URL}/sm/red-dot.png`,
      DEFAULT: `${MARKERS_BASE_URL}/sm/white-dot.png`,
    },
  },
  md: {
    horiz: {
      ACTV: `${MARKERS_BASE_URL}/md/yellow-dot-ang.png`,
      PLUG: `${MARKERS_BASE_URL}/md/orange-dot-ang.png`,
      ABAN: `${MARKERS_BASE_URL}/md/blue-dot-ang.png`,
      INAC: `${MARKERS_BASE_URL}/md/red-dot-ang.png`,
      DEFAULT: `${MARKERS_BASE_URL}/md/white-dot-ang.png`,
    },
    vert: {
      ACTV: `${MARKERS_BASE_URL}/md/yellow-dot.png`,
      PLUG: `${MARKERS_BASE_URL}/md/orange-dot.png`,
      ABAN: `${MARKERS_BASE_URL}/md/blue-dot.png`,
      INAC: `${MARKERS_BASE_URL}/md/red-dot.png`,
      DEFAULT: `${MARKERS_BASE_URL}/md/white-dot.png`,
    },
  },
};

// const getStatusMarker = (m: google.maps.Map, status: string) => {
//   if (!m) return null;
//
//   const zoom = m.getZoom();
//   const markerUrls = zoom < 10 ? MARKER_URLS.xs : zoom < 14 ? MARKER_URLS.sm : MARKER_URLS.md;
//   return markerUrls.vert[status] || markerUrls.vert.DEFAULT;
// };

const setFeatureStyle = (m: google.maps.Map, feature: google.maps.Data.Feature) => {
  if (m) {
    const zoom = m.getZoom();
    const markerUrls = zoom < 10 ? MARKER_URLS.xs : zoom < 14 ? MARKER_URLS.sm : MARKER_URLS.md;
    const well = feature.getProperty('well');
    const configMarkerUrls = well?.config === 'HORIZ' ? markerUrls.horiz : markerUrls.vert;
    return {
      title: well?.site || well?.api?.slice(0, 10) || well?.id || '',
      icon: configMarkerUrls[well.status] || configMarkerUrls.DEFAULT,
    };
  }
  return {};
};

const TcfMap = (props: TcfMapProps) => {
  const isApiLoaded = useContext(MapContext);
  const { zoom, mapTypeId, center, wellFilters } = props;
  const [map, setMap] = useState<any>(null);
  const [infoWindow] = useState<google.maps.InfoWindow>(new google.maps.InfoWindow({}));
  // const [wellMarkers, setWellMarkers] = useState([]);

  // const clearWells = (map: google.maps.Map, wellMarkers: any[]) => {
  //   for (let wm = wellMarkers.pop(); wm; wm = wellMarkers.pop()) {
  //     wm.setMap(null);
  //     google.maps.event.clearInstanceListeners(wm);
  //   }
  // };

  // const addWells = (map: google.maps.Map, wells: Well[], wellMarkers: google.maps.Marker[]) => {
  //   const data = map.data;
  //
  //   const well_image = wells.length > 100 ? DOT_IMAGE : undefined;
  //   data.setStyle({
  //     icon: well_image,
  //   });
  //
  //   wells.forEach((w: Well) => {
  //     const marker = new google.maps.Marker({
  //       position: { lng: w.location![0], lat: w.location![1] },
  //       title: w.site,
  //       map,
  //     });
  //
  //     google.maps.event.addListener(marker, 'click', () = {
  //       infoWindow?.close();
  //       infoWindow = new google.maps.InfoWindow({
  //       });
  //       infoWindow.open(map, marker);
  //     });
  //     wellMarkers.push(marker);
  //   });
  // };

  const clearWells = () => {
    const data: google.maps.Data = map.data;
    data.forEach((feature: google.maps.Data.Feature) => data.remove(feature));
  };

  const addWells = (wells: Well[]) => {
    const data = map.data;
    wells.forEach((well: Well) =>
      data.add({
        id: well.id,
        geometry: { lng: well.location![0], lat: well.location![1] },
        properties: { well },
      }),
    );
  };

  const getInfoWindowContent = (w: Well) => {
    const url = paths.VIEW_WELL.replace(':id', w.id.toString());
    const api = (w?.api && w.api.slice(0, 2) + '-' + w.api.slice(2, 5) + '-' + w.api.slice(5, 10)) || '';
    return `
     <div style=${infoStyle}>
        <table>
          <tr><th>Well</th><td><a href=${url} title='View well details'>${w.site || api || '?'}</a></td></tr>
          <tr><th>API</th><td>${api}</td></tr>
          <tr><th>County</th><td>${w.countyName}, ${w.stateAbbrev}</td></tr>
          <tr><th>Type</th><td>${w.type}</td></tr>
          <tr><th>Config</th><td>${w.config || 'n/a'}</td></tr>
          <tr><th>Status</th><td>${w.status || 'n/a'}</td></tr>
          <tr><th>Spud</th><td>${w.spud || 'n/a'}</td></tr>
        </Table>
      </div>
    `;
  };
  // ${w.operators.length > 0 && (
  //   <tr>
  //     <th>Operator</th>
  //     <td>
  //       {w.operators
  //         .filter((o: any) => o.isCurrentOperator)
  //         .map((o: any) => (
  //           <a
  //             key={o.operatorId}
  //             href={paths.VIEW_OPERATOR.replace(':id', o.operatorId.toString())}
  //             title={'View operator details'}
  //           >
  //             {o.operatorName}
  //           </a>
  //         ))}
  //     </td>
  //   </tr>
  // )}

  const onLoadMap = (m: any) => {
    setMap(m);
    const data = m.data;

    m.setMapTypeId(mapTypeId);

    data.setStyle((feature: google.maps.Data.Feature) => {
      return setFeatureStyle(m, feature);
    });

    data.addListener('click', (event: any) => {
      const feature = event.feature;
      const well = feature.getProperty('well');
      if (well) {
        infoWindow.setContent(getInfoWindowContent(well));
        infoWindow.setPosition(feature.getGeometry().get());
        infoWindow.setOptions({ pixelOffset: new google.maps.Size(0, -30) });
        infoWindow.open(m);
      }
    });
  };

  const onIdleMap = async () => {
    if (map) {
      const bounds = map!.getBounds();
      try {
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        const box = {
          top: northEast.lat(),
          right: northEast.lng(),
          left: southWest.lng(),
          bottom: southWest.lat(),
        };
        if (Math.abs(box.top - box.bottom) > 0.0001 && Math.abs(box.right - box.left) > 0.0001) {
          const payload = await readWellsByBoundingBox({ box, filters: wellFilters });
          if (payload) {
            // clearWells(map, wellMarkers);
            clearWells();
            const wells = payload.results;
            // addWells(map, wells, wellMarkers);
            addWells(wells);
          }
        }
      } catch (err) {
        // console.log(err);
      }
    }
  };

  // const onLoadData = (d: any) => {
  //   console.log('data: ', d);
  // };

  return isApiLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      mapTypeId={mapTypeId}
      onLoad={onLoadMap}
      onIdle={onIdleMap}
      options={{
        minZoom: 5,
        scaleControl: true,
      }}
    >
      <MapControl position={window.google.maps.ControlPosition.RIGHT_TOP}>
        <div style={controlStyle}>
          <h6 style={{ marginBottom: 0 }}>Wells</h6>
          <img src={MARKER_URLS.sm.vert.ACTV} alt={'Marker for active wells'} />
          &nbsp;Active
          <br />
          <img src={MARKER_URLS.sm.vert.PLUG} alt={'Marker for plugged wells'} />
          &nbsp;Plugged
          <br />
          <img src={MARKER_URLS.sm.vert.ABAN} alt={'Marker for abandoned wells'} />
          &nbsp;Abandoned
          <br />
          <img src={MARKER_URLS.sm.vert.INAC} alt={'Marker for inactive wells'} />
          &nbsp;Inactive
          <br />
          <img src={MARKER_URLS.sm.vert.DEFAULT} alt={'Marker for wells with any other status'} />
          &nbsp;All Others
        </div>
      </MapControl>
      {props.children}
    </GoogleMap>
  ) : null;
};

export default TcfMap;
