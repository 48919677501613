import { useCookies } from 'react-cookie';

const PRODUCTION_UNITS_KEY = 'production-units';

export const BOE = 'boe';
export const MCFE = 'mcfe';

export const TITLES = {
  [BOE]: 'BOE',
  [MCFE]: 'Mcfe',
};

const BOE_TO_MCFE_FACTOR = 6;
const MCF_TO_BOE_FACTOR = 1 / 6;

const useProductionUnits = () => {
  const [cookies, setCookie] = useCookies([PRODUCTION_UNITS_KEY]);
  const _units = cookies[PRODUCTION_UNITS_KEY] || MCFE;
  const setProductionUnits = (units: 'boe' | 'mcfe') => {
    setCookie(PRODUCTION_UNITS_KEY, units, {
      path: '/',
      secure: true,
      sameSite: 'strict',
    });
  };

  const convertToSelectedUnits = (val?: any, valUnits: 'boe' | 'mcfe' = 'boe') => {
    if (typeof val !== 'number') {
      return val;
    }
    return valUnits === _units ? val : val * (valUnits === 'boe' ? BOE_TO_MCFE_FACTOR : MCF_TO_BOE_FACTOR);
  };

  return { units: _units, title: TITLES[_units], convertToSelectedUnits, setProductionUnits };
};

export default useProductionUnits;
