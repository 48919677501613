import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { usePopupToggler } from '../hooks';

interface OwnProps {
  label: string;
  children: React.ReactNode;
  [key: string]: any;
}

const preventClickThrough = (event: React.MouseEvent<HTMLSpanElement>) => {
  event.preventDefault();
};

const Definition = (props: OwnProps) => {
  const { label, children } = props;
  const { state, toggle } = usePopupToggler();

  const targetId = label.replace(/[^a-zA-Z0-9]/g, '');

  return (
    <>
      <span id={targetId} style={{ borderBottom: '1px dashed #007bff', cursor: 'pointer' }} onClick={preventClickThrough}>
        {label}
      </span>
      <Popover
        isOpen={state.isOpen}
        trigger="legacy"
        placement="right"
        target={targetId}
        toggle={toggle}
        boundariesElement="window"
      >
        <PopoverHeader style={{ fontWeight: 'normal' }}>{label}</PopoverHeader>
        <PopoverBody style={{ color: '#444', fontSize: '0.75rem' }}>{children}</PopoverBody>
      </Popover>
    </>
  );
};

export default Definition;
