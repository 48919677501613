import React, { useEffect, useState } from 'react';

import Sidebar from '../../components/Sidebar';
import ListCount from '../../components/ListCount';
import SourceList from './components/SourceList';
import FilterSourcesForm, { VisibleFilters } from './components/FilterSourcesForm';

const SOURCES_STORE = 'sourcesListStore';
const SOURCES_QUERY = 'sourcesListQuery';

const ListSources = () => {
  const initVisibleFilters: VisibleFilters = {
    location: true,
    dataType: true,
  };
  const [visibleFilters, setVisibleFilters] = useState(initVisibleFilters);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onVisibleFilterToggle = (id: string) => {
    const newVisibleFilters = { ...visibleFilters };
    newVisibleFilters[id] = !visibleFilters[id];
    setVisibleFilters(newVisibleFilters);
  };

  return (
    <div className={'flexPage'}>
      <Sidebar>
        <FilterSourcesForm
          queryIdentifier={SOURCES_QUERY}
          storeIdentifier={SOURCES_STORE}
          visibleFilters={visibleFilters}
          onVisibleFilterToggle={onVisibleFilterToggle}
        />
      </Sidebar>
      <main className={'mainContent'}>
        <ListCount storeIdentifier={SOURCES_STORE} label={'Data Source Update'} />
        <div className={'mt-4'}>
          <SourceList storeIdentifier={SOURCES_STORE} queryIdentifier={SOURCES_QUERY} />
        </div>
      </main>
    </div>
  );
};

export default ListSources;
