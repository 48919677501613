import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch } from '../../hooks';
import { paths } from '../../paths';
import { logout } from '../../actions/authActions';

const styles = {
  layout: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignContent: 'center',
    marginTop: '2rem',
  },
  link: {
    marginTop: '1rem',
    width: '100px',
  },
};

const Logout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <main className={'mainContent'}>
      <div style={styles.layout}>
        <h2>You have successfully logged out.</h2>
        <Link title={'Go to page to request a login email'} className={'btn btn-primary'} to={paths.LOGIN} style={styles.link}>
          Login
        </Link>
      </div>
    </main>
  );
};

export default Logout;
