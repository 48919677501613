import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import { useAppDispatch } from '../../hooks';
import { setQuery, resetQueryFilter } from '../../actions/queryActions';
import { resetStore } from '../../actions/serverActions';
import Sidebar from '../../components/Sidebar';
import ListCount from '../../components/ListCount';
import WellList from './components/WellList';
import WellsMap from './components/WellsMap';
import WellsFilterForm, { VisibleFilters } from './components/WellsFilterForm';

const WELLS_STORE = 'wellsListStore';
const WELLS_QUERY = 'wellsListQuery';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_SORT = [{ 'prodSummary.boeTotal': 'desc' }];

const ListWells = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState('List');

  const initVisibleFilters: VisibleFilters = {
    location: true,
    wellType: true,
    config: true,
    status: false,
    producing: false,
  };
  const [visibleFilters, setVisibleFilters] = useState(initVisibleFilters);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setQuery(WELLS_QUERY, { limit: DEFAULT_PAGE_SIZE, sort: DEFAULT_SORT, filters: {} }));
    return () => {
      dispatch(resetStore(WELLS_STORE));
    };
  }, [dispatch]);

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const resetWellsQuery = () => {
    dispatch(resetQueryFilter(WELLS_QUERY));
  };

  const onVisibleFilterToggle = (id: string) => {
    const newVisibleFilters = { ...visibleFilters };
    newVisibleFilters[id] = !visibleFilters[id];
    setVisibleFilters(newVisibleFilters);
  };

  return (
    <div className={'flexPage'}>
      <Sidebar>
        <WellsFilterForm
          storeIdentifier={WELLS_STORE}
          queryIdentifier={WELLS_QUERY}
          visibleFilters={visibleFilters}
          onVisibleFilterToggle={onVisibleFilterToggle}
          resetQueryFilter={resetWellsQuery}
          showKeywordFilter={true}
          showLocationFilter={true}
        />
      </Sidebar>
      <main className={'mainContent'}>
        <ListCount storeIdentifier={WELLS_STORE} label={'Well'} />
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab === 'List' ? 'active' : ''} onClick={() => toggle('List')} title={'Show wells list'}>
              List
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 'Map' ? 'active' : ''}
              onClick={() => toggle('Map')}
              title={'Display map of wells'}
            >
              Map
            </NavLink>
          </NavItem>
        </Nav>
        <div className={'mt-2'}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={'List'}>
              <WellList storeIdentifier={WELLS_STORE} queryIdentifier={WELLS_QUERY} />
            </TabPane>
            <TabPane tabId={'Map'}>
              <WellsMap storeIdentifier={WELLS_STORE} queryIdentifier={WELLS_QUERY} />
            </TabPane>
          </TabContent>
        </div>
      </main>
    </div>
  );
};

export default ListWells;
