// Not to be confused with the ChartLabel that comes bundled with react-vis.  I found ChartLabel's percent based
// positioning to be insufficient so I added in pixel based manipulation.
import React from 'react';

interface OwnProps {
  innerHeight?: number;
  innerWidth?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  className?: string;
  ignoreMargins?: boolean;
  style?: object;
  text?: string;
  xPercent?: number;
  yPercent?: number;
  xPixels?: number;
  yPixels?: number;
}

const TCFChartLabel = (props: OwnProps) => {
  const {
    innerHeight = 0,
    innerWidth = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    marginTop = 0,
    className = '',
    ignoreMargins = true,
    style = {},
    text = '',
    xPercent = 0,
    yPercent = 0,
    xPixels = 0,
    yPixels = 0,
  } = props;

  const width = innerWidth + (ignoreMargins ? marginLeft + marginRight : 0);
  const height = innerHeight + (ignoreMargins ? marginTop + marginBottom : 0);
  const xPos = width * xPercent + (ignoreMargins ? 0 : marginLeft) + xPixels;
  const yPos = height * yPercent + (ignoreMargins ? marginTop : 0) + yPixels;

  const combinedClassName = [className, 'rv-xy-plot__axis__title'].filter(Boolean).join(' ');

  return (
    <g transform={`translate(${xPos}, ${yPos})`} className={combinedClassName}>
      <text {...style}>{text}</text>
    </g>
  );
};

TCFChartLabel.requiresSVG = true;
export default TCFChartLabel;
