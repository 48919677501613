import { useState } from 'react';

const usePopupToggler = <T>(initialIsOpen: boolean = false) => {
  const [state, setState] = useState<{ obj?: Partial<T>; isOpen: boolean }>({
    obj: undefined,
    isOpen: initialIsOpen,
  });

  const open = (obj?: Partial<T>) => setState({ obj, isOpen: true });
  const close = () => setState((s) => ({ ...s, isOpen: false }));
  const toggle = () => setState((s) => ({ ...s, isOpen: !s.isOpen }));

  return { state, open, close, toggle };
};

export default usePopupToggler;
