import { AxiosInstance } from 'axios';

import { ApiResponse, Query } from 'tcf-upstream-shared/models';

export class CrudApiClient {
  constructor(readonly axios: AxiosInstance, readonly baseUrl: string) {}

  async search(query: Query): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/search`, query);
  }

  async searchExport(
    query: Query,
    units: string,
    localDatetime: string,
    exportType?: string,
    exportParams?: object,
  ): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/search/export`, {
      query,
      units,
      localDatetime,
      exportType,
      exportParams,
    });
  }

  async searchStats(query: Query): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/search/aggregations`, query);
  }

  async post(data: {}): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(this.baseUrl, data);
  }

  async get(id: number | string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}`);
  }

  async put(id: number | string, data: {}): Promise<ApiResponse> {
    return this.axios.put<ApiResponse>(`${this.baseUrl}/${id}`, data);
  }

  async delete(id: number | string): Promise<ApiResponse> {
    return this.axios.delete<ApiResponse>(`${this.baseUrl}/${id}`);
  }

  async patch(id: number | string, updatedAt: Date | string, patch: {}): Promise<ApiResponse> {
    return this.axios.patch<ApiResponse>(`${this.baseUrl}/${id}`, {
      updatedAt,
      patch,
    });
  }

  async getDownloadToken(id: number | string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}/token`);
  }
}

export class CrudApiClient2 {
  constructor(readonly axios: AxiosInstance) {}

  async search(baseUrl: string, query?: Query): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${baseUrl}/search`, query);
  }

  async searchExport(baseUrl: string, query: Query, units: string, localDatetime: string): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${baseUrl}/search/export`, { query, units, localDatetime });
  }

  async searchStats(baseUrl: string, query?: Query): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${baseUrl}/search/aggregations`, query);
  }

  async post(baseUrl: string, data: {}): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(baseUrl, data);
  }

  async get(baseUrl: string, id: number | string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${baseUrl}/${id}`);
  }

  async getDownloadToken(baseUrl: string, id: number | string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${baseUrl}/${id}/token`);
  }

  async diff(baseUrl: string, id: number | string, oid: number | string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${baseUrl}/diff/${id}/${oid}`);
  }

  async put(baseUrl: string, id: number | string, data: {}): Promise<ApiResponse> {
    return this.axios.put<ApiResponse>(`${baseUrl}/${id}`, data);
  }

  async delete(baseUrl: string, id: number | string): Promise<ApiResponse> {
    return this.axios.delete<ApiResponse>(`${baseUrl}/${id}`);
  }

  async patch(baseUrl: string, id: number | string, updatedAt: Date | string, patch: {}): Promise<ApiResponse> {
    return this.axios.patch<ApiResponse>(`${baseUrl}/${id}`, {
      updatedAt,
      patch,
    });
  }

  async merge(baseUrl: string, body: {}): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(baseUrl, body);
  }

  async upload(baseUrl: string, values: {}): Promise<ApiResponse> {
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      formData.append(key, value as string | Blob);
    }
    return this.axios.post<ApiResponse>(baseUrl, formData);
  }
}
