import { Query } from 'tcf-upstream-shared/models';
import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { READ_SERVER } from './actionTypes';

export function readRegions(storeIdentifier: string, query: Query) {
  query.q = query.q || '*';
  query.skip = query.skip || 0;
  query.limit = query.limit || 25;
  query.filters = query.filters || {};

  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.regions.search(query)).data,
    storeIdentifier,
    requestPayload: { query },
  });
}

export function readRegion(storeIdentifier: string, id: string | number) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.regions.get(id)).data,
    storeIdentifier,
  });
}

export function exportRegions(storeIdentifier: string, query: Query, units: string, localDatetime: string) {
  query.q = query.q || '*';
  query.filters = query.filters || {};

  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.regions.searchExport(query, units, localDatetime)).data,
    storeIdentifier,
    requestPayload: { query },
  });
}
