import { LOGOUT, SET_BREADCRUMBS } from '../actions/actionTypes';
import { Action } from '../actions/Action';

export type BreadcrumbState = { label: string; link?: string }[];

const initialState: BreadcrumbState = [];

export const breadcrumbReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_BREADCRUMBS:
      return action.payload ? [...action.payload] : initialState;

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialState;

    default:
      return state;
  }
};
