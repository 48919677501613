export interface StylesDictionary {
  [Key: string]: any;
}

export const sizes = {
  appHeaderPadding: 99,
  sidebarWidth: 250,
};

export const colors = {
  lightestgrey: '#f7f7f7',
  accordion: '#f7f7f7',
};

export const sharedStyles: StylesDictionary = {
  sidebar: {
    position: 'fixed',
    top: sizes.appHeaderPadding,
    bottom: 0,
    backgroundColor: colors.lightestgrey,
    left: 0,
    width: sizes.sidebarWidth,
    zIndex: 1001,
    display: 'block',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '1rem',
    borderRight: '1px solid #e8e8e8',
  },
  mainContent: {
    paddingLeft: sizes.sidebarWidth,
    paddingTop: '1rem',
  },
};
