import React from 'react';

interface OwnProps {
  color: string;
  height?: string;
  width?: string;
  strokeWidth?: number;
  strokeColor?: string;
  verticalFineTune?: string;
}

const DeclineChartLegendSwatch = (props: OwnProps) => {
  const {
    color,
    height = '1.5em',
    width = '1.5em',
    strokeWidth = 1,
    strokeColor = 'rgb(136,136,136)',
    verticalFineTune = '-0.2em',
  } = props;
  return (
    <svg width={width} height={height} style={{ marginTop: verticalFineTune }}>
      <rect width={width} height={height} style={{ fill: color, strokeWidth, stroke: strokeColor }} />
    </svg>
  );
};

export default DeclineChartLegendSwatch;
