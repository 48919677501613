import { SearchResult } from 'tcf-upstream-shared/models';

import { ADMIN_OPERATOR_INITIALIZE_LOOKUP_CACHE, ADMIN_OPERATOR_ADD_TO_LOOKUP_CACHE, LOGOUT } from '../actions/actionTypes';
import { Action } from '../actions/Action';

export interface AdminOperatorLookupCacheState {
  [id: number]: SearchResult;
}

const initialState: AdminOperatorLookupCacheState = {};

export const adminOperatorLookupCacheReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ADMIN_OPERATOR_INITIALIZE_LOOKUP_CACHE:
      const operators = action?.payload || [];
      const result = {};
      for (const o of operators) {
        result[o.id] = o;
      }
      return result;

    case ADMIN_OPERATOR_ADD_TO_LOOKUP_CACHE:
      const operator = action?.payload as SearchResult | undefined;
      if (operator) {
        const id = operator.id as number;
        return {
          ...state,
          [id]: operator,
        };
      }
      return state;

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialState;

    default:
      return state;
  }
};
