import React from 'react';
import { Badge } from 'reactstrap';

import { UpstreamToken } from 'tcf-upstream-shared/models';

import config from '../../config';
import Header from '../Header';
import Footer from '../Footer';
import Routes from './Routes';
import { StylesDictionary } from '../../theme';

const styles: StylesDictionary = {
  app: {
    // paddingTop: sizes.appHeaderPadding,
    minHeight: '100%',
    minWidth: '960px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflowX: 'auto',
  },
  badge: {
    position: 'fixed',
    top: 5,
    left: 5,
    zIndex: 11,
  },
  page: {
    flex: '1 0 auto',
    boxSizing: 'border-box',
    display: 'flex',
  },
};

interface ContentPageProps {
  authUser?: UpstreamToken;
  authError?: string;
}

const ContentPage = (props: ContentPageProps) => {
  const { authUser, authError } = props;

  const environment = config.TCF_ENV === 'prod' ? 'Beta' : config.TCF_ENV;

  return (
    <div className={'contentPage'}>
      <Header authUser={authUser} />
      <Badge color={'warning'} style={styles.badge}>
        {environment} {authUser?.isTrial ? 'Trial (Limited Data)' : ''} Version
      </Badge>
      <div className={'contentPageMain'}>
        <Routes authUser={authUser} authError={authError} />
      </div>
      <Footer />
    </div>
  );
};

export default ContentPage;

// {/*Save for prod release*/}
// {/*{config.TCF_ENV !== 'prod' && (*/}
// {/*  <Badge color={'warning'} style={styles.badge}>*/}
// {/*    Environment: {config.TCF_ENV}*/}
// {/*  </Badge>*/}
// {/*)}*/}
