import React from 'react';

import { ADMIN_FILE_URL } from 'tcf-upstream-shared/urls';

import { useAppDispatch } from '../hooks';
import { getDownloadToken } from '../libraryApi/apiActions';

export interface OwnProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  file: { id: string; original_file_name?: string; new_file_name?: string };
  children?: React.ReactNode;
}

// Experimented with window.open, but some browsers (looking at you Safari) block that because
// they're considered pop-ups.  This method was successfully used in the Library, so let's see
// if we can make it work here.
//
// Bottom line is that we only want subscribers and/or admins to be able to view files so we need
// a way to secure hyperlinks.  To do that, we're going to pass short lived download tokens
// through URL query params.  That means obtaining a download token and temporarily updating
// the anchor tag with it on click.
const EvidenceLink = (props: OwnProps) => {
  const dispatch = useAppDispatch();

  const { file, children, ...anchorProps } = props;

  const url = `/api${ADMIN_FILE_URL}/${file.id}/file`;
  const fileName = file.original_file_name || file.new_file_name;

  let downloading = false;
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!downloading) {
      downloading = true;
      event.preventDefault();
      const anchor = event.currentTarget as HTMLAnchorElement;
      dispatch(getDownloadToken(ADMIN_FILE_URL, file.id)).then((res: any) => {
        anchor.href = `${url}?dt=${res.downloadToken}`;
        anchor.click();
        anchor.href = url;
        downloading = false;
      });
    }
  };

  return (
    <a {...anchorProps} target="_blank" rel="noopener noreferrer" download={fileName} href={url} onClick={handleClick}>
      {children}
    </a>
  );
};

export default EvidenceLink;
