import { ApiError } from 'tcf-upstream-shared/models';

import { Action } from '../actions/Action';
import { READ_SERVER, RESET_SERVER_STORE, LOGOUT } from '../actions/actionTypes';

export interface ServerStore {
  payload?: any;
  error: string;
  isFetching: boolean;
}
const initialStoreState: ServerStore = {
  error: '',
  isFetching: false,
};

export interface ServerStoresState {
  [storeIdentifier: string]: ServerStore;
}
const initialState: ServerStoresState = {};

export const serverStoresReducer = (state: ServerStoresState = initialState, action: Action) => {
  let updatedStoreState: ServerStore;

  switch (action.type) {
    case READ_SERVER.REQUESTED:
      updatedStoreState = {
        ...state[action.storeIdentifier!],
        isFetching: true,
      };
      break;

    case READ_SERVER.SUCCEEDED:
      updatedStoreState = {
        ...initialStoreState,
        payload: action.payload,
      };
      break;

    case READ_SERVER.FAILED: {
      const error = action.payload as ApiError;
      updatedStoreState = {
        ...initialStoreState,
        error: error?.message ?? 'Not found',
      };
      break;
    }

    case RESET_SERVER_STORE:
      updatedStoreState = {
        ...initialStoreState,
      };
      break;

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialState;

    default:
      return state;
  }

  return { ...state, [action.storeIdentifier!]: updatedStoreState };
};
