import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from 'react-loader';
import { Container, Col, Row, TabContent, TabPane, Card, CardBody, CardTitle, Nav, NavItem, NavLink, Button } from 'reactstrap';

import { ROOT_REGION_ID, ROOT_REGION_NAME } from 'tcf-upstream-shared/constants';
import { Region } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector, useProductionUnits } from '../../hooks';
import { readRegion } from '../../actions/regionActions';
import { setQuery } from '../../actions/queryActions';
import AsyncPage from '../AsyncPage/AsyncPage';
import { paths } from '../../paths';
import ListCount from '../../components/ListCount';
import OperatorList from '../Operator/components/OperatorList';
import RegionProductionList from './components/RegionProductionList';
import RegionProductionChart from './components/RegionProductionChart';
import RegionDeclineChart from './components/RegionDeclineChart';
import WellsFilterForm, { VisibleFilters } from '../Well/components/WellsFilterForm';
import WellList from '../Well/components/WellList';
import TransfersFilterForm from '../Transfer/components/TransfersFilterForm';
import TransferList from '../Transfer/components/TransferList';
import RegionDetails from './components/RegionDetails';
import SubRegionList from './components/SubRegionList';
import RegionMap from './components/RegionMap';
import ErrorComponent from '../AsyncPage/ErrorComponent';
import { FIRST_DECLINE_COLOR, SECOND_DECLINE_COLOR } from '../Production/components/DeclineChart';
import DeclineChartLegendSwatch from '../Production/components/DeclineChartLegendSwatch';

const DEFAULT_PAGE_SIZE = 25;
const REGION_STORE = 'regionDetailsStore';
const REGION_BENCHMARK_STORE = 'regionBenchmarkStore';

const WELLS_STORE = 'regionWellsStore';
const WELLS_QUERY = 'regionWellsQuery';
const WELLS_STATE_FIELD = 'stateAbbrev';
const WELLS_COUNTY_FIELD = 'countyApi';
const WELLS_SORT = [{ 'prodSummary.boeTotal': 'desc' }];

const OPERATORS_STORE = 'regionOperatorsStore';
const OPERATORS_QUERY = 'regionOperatorsQuery';
const OPERATOR_PRODUCING_FIELD = 'isProducing';
const OPERATORS_STATE_SUMMARY_FIELD = 'stateSummary';
const OPERATORS_COUNTY_SUMMARY_FIELD = 'countySummary';
// const OPERATORS_SORT = [{ regionSummary: { field: 'boeTotal', regionId: ROOT_REGION_ID, order: 'desc' } }];

const DEFAULT_OPERATORS_FILTER = { parentId: null };

const STATES_STORE = 'regionDetailsStatesStore';
const STATES_QUERY = 'regionDetailsStatesQuery';

const COUNTIES_STORE = 'regionDetailsCountiesStore';
const COUNTIES_QUERY = 'regionDetailsCountiesQuery';

const TRANSFERS_STORE = 'regionTransfersStore';
const TRANSFERS_QUERY = 'regionTransfersQuery';
const TRANSFERS_STATE_FIELD = 'stateAbbrev';
const TRANSFERS_COUNTY_FIELD = 'countyApi';
const TRANSFERS_SORT = [{ transferDate: 'desc' }];

interface ParamTypes {
  id: string;
}

const ViewRegion = () => {
  const dispatch = useAppDispatch();

  const [delayRender, setDelayRender] = useState(true);
  const [activeTab, setActiveTab] = useState('Summary');
  const [activeSummary, setActiveSummary] = useState('Map');
  const { id: urlId } = useParams<ParamTypes>();

  const isFetching = useAppSelector((state) => state.serverStores?.[REGION_STORE]?.isFetching);
  const error = useAppSelector((state) => state.serverStores?.[REGION_STORE]?.error);
  const region: Region = useAppSelector((state) => state.serverStores?.[REGION_STORE]?.payload);

  const regionId = String(region?.id || '');
  const regionLevel = region?.level;
  const stateAbbrev = region?.stateAbbrev;
  const apiCode = region?.apiCode;

  const { title: productionUnitsTitle } = useProductionUnits();

  const initVisibleFilters: VisibleFilters = {
    location: false,
    wellType: true,
    config: true,
    status: false,
    producing: false,
  };
  const [visibleFilters, setVisibleFilters] = useState(initVisibleFilters);

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveTab('Summary');
    setActiveSummary('Map');
  }, [urlId]);

  useEffect(() => {
    if (regionId !== urlId) {
      dispatch(readRegion(REGION_STORE, urlId));
      if (urlId !== String(ROOT_REGION_ID)) {
        dispatch(readRegion(REGION_BENCHMARK_STORE, ROOT_REGION_ID));
      }
    }
  }, [urlId, regionId, dispatch]);

  useEffect(() => {
    if (regionId === urlId) {
      const operatorFilters = { ...DEFAULT_OPERATORS_FILTER };
      const wellFilters = {};
      const transferFilters = {};
      let operatorSort;
      if (regionLevel === 1) {
        operatorFilters[OPERATOR_PRODUCING_FIELD] = true;
        operatorSort = [{ regionSummary: { field: 'boeTotal', regionId: parseInt(regionId, 10), order: 'desc' } }];
      } else if (regionLevel === 2) {
        operatorFilters[OPERATORS_STATE_SUMMARY_FIELD] = { regionId, producingOnly: 1 };
        wellFilters[WELLS_STATE_FIELD] = [stateAbbrev];
        transferFilters[TRANSFERS_STATE_FIELD] = [stateAbbrev];
        operatorSort = [{ stateSummary: { field: 'boeTotal', regionId: parseInt(regionId, 10), order: 'desc' } }];
      } else if (regionLevel === 3) {
        operatorFilters[OPERATORS_COUNTY_SUMMARY_FIELD] = { regionId, producingOnly: 1 };
        wellFilters[WELLS_COUNTY_FIELD] = apiCode;
        transferFilters[TRANSFERS_COUNTY_FIELD] = apiCode;
        operatorSort = [{ countySummary: { field: 'boeTotal', regionId: parseInt(regionId, 10), order: 'desc' } }];
      }
      dispatch(setQuery(OPERATORS_QUERY, { sort: operatorSort, filters: operatorFilters }));
      dispatch(setQuery(WELLS_QUERY, { limit: DEFAULT_PAGE_SIZE, sort: WELLS_SORT, filters: wellFilters }));
      dispatch(setQuery(TRANSFERS_QUERY, { sort: TRANSFERS_SORT, filters: transferFilters }));
    }
  }, [urlId, regionId, apiCode, stateAbbrev, regionLevel, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error || regionId !== urlId) {
    return <ErrorComponent error={error} />;
  } else if (!region) {
    return null;
  }

  const resetWellsQuery = () => {
    const wellFilters = {};
    if (regionLevel === 2) {
      wellFilters[WELLS_STATE_FIELD] = [stateAbbrev];
    } else if (regionLevel === 3) {
      wellFilters[WELLS_COUNTY_FIELD] = apiCode;
    }
    dispatch(setQuery(WELLS_QUERY, { sort: WELLS_SORT, filters: wellFilters }));
  };

  const resetTransfersQuery = () => {
    const transferFilters = {};
    if (regionLevel === 2) {
      transferFilters[TRANSFERS_STATE_FIELD] = [stateAbbrev];
    } else if (regionLevel === 3) {
      transferFilters[TRANSFERS_COUNTY_FIELD] = apiCode;
    }
    dispatch(setQuery(TRANSFERS_QUERY, { sort: TRANSFERS_SORT, filters: transferFilters }));
  };

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onVisibleFilterToggle = (id: string) => {
    const newVisibleFilters = { ...visibleFilters };
    newVisibleFilters[id] = !visibleFilters[id];
    setVisibleFilters(newVisibleFilters);
  };

  const hdrStyle = { display: 'inline-block', marginBottom: '0.5rem', marginRight: '0.5rem', marginLeft: '0.5rem' };
  const dataDisclaimer = `Note: production and well counts are complete through ${region.latestFullProductionYear}. Production totals and well counts beyond ${region.latestFullProductionYear} may not be indicative of final values.`;

  return (
    <main className={'mainContent'}>
      <AsyncPage loading={isFetching} error={error}>
        <Container fluid={true}>
          Regions&nbsp;&gt;&nbsp;
          {region.level > 1 ? (
            <>
              <Link to={paths.VIEW_REGION.replace(':id', String(ROOT_REGION_ID))} title={`View ${ROOT_REGION_NAME} region`}>
                {ROOT_REGION_NAME}
              </Link>
              &nbsp;&gt;&nbsp;
            </>
          ) : null}
          {region.level > 2 ? (
            <>
              <Link to={paths.VIEW_REGION.replace(':id', urlId.slice(0, 2))} title={'View state region'}>
                {region.stateAbbrev}
              </Link>
              &nbsp;&gt;
            </>
          ) : null}
          <h2 style={hdrStyle}>{regionLevel === 1 ? ROOT_REGION_NAME : region.name}</h2>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'Summary' ? 'active' : ''}
                onClick={() => toggle('Summary')}
                title={'View region summary'}
              >
                Summary
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'Production' ? 'active' : ''}
                onClick={() => toggle('Production')}
                title={'View region production table'}
              >
                Production
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'Decline' ? 'active' : ''}
                onClick={() => toggle('Decline')}
                title={'View decline chart for region'}
              >
                Decline
              </NavLink>
            </NavItem>
            {region.level > 1 && region.boeTotal ? (
              <>
                <NavItem>
                  <NavLink
                    className={activeTab === 'Operators' ? 'active' : ''}
                    onClick={() => toggle('Operators')}
                    title={'View operators in this region'}
                  >
                    Operators
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'Wells' ? 'active' : ''}
                    onClick={() => toggle('Wells')}
                    title={'View wells in this region'}
                  >
                    Wells
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'Transfers' ? 'active' : ''}
                    onClick={() => toggle('Transfers')}
                    title={'View well transfers in this region'}
                  >
                    Transfers
                  </NavLink>
                </NavItem>
              </>
            ) : null}
          </Nav>
          <div className={'mt-4'}>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={'Summary'}>
                <Row>
                  <Col sm={6} md={5} lg={4} xl={3} className={'mb-4'}>
                    <RegionDetails storeIdentifier={REGION_STORE} />
                    {region?.level < 3 ? (
                      <Nav vertical className={'mt-4'}>
                        <NavItem>
                          <Button
                            outline
                            block
                            color={activeSummary === 'Map' ? 'primary' : 'secondary'}
                            onClick={() => setActiveSummary('Map')}
                          >
                            Display Map
                          </Button>
                        </NavItem>
                        {region?.level === 1 ? (
                          <NavItem>
                            <Button
                              outline
                              block
                              color={activeSummary === 'States' ? 'primary' : 'secondary'}
                              onClick={() => setActiveSummary('States')}
                            >
                              List States
                            </Button>
                          </NavItem>
                        ) : null}
                        {region?.level < 3 ? (
                          <NavItem>
                            <Button
                              block
                              outline
                              color={activeSummary === 'Counties' ? 'primary' : 'secondary'}
                              onClick={() => setActiveSummary('Counties')}
                            >
                              List Counties
                            </Button>
                          </NavItem>
                        ) : null}
                      </Nav>
                    ) : null}
                  </Col>

                  <Col sm={6} md={7} lg={8} xl={9} className={'mb-4'}>
                    <div className={'mb-4'}>
                      {activeSummary === 'Map' ? (
                        <RegionMap storeIdentifier={REGION_STORE} queryIdentifier={WELLS_QUERY} />
                      ) : activeSummary === 'States' ? (
                        <SubRegionList
                          regionStoreIdentifier={REGION_STORE}
                          subRegionsStoreIdentifier={STATES_STORE}
                          subRegionsQueryIdentifier={STATES_QUERY}
                          subRegionLevel={2}
                        />
                      ) : (
                        <SubRegionList
                          regionStoreIdentifier={REGION_STORE}
                          subRegionsStoreIdentifier={COUNTIES_STORE}
                          subRegionsQueryIdentifier={COUNTIES_QUERY}
                          subRegionLevel={3}
                        />
                      )}
                    </div>
                    <div className={'mb-2 font-italic'}>{dataDisclaimer}</div>
                    <div className={'mb-4'}>
                      <Card>
                        <CardBody>
                          <CardTitle>Total Production ({productionUnitsTitle})</CardTitle>
                          <RegionProductionChart
                            storeIdentifier={REGION_STORE}
                            minYear={2010}
                            lastFullYear={region.latestFullProductionYear}
                            h={400}
                            dataField={'boeTotal'}
                            yTitle={`Production (${productionUnitsTitle})`}
                            color={'lightblue'}
                          />
                        </CardBody>
                      </Card>
                    </div>
                    <div>
                      <Card>
                        <CardBody>
                          <CardTitle>Producing Wells</CardTitle>
                          <RegionProductionChart
                            storeIdentifier={REGION_STORE}
                            minYear={2010}
                            lastFullYear={region.latestFullProductionYear}
                            h={400}
                            dataField={'wellCount'}
                            yTitle={'Wells'}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={'Operators'}>
                <OperatorList
                  storeIdentifier={OPERATORS_STORE}
                  queryIdentifier={OPERATORS_QUERY}
                  regionId={region.id}
                  showDecline={true}
                />
              </TabPane>
              <TabPane tabId={'Wells'}>
                <div style={{ display: 'flex', border: '1px solid #e8e8e8' }}>
                  <div
                    style={{
                      borderRight: '1px solid #e8e8e8',
                      padding: '1rem',
                      backgroundColor: '#f7f7f7',
                      width: '300px',
                      minWidth: '200px',
                    }}
                  >
                    <WellsFilterForm
                      storeIdentifier={WELLS_STORE}
                      queryIdentifier={WELLS_QUERY}
                      visibleFilters={visibleFilters}
                      onVisibleFilterToggle={onVisibleFilterToggle}
                      resetQueryFilter={resetWellsQuery}
                    />
                  </div>
                  <div style={{ padding: '1rem', flexGrow: 1, width: '100%' }}>
                    <ListCount storeIdentifier={WELLS_STORE} label={'Well'} />
                    <WellList
                      storeIdentifier={WELLS_STORE}
                      queryIdentifier={WELLS_QUERY}
                      hideFields={
                        regionLevel === 3 ? ['countyName', 'stateAbbrev'] : regionLevel === 2 ? ['stateAbbrev'] : undefined
                      }
                    />
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={'Transfers'}>
                <div style={{ display: 'flex', border: '1px solid #e8e8e8' }}>
                  <div
                    style={{
                      borderRight: '1px solid #e8e8e8',
                      padding: '1rem',
                      backgroundColor: '#f7f7f7',
                      width: '300px',
                      minWidth: '200px',
                    }}
                  >
                    <TransfersFilterForm
                      storeIdentifier={TRANSFERS_STORE}
                      queryIdentifier={TRANSFERS_QUERY}
                      visibleFilters={visibleFilters}
                      onVisibleFilterToggle={onVisibleFilterToggle}
                      resetQueryFilter={resetTransfersQuery}
                      operatorFilterType={'byName'}
                    />
                  </div>
                  <div style={{ padding: '1rem', flexGrow: 1, width: '100%' }}>
                    <TransferList
                      storeIdentifier={TRANSFERS_STORE}
                      queryIdentifier={TRANSFERS_QUERY}
                      hideFields={
                        regionLevel === 3 ? ['countyName', 'stateAbbrev'] : regionLevel === 2 ? ['stateAbbrev'] : undefined
                      }
                    />
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={'Production'}>
                <RegionProductionList storeIdentifier={REGION_STORE} disclaimer={dataDisclaimer} />
              </TabPane>
              <TabPane tabId={'Decline'}>
                <Row>
                  <Col sm={12}>
                    <div className={'my-2 font-italic'}>{dataDisclaimer}</div>
                  </Col>
                  <Col md={12} className={'mb-2'}>
                    <RegionDeclineChart
                      storeIdentifier={REGION_STORE}
                      benchmarkStoreIdentifier={regionLevel! > 1 ? REGION_BENCHMARK_STORE : undefined}
                      label={regionLevel === 1 ? 'Region' : regionLevel === 2 ? 'State' : 'County'}
                      title={
                        <>
                          YoY Pct Change, <DeclineChartLegendSwatch color={FIRST_DECLINE_COLOR} />{' '}
                          {regionLevel === 1 ? null : regionLevel === 2 ? 'State vs' : 'County vs'}{' '}
                          {regionLevel === 1 ? null : <DeclineChartLegendSwatch color={SECOND_DECLINE_COLOR} />}{' '}
                          {ROOT_REGION_NAME}
                        </>
                      }
                      minYear={2010}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </AsyncPage>
    </main>
  );
};

export default ViewRegion;
