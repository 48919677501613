import React from 'react';

const Sidebar = (props: any) => {
  return (
    <div className={'sidebarContainer'}>
      <div className={'sidebarContents'}>{props.children}</div>
    </div>
  );
};

export default Sidebar;
