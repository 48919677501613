import React from 'react';

import { useAppSelector } from '../../../hooks';
import { WellLookup } from '../../../reducers/wellLookupsReducer';
import Definition from '../../../components/Definition';

interface WellCodeDescriptionProps {
  code?: string;
  lookupType: string;
}

const WellCodeDescription = (props: WellCodeDescriptionProps) => {
  const { code, lookupType } = props;
  const wellLookupValues: WellLookup = useAppSelector((state) => state.wellLookups?.[lookupType + 'Values']);

  if (!code) return null;

  const lookupValue = wellLookupValues?.[code];
  const description = lookupValue?.description || code;
  const helpText = lookupValue?.helpText;

  return <Definition label={description}>{helpText || 'No further description available'}</Definition>;
};

export default WellCodeDescription;
