import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import { SUPPORTED_STATES } from 'tcf-upstream-shared/constants';

import { useAppDispatch, useAppSelector } from '../hooks';
import { upsertQueryFilter, removeQueryFilter } from '../actions/queryActions';
import Definition from './Definition';

export interface StatesFilterProps {
  queryIdentifier: string;
  filterField: string; // stateAbbrev
  values?: { key: string; key_as_string: string; doc_count: number }[];
  statesHelpText?: { [key: string]: string };
}

const StatesFilter = (props: StatesFilterProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier, filterField, values, statesHelpText } = props;
  const selectedStates = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.[filterField]);

  const onStatesChanged = (event: any) => {
    const { value: state, checked } = event.target;
    let nextSelectedStates;
    if (checked) {
      nextSelectedStates = [...(selectedStates || [])];
      nextSelectedStates.push(state);
    } else {
      nextSelectedStates = (selectedStates || []).filter((w: any) => w !== state);
    }
    if (nextSelectedStates && nextSelectedStates.length > 0) {
      dispatch(upsertQueryFilter(queryIdentifier, filterField, nextSelectedStates));
    } else {
      dispatch(removeQueryFilter(queryIdentifier, filterField));
    }
  };

  return (
    <>
      {SUPPORTED_STATES.map((state) => {
        const doc_count = values?.find((v) => v.key === state.code)?.doc_count;
        return (
          <FormGroup check key={state.code}>
            <Label check>
              <Input
                type="checkbox"
                value={state.code}
                onChange={onStatesChanged}
                checked={(selectedStates || []).includes(state.code)}
              />
              &nbsp;
              {statesHelpText?.[state.code] ? (
                <Definition label={state.name}>{statesHelpText[state.code]}</Definition>
              ) : (
                state.name
              )}
              <span className={'text-muted'}> ({doc_count ? doc_count.toLocaleString() : 0})</span>
            </Label>
          </FormGroup>
        );
      })}
    </>
  );
};

export default StatesFilter;
