import { library } from '../libraryApi';
import { accessToken } from '../utils/Tokens';
import { LOGIN, LOGOUT, SEND_LOGIN_EMAIL } from './actionTypes';
import { asyncAction } from './asyncAction';
import { toast } from './toastActions';

export const sendLoginEmail = (payload: { email: string; redirectTo?: string }) => {
  return asyncAction({
    actionType: SEND_LOGIN_EMAIL,
    requestPayload: payload,
    func: async () => {
      accessToken.delete();
      return (await library.auth.sendLoginEmail(payload)).data;
    },
    onSucceeded: async (result, dispatch) => {
      return dispatch(
        toast.showSucceeded({
          message:
            'Check your email for a message you can use to log in. ' +
            'Or email editorial@thecapitolforum.com if you are having difficulties.',
        }),
      );
    },
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          message:
            'We were not able to send a login email. If you are a subscriber and believe this is an error, please ' +
            ' email editorial@thecapitolforum.com for help.',
        }),
      ),
  });
};

export const login = (token: string, showFailure = true) => {
  return asyncAction({
    actionType: LOGIN,
    func: async () => {
      accessToken.delete();
      return (await library.auth.login(token)).data;
    },
    onFailed: async (result, dispatch, getState) => {
      if (showFailure) {
        const authUser = getState().auth.authUser;
        dispatch(
          toast.showFailed({
            title: 'Login failed.',
            message:
              'Login links expire after 10 minutes, can only be used once, and ' +
              'only the most recent one will work. ' +
              (authUser ? 'You are already logged in as ' + authUser.email + '. ' : 'Please request a new login link. ') +
              'Email editorial@thecapitolforum.com if you are having difficulties.',
          }),
        );
      }
    },
  });
};

export const logout = () => {
  return asyncAction({
    actionType: LOGOUT,
    func: async () => {
      try {
        await library.auth.logout();
      } finally {
        accessToken.delete();
      }
    },
  });
};
