import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { Link } from 'react-router-dom';
import { Card, CardTitle, CardBody } from 'reactstrap';

import { SearchResults, Transfer } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { readTransfers } from '../../../actions/transferActions';
import { paths } from '../../../paths';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import TcfBootstrapTable from '../../../components/TcfBootstrapTable';

interface TransferLocalListProps {
  storeIdentifier: string;
  queryString: string;
}

const WellTransferList = (props: TransferLocalListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true);
  const { storeIdentifier, queryString } = props;
  const payload: SearchResults = useAppSelector((state) => state.serverStores?.[props.storeIdentifier]?.payload);
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[props.storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[props.storeIdentifier]?.isFetching);

  useEffect(() => {
    const _query = JSON.parse(queryString);
    dispatch(readTransfers(storeIdentifier, _query));
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!payload?.total || payload.total < 1) {
    return null;
  }

  const formatFromOperatorName = (cell: string, row: Transfer) =>
    cell && cell.length > 0 ? (
      <Link to={paths.VIEW_OPERATOR.replace(':id', row?.fromOperatorId?.toString() || '')} title={'View operator'}>
        {cell}
      </Link>
    ) : (
      ''
    );

  const formatToOperatorName = (cell: string, row: Transfer) =>
    cell && cell.length > 0 ? (
      <Link to={paths.VIEW_OPERATOR.replace(':id', row?.toOperatorId?.toString() || '')} title={'View operator'}>
        {cell}
      </Link>
    ) : (
      ''
    );

  const dataTransfer = (payload?.results || []).map((o) => ({
    ...o,
  }));

  const columns = [
    {
      dataField: 'transferDate',
      text: 'Transfer Date',
      sort: true,
    },
    {
      dataField: 'fromOperatorName',
      text: 'From Operator',
      formatter: formatFromOperatorName,
      sort: true,
    },
    {
      dataField: 'toOperatorName',
      text: 'To Operator',
      formatter: formatToOperatorName,
      sort: true,
    },
  ];

  const totalSize = payload?.total || 0;

  return (
    <Card className={'my-4'}>
      <CardBody>
        <CardTitle>Transfers</CardTitle>
        <TcfBootstrapTable allowWrap columns={columns} dataSource={dataTransfer} totalSize={totalSize} />
      </CardBody>
    </Card>
  );
};

export default WellTransferList;
