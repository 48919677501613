import React from 'react';
import { FormGroup, Label, Input, Form } from 'reactstrap';

import { useAppSelector, useAppDispatch } from '../../../hooks';
import { upsertQueryFilter } from '../../../actions/queryActions';

const fromOperatorField = 'fromOperatorName.text';
const toOperatorField = 'toOperatorName.text';

export interface FromToOperatorNameFilterProps {
  queryIdentifier: string;
}

const FromToOperatorNameFilter = (props: FromToOperatorNameFilterProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier } = props;
  const operatorFilter = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.prefixKeywords);
  const operatorFilterFields: string[] = operatorFilter?.fields || [];

  if (operatorFilterFields.length < 1) {
    operatorFilterFields.push(fromOperatorField);
    operatorFilterFields.push(toOperatorField);
  }

  const changeOperatorFilterFields = (e: any) => {
    const _newFilterFields = JSON.parse(e.currentTarget.value);
    dispatch(
      upsertQueryFilter(queryIdentifier, 'prefixKeywords', {
        keywords: operatorFilter?.keywords?.length ? operatorFilter.keywords : '',
        fields: _newFilterFields,
      }),
    );
  };

  return (
    <Form inline>
      <FormGroup>
        <Input
          type="radio"
          id="fromName"
          name="useName"
          value={JSON.stringify([fromOperatorField])}
          checked={JSON.stringify(operatorFilterFields) === JSON.stringify([fromOperatorField])}
          onChange={changeOperatorFilterFields}
        />
        <Label for="fromName">From&nbsp;&nbsp;&nbsp;</Label>
      </FormGroup>
      <FormGroup>
        <Input
          type="radio"
          id="toName"
          name="useName"
          value={JSON.stringify([toOperatorField])}
          checked={JSON.stringify(operatorFilterFields) === JSON.stringify([toOperatorField])}
          onChange={changeOperatorFilterFields}
        />
        <Label for="toName">To&nbsp;&nbsp;&nbsp;</Label>
      </FormGroup>
      <FormGroup>
        <Input
          type="radio"
          id="bothNames"
          name="useName"
          value={JSON.stringify([fromOperatorField, toOperatorField])}
          checked={JSON.stringify(operatorFilterFields) === JSON.stringify([fromOperatorField, toOperatorField])}
          onChange={changeOperatorFilterFields}
        />
        <Label for="bothNames">Both</Label>
      </FormGroup>
    </Form>
  );
};

export default FromToOperatorNameFilter;
