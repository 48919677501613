import React, { useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';
import Loader from 'react-loader';
import socketio from 'socket.io-client';

import 'react-widgets/styles.css';

import { ADMIN_OPERATOR_EVENT_TYPE_URL, WEBSOCKET_URL } from 'tcf-upstream-shared/urls';
import { UpstreamToken } from 'tcf-upstream-shared/models';
import idUtil from 'tcf-upstream-shared/idUtil';

import { ADMIN_OPERATOR_EVENT_TYPE_STORE_ID } from '../../storeIds';
import { leavePage, visitPage } from '../../actions/usageActions';
import { readAdminObjects } from '../../actions/adminObjectsActions';
import { readWellLookups } from '../../actions/wellActions';
import { updateMaintenanceWindow } from '../../libraryApi/apiActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import AdminPage from './AdminPage';
import ContentPage from './ContentPage';
import MapContext from './MapContext';

import './App.css';

const GOOGLE_MAPS_API_KEY = 'AIzaSyCBy-4Z39DsPtHyBGdKHf5RyAW1J3nYGjY';
const socket = socketio({ path: WEBSOCKET_URL });

const App = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const authUser: UpstreamToken | undefined = useAppSelector((state) => state.auth.authUser);
  const authError: string | undefined = useAppSelector((state) => state.auth.error);
  const isFetching: boolean = useAppSelector(
    (state) =>
      state.auth.isLoggingIn ||
      state.wellLookups.isFetching ||
      state.adminObjects[ADMIN_OPERATOR_EVENT_TYPE_STORE_ID]?.isFetching,
  );

  const { isLoaded } = useLoadScript({ googleMapsApiKey: GOOGLE_MAPS_API_KEY });
  const isLoggedIn = !!authUser;

  useEffect(() => {
    dispatch(readWellLookups());
    dispatch(readAdminObjects(ADMIN_OPERATOR_EVENT_TYPE_URL, ADMIN_OPERATOR_EVENT_TYPE_STORE_ID));
  }, [dispatch]);

  useEffect(() => {
    socket.on('NextMaintenanceBegins', (nextMaintenance) => {
      dispatch(updateMaintenanceWindow(nextMaintenance && new Date(nextMaintenance)));
    });
    return () => {
      socket.off('NextMaintenanceBegins');
    };
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      const visitId = idUtil.generateBase32Id();
      const handleBeforeUnload = () => {
        if (navigator.sendBeacon && document.visibilityState === 'hidden') {
          navigator.sendBeacon(`/api/usage/${visitId}`);
        }
      };
      dispatch(visitPage(visitId, pathname));
      window.addEventListener('visibilitychange', handleBeforeUnload);
      return () => {
        window.removeEventListener('visibilitychange', handleBeforeUnload);
        dispatch(leavePage(visitId, pathname));
      };
    }
  }, [dispatch, pathname, isLoggedIn]);

  const PageWrapper = pathname.includes('/admin/') ? AdminPage : ContentPage;

  return (
    <Loader loaded={!isFetching}>
      <MapContext.Provider value={isLoaded}>
        <PageWrapper authUser={authUser} authError={authError} />
      </MapContext.Provider>
    </Loader>
  );
};

export default App;
