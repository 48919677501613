import React from 'react';
import { Card, Collapse, CardBody, CardHeader } from 'reactstrap';
import { FaAngleRight, FaAngleUp } from 'react-icons/fa';

import { colors } from '../theme';

export interface AccordionProps {
  children: any;
  isOpen?: boolean;
  title: string;
  backgroundColor?: any;
  id: string;
  handleToggle: any;
}

const Accordion = (props: AccordionProps) => {
  const { children, isOpen, title, backgroundColor, id, handleToggle } = props;
  const finalColor = backgroundColor || colors.accordion;

  return (
    <Card className={'border-0'} style={{ backgroundColor: finalColor }}>
      <CardHeader
        className={'p-0 border-0 accordion'}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleToggle(id);
        }}
      >
        <div>
          <span className={'float-right'}>{isOpen ? <FaAngleUp /> : <FaAngleRight />}</span>
          {title}
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody className={'p-0 mt-2'} style={{ backgroundColor: finalColor }}>
          {children}
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default Accordion;
