import React from 'react';
import { Badge } from 'reactstrap';

import { UpstreamToken } from 'tcf-upstream-shared/models';

import config from '../../config';
import HeaderAdmin from '../HeaderAdmin';
import Routes from './Routes';
import { StylesDictionary, sizes } from '../../theme';

const styles: StylesDictionary = {
  app: {
    paddingTop: sizes.appHeaderPadding,
  },
  badge: {
    position: 'fixed',
    top: 5,
    left: 5,
    zIndex: 11,
  },
  content: {
    height: '100%',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
};

interface AdminPageProps {
  authUser?: UpstreamToken;
  authError?: string;
}

const AdminPage = (props: AdminPageProps) => {
  const { authUser, authError } = props;

  return (
    <div className={'contentPage adminPage'}>
      <HeaderAdmin authUser={authUser} />
      {config.TCF_ENV !== 'prod' && (
        <Badge color={'warning'} style={styles.badge}>
          Environment: {config.TCF_ENV}
        </Badge>
      )}
      {
        // This div matches the padding of navbar in Header.
        // When navbar contains a fluid container, the container's
        // left/right padding is removed under 768px, so it isn't
        // doubled. App.css has the same media query for content-container.
        // Note, only pass authErrors to Routes - used to redirect to login
      }
      <div className={'contentPageMain'} style={{ fontSize: '0.9rem' }}>
        <Routes authUser={authUser} authError={authError} />
      </div>
    </div>
  );
};

export default AdminPage;
