import React from 'react';

import NotFoundError from '../../components/NotFoundError';

export interface OwnProps {
  error?: string;
}

const ErrorComponent = (props: OwnProps) => {
  const { error } = props;

  const render = () => {
    if (error) {
      const errorLower = error.toLowerCase();
      switch (errorLower) {
        case 'not found':
          return <NotFoundError />;
        case 'network error':
          return <h3>Network service not responding. Please try again later.</h3>;
        case 'missing permission':
          return (
            <div>
              <h3>Please login with credentials to view this page.</h3>
              <p>If you believe this is an error, please contact editorial@thecapitolforum.com for help.</p>
            </div>
          );
        default:
          return errorLower.startsWith('timeout') ? (
            <h3>Request timed out.</h3>
          ) : (
            <>
              <h3>Unknown error</h3>
              <p>Please contact editorial@thecapitolforum.com for assistance</p>
            </>
          );
      }
    } else {
      return <></>;
    }
  };

  return render();
};

export default ErrorComponent;
