import { Query } from 'tcf-upstream-shared/models';
import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { AppState } from '../reducers';
import { GET_DOWNLOAD_TOKEN, READ_SERVER } from './actionTypes';

export function readReports(storeIdentifier: string, query: Query) {
  query.q = query.q || '*';
  query.skip = query.skip || 0;
  query.limit = query.limit || 25;
  query.filters = query.filters || {};

  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.reports.search(query)).data,
    storeIdentifier,
    requestPayload: { query },
  });
}

export function getDownloadToken(id: string) {
  return asyncAction<AppState>({
    actionType: GET_DOWNLOAD_TOKEN,
    func: async () => (await library.reports.getDownloadToken(id)).data,
  });
}
