import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { Card, CardTitle, CardBody } from 'reactstrap';

import { SearchResults } from 'tcf-upstream-shared/models';

import { useAppDispatch, useAppSelector, useProductionUnits } from '../../../hooks';
import { readOperators } from '../../../actions/operatorActions';
import TcfBootstrapTable, { formatNumber, formatStates, formatOperatorName } from '../../../components/TcfBootstrapTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

interface LocalOperatorListProps {
  storeIdentifier: string;
  queryString: string;
  label?: string;
  // hideFields?: ('name' | 'stateAbbrev' | 'wellCount' | 'producingWellCount' | 'wellStates')[];
  hideFields?: string[];
  hideCount?: boolean;
  children?: any;
}

const LocalOperatorList = (props: LocalOperatorListProps) => {
  const dispatch = useAppDispatch();
  const [delayRender, setDelayRender] = useState(true);
  const { storeIdentifier, queryString, label, hideCount } = props;
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const payload: SearchResults = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const hideFields = props.hideFields || [];

  const { title: productionUnitsTitle, convertToSelectedUnits } = useProductionUnits();

  useEffect(() => {
    const _query = JSON.parse(queryString);
    dispatch(readOperators(storeIdentifier, _query));
  }, [queryString, storeIdentifier, dispatch]);

  useEffect(() => {
    setDelayRender(false);
  }, []);

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!payload?.total || payload.total < 1) {
    return null;
  }

  const operators = (payload?.results || []).map((o: any) => ({
    ...o,
    ...{ ...o.regionSummary?.[0], boeTotal: convertToSelectedUnits(o.regionSummary?.[0]?.boeTotal) },
  }));

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: formatOperatorName,
      sort: false,
      hidden: hideFields.includes('name'),
    },
    {
      dataField: 'boeTotal',
      text: `Total ${productionUnitsTitle}`,
      sort: false,
      align: 'right',
      formatter: formatNumber,
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'producingWellCount',
      text: 'Producing Wells',
      sort: false,
      hidden: hideFields.includes('producingWellCount'),
      align: 'right',
      formatter: formatNumber,
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'wellCount',
      text: 'All Wells',
      sort: false,
      hidden: hideFields.includes('wellCount'),
      align: 'right',
      formatter: formatNumber,
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'wellStates',
      text: 'States',
      sort: false,
      hidden: hideFields.includes('wellStates'),
      formatter: formatStates,
    },
  ];

  const totalSize = payload.total || 0;

  return (
    <Card className={'mb-4'}>
      <CardBody>
        {label ? (
          <CardTitle>
            {label}: {hideCount ? null : totalSize.toLocaleString()}
          </CardTitle>
        ) : null}
        <TcfBootstrapTable allowWrap columns={columns} dataSource={operators} totalSize={totalSize} />
        {props.children}
      </CardBody>
    </Card>
  );
};

export default LocalOperatorList;
