// Questionable whether we should have a general component doing direct updates to a query, or whether
// changes to the filter should be sent via callback to parent component.  The calling component knows
// best how a keyword change should impact the query, but it would mean duplicating code in several parents.
import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { FormGroup, Input, Label } from 'reactstrap';

import { useAppDispatch, useAppSelector } from '../hooks';
import { upsertQueryFilter, removeQueryFilter } from '../actions/queryActions';

interface KeywordFilterProps {
  queryIdentifier: string;
  title: string; // 'Name"
  searchFields: string[];
}

const KeywordFilter = (props: KeywordFilterProps) => {
  const dispatch = useAppDispatch();
  const { queryIdentifier, title, searchFields } = props;
  const keywords: string = useAppSelector((state) => state.queries?.[queryIdentifier]?.filters?.prefixKeywords?.keywords ?? '');
  const [localKeywords, setLocalKeywords] = useState(keywords);

  const updateKeywordsFilter = useCallback(
    (_keywords: string, _searchFields: string[]) => {
      _keywords
        ? dispatch(upsertQueryFilter(queryIdentifier, 'prefixKeywords', { keywords: _keywords, fields: _searchFields }))
        : dispatch(removeQueryFilter(queryIdentifier, 'prefixKeywords'));
    },
    [dispatch, queryIdentifier],
  );

  // This is just to catch the external clearing of the filter box.
  useEffect(() => {
    if (!keywords) {
      setLocalKeywords('');
    }
  }, [keywords]);

  const searchFieldsString = JSON.stringify(searchFields);
  useEffect(() => {
    const _searchFields = JSON.parse(searchFieldsString);
    updateKeywordsFilter(keywords, _searchFields);
  }, [updateKeywordsFilter, searchFieldsString, keywords]);

  const debounceKeywords = useCallback(
    debounce((newKeywords: string) => updateKeywordsFilter(newKeywords, searchFields), 700),
    [],
  );

  const onKeywordsChange = (event: any) => {
    const newKeywords = event?.target?.value;
    setLocalKeywords(newKeywords);
    debounceKeywords(newKeywords);
  };

  return (
    <>
      <Label>{title}: </Label>
      <FormGroup>
        <Input type="text" name="keywordsFilter" id="keywordsFilter" value={localKeywords} onChange={onKeywordsChange} />
      </FormGroup>
    </>
  );
};

export default KeywordFilter;
