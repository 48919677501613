export interface AsyncActionType {
  REQUESTED: string;
  SUCCEEDED: string;
  FAILED: string;
}

export const getAsyncActionTypes = (baseType: string) => {
  const type: AsyncActionType = {
    REQUESTED: baseType + '_REQUESTED',
    SUCCEEDED: baseType + '_SUCCEEDED',
    FAILED: baseType + '_FAILED',
  };
  return type;
};
