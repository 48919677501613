import React, { useState } from 'react';
import Loader from 'react-loader';
import { Card, CardBody, Form, FormGroup, Input } from 'reactstrap';

import { Production } from 'tcf-upstream-shared/models';

import { useAppSelector, useProductionUnits } from '../../../hooks';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import ProductionList, { ProductionListType } from '../../Production/components/ProductionList';

interface WellProductionListProps {
  storeIdentifier: string;
}

const WellProductionList = (props: WellProductionListProps) => {
  const [listType, setListType] = useState('summary');
  const { storeIdentifier } = props;
  const error = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const payload = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);

  const { title: productionUnitsTitle, convertToSelectedUnits } = useProductionUnits();

  if (isFetching) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!payload) {
    return null;
  }

  const handleListTypeChange = (event: any) => setListType(event.target.value);

  const productionList: any[] =
    payload?.results
      ?.sort((a: Production, b: Production) => b.year - a.year)
      ?.map((p: Production, index: number) => {
        const row = Object.keys(p).reduce((prev: any, k: string) => {
          const prefix: string = k.slice(0, 3);
          const lowerK = k.toLowerCase();
          if (lowerK.includes('month') || lowerK.includes('pct')) {
            prev[k] = p[k];
          } else if (['oil', 'ngl', 'boe'].includes(prefix)) {
            prev[k] = convertToSelectedUnits(p[k], 'boe');
          } else if ('gas' === prefix) {
            prev[k] = convertToSelectedUnits(p[k], 'mcfe');
          } else {
            prev[k] = p[k];
          }
          return prev;
        }, {});
        row.id = `Year${p.year}_${index}`;
        return row;
      }) || [];
  const wellId = payload?.results?.[0]?.wellId || '';

  if (!productionList?.length) {
    return <h5>No production data found for this well.</h5>;
  }

  return (
    <Card>
      <CardBody>
        <div className={'mb-3'}>
          <Form inline>
            <FormGroup>
              <Input type="select" id="selectType" onChange={handleListTypeChange} value={listType}>
                <option value="summary">Summary by Year</option>
                <option value="boe">{productionUnitsTitle} by Month</option>
                <option value="gas">Gas by Month</option>
                <option value="oil">Oil by Month</option>
                <option value="ngl">NGL by Month</option>
              </Input>
            </FormGroup>
          </Form>
        </div>
        <ProductionList
          productionList={productionList}
          listType={listType as ProductionListType}
          timeUnit={'month'}
          exportFileName={`well_${wellId}_${listType}_production.csv`}
        />
      </CardBody>
    </Card>
  );
};

export default WellProductionList;
