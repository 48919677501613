import React, { useState } from 'react';
import Loader from 'react-loader';
import Tree from 'react-d3-tree';
import { Point, TreeNodeDatum } from 'react-d3-tree/lib/types/common';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

import { Operator } from 'tcf-upstream-shared/models';

import { useAppSelector } from '../../../hooks';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import { paths } from '../../../paths';
import { truncateText } from '../../../utils/formatting';
import ZoomControl from './ZoomControl';
import './OperatorOrgChart.css';

interface OperatorOrgChartProps {
  storeIdentifier: string;
}

const getChildren = (nodeId: number, subsidiaries: any[]): any => {
  return (
    subsidiaries
      .filter((s: any) => s.parentId === nodeId)
      .sort((a: any, b: any) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      .map((s: any) => ({ ...s, children: getChildren(s.id, subsidiaries) })) ?? []
  );
};

const OperatorOrgNode = ({ pageOperatorId, nodeDatum }: any) => {
  const name = truncateText(nodeDatum.name, 35) || 'NO NAME PROVIDED';

  const isCurrentOperator = nodeDatum.id === pageOperatorId;

  const icon = isCurrentOperator ? <polygon className="current" points="-9,-9 -9,9 9,9 9,-9" /> : <circle r="9" />;

  const targetId = `Operator${nodeDatum.id}`;
  const url = paths.VIEW_OPERATOR.replace(':id', nodeDatum.id.toString());

  const moProps = isCurrentOperator ? { className: 'rd3t-label__title current' } : { className: 'rd3t-label__title' };

  return (
    <g id={targetId}>
      {icon}
      <g className="rd3t-label">
        <text textAnchor="start" x="15" y="6" {...moProps}>
          {name}
        </text>
        {isCurrentOperator ? null : (
          <UncontrolledPopover trigger="legacy" placement="auto" target={targetId} boundariesElement="window">
            <PopoverBody style={{ color: '#444', fontSize: '0.75rem' }}>
              <table>
                <tbody>
                  <tr>
                    <th>Operator:</th>
                    <td>
                      <a href={url} title="View operator details">
                        Click to view
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>Status:</th>
                    <td>{nodeDatum.isActive ? 'Active' : 'Inactive'}</td>
                  </tr>
                  <tr>
                    <th>Producing:</th>
                    <td>{nodeDatum.isProducing ? 'Yes' : 'No'}</td>
                  </tr>
                  {nodeDatum.ticker ? (
                    <tr>
                      <th>Ticker:</th>
                      <td>{nodeDatum.ticker}</td>
                    </tr>
                  ) : null}
                  <tr>
                    <th style={{ paddingRight: '0.5rem' }}>Producing Wells:</th>
                    <td>{nodeDatum?.producingWellCount?.toLocaleString() || 0}</td>
                  </tr>
                  <tr>
                    <th>All Wells:</th>
                    <td>{nodeDatum?.wellCount?.toLocaleString() || 0}</td>
                  </tr>
                </tbody>
              </table>
            </PopoverBody>
          </UncontrolledPopover>
        )}
      </g>
    </g>
  );
};

const MIN_ZOOM = 0.1;
const MAX_ZOOM = 2;

const OperatorOrgTree = (props: { pageOperatorId: number; orgChart: any }) => {
  const { height, width } = useWindowDimensions();
  const [zoomFactor, setZoomFactor] = useState(1.0);
  const [translateFactor, setTranslateFactor] = useState({ x: width / 2, y: 90 });

  const { pageOperatorId, orgChart } = props;

  const zoomIn = () => {
    setZoomFactor(Math.min(zoomFactor + 0.1, MAX_ZOOM));
  };

  const zoomOut = () => {
    setZoomFactor(Math.max(zoomFactor - 0.1, MIN_ZOOM));
  };

  const rootDiv = document.getElementById('root') as HTMLDivElement;

  const handleUpdate = (target: { node: TreeNodeDatum | null; zoom: number; translate: Point }) => {
    // Terrible hack to cause popovers to close when the page is zoomed or panned.  This causes
    // a noticeable stutter.  I'm still trying to find a better solution, but this works.
    if (rootDiv) rootDiv.click();
    if (target?.zoom) setZoomFactor(target.zoom);
    if (target?.translate) setTranslateFactor(target.translate);
  };

  return (
    <div id="treeWrapper" style={{ width: '100%', height: Math.max(height - 288, 400), position: 'relative' }}>
      <Tree
        data={orgChart}
        orientation={'vertical'}
        zoom={zoomFactor}
        scaleExtent={{ min: MIN_ZOOM, max: MAX_ZOOM }}
        translate={translateFactor}
        nodeSize={{ x: 200, y: 120 }}
        separation={{ siblings: 1.5, nonSiblings: 2 }}
        collapsible={false}
        onUpdate={handleUpdate}
        renderCustomNodeElement={(rd3tProps) => <OperatorOrgNode pageOperatorId={pageOperatorId} {...rd3tProps} />}
      />
      <ZoomControl onPlus={zoomIn} onMinus={zoomOut} />
    </div>
  );
};

const OperatorOrgChart = (props: OperatorOrgChartProps) => {
  const { storeIdentifier } = props;
  const error: string | undefined = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.error);
  const isFetching: boolean = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.isFetching);
  const operator: Operator = useAppSelector((state) => state.serverStores?.[storeIdentifier]?.payload);
  const parents = operator?.parents?.sort((a: any, b: any) => b.depth - a.depth) ?? [];
  const subsidiaries = operator?.subsidiaries ?? [];

  if (isFetching) return <Loader loaded={false} />;
  if (error) return <ErrorComponent error={error} />;
  if (!operator || (parents.length < 1 && subsidiaries.length < 1)) return null;

  const orgChart: any = {};
  let node = orgChart;
  if (parents.length > 0) {
    parents.forEach((p: any, i: number) => {
      if (i === 0) {
        node.id = p.id;
        node.name = p.name;
        node.isActive = p.isActive;
        node.isProducing = p.isProducing;
        node.ticker = p.ticker;
        node.producingWellCount = p.producingWellCount;
        node.wellCount = p.wellCount;
        node.children = [];
        node = node.children;
      } else {
        node.push({
          id: p.id,
          name: p.name,
          isActive: p.isActive,
          isProducing: p.isProducing,
          ticker: p.ticker,
          producingWellCount: p.producingWellCount,
          wellCount: p.wellCount,
          children: [],
        });
        node = node[0].children;
      }
    });
    node.push({
      id: operator.id,
      name: operator.name,
    });
    node = node[0];
  } else {
    node.id = operator.id;
    node.name = operator.name;
  }
  node.children = subsidiaries.length > 0 ? getChildren(node.id, subsidiaries) : [];

  return <OperatorOrgTree pageOperatorId={operator.id} orgChart={orgChart} />;
};

export default OperatorOrgChart;
