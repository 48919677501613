import React, { useEffect, useState } from 'react';
import { Label, Input, FormGroup } from 'reactstrap';

import { ROOT_REGION_ID } from 'tcf-upstream-shared/constants';

import { useAppDispatch } from '../../hooks';
import { setQuery } from '../../actions/queryActions';
import Sidebar from '../../components/Sidebar';
import OperatorList from './components/OperatorList';
import FilterOperatorsForm, { VisibleFilters } from './components/FilterOperatorsForm';

const OPERATORS_STORE = 'operatorsListStore';
const OPERATORS_QUERY = 'operatorsListQuery';

const DEFAULT_FILTERS = { isProducing: true, parentId: null };
const DEFAULT_SORT = [{ regionSummary: { field: 'boeTotal', regionId: ROOT_REGION_ID, order: 'desc' } }];
const DEFAULT_PAGE_SIZE = 25;

const ListOperators = () => {
  const dispatch = useAppDispatch();
  const [showColumns, setShowColumns] = useState({
    showAlias: false,
    showWellStates: false,
    showStatus: false,
    showDecline: true,
  });

  const initVisibleFilters: VisibleFilters = {
    location: true,
    wellCount: false,
    producingWellCount: false,
  };
  const [visibleFilters, setVisibleFilters] = useState(initVisibleFilters);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setQuery(OPERATORS_QUERY, { limit: DEFAULT_PAGE_SIZE, sort: DEFAULT_SORT, filters: DEFAULT_FILTERS }));
  }, [dispatch]);

  const toggleShowAlias = () => {
    setShowColumns((c) => ({ ...c, showAlias: !c.showAlias }));
  };
  const toggleShowWellStates = () => {
    setShowColumns((c) => ({ ...c, showWellStates: !c.showWellStates }));
  };
  const toggleShowStatus = () => {
    setShowColumns((c) => ({ ...c, showStatus: !c.showStatus }));
  };

  const onVisibleFilterToggle = (id: string) => {
    const newVisibleFilters = { ...visibleFilters };
    newVisibleFilters[id] = !visibleFilters[id];
    setVisibleFilters(newVisibleFilters);
  };

  return (
    <div className={'flexPage'}>
      <Sidebar>
        <FilterOperatorsForm
          storeIdentifier={OPERATORS_STORE}
          queryIdentifier={OPERATORS_QUERY}
          showLocationFilter={true}
          visibleFilters={visibleFilters}
          onVisibleFilterToggle={onVisibleFilterToggle}
        />
        <div>
          <hr />
          <Label>Include in results:</Label>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" onChange={toggleShowAlias} checked={showColumns.showAlias} />
              &nbsp;Aliases
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" onChange={toggleShowWellStates} checked={showColumns.showWellStates} />
              &nbsp;Well States
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" onChange={toggleShowStatus} checked={showColumns.showStatus} />
              &nbsp;Status
            </Label>
          </FormGroup>
        </div>
      </Sidebar>
      <main className={'mainContent'}>
        <OperatorList
          storeIdentifier={OPERATORS_STORE}
          queryIdentifier={OPERATORS_QUERY}
          regionId={ROOT_REGION_ID}
          {...showColumns}
        />
      </main>
    </div>
  );
};

export default ListOperators;
