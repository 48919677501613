import { combineReducers } from 'redux';
import { reducer as toastrReducer, ToastrState } from 'react-redux-toastr';

import { AdminObjectState, adminObjectReducer } from './adminObjectReducer';
import { AdminObjectsState, adminObjectsReducer } from './adminObjectsReducer';
import { AuthState, authReducer } from './authReducer';
import { BreadcrumbState, breadcrumbReducer } from './breadcrumbReducer';
import { AdminOperatorLookupCacheState, adminOperatorLookupCacheReducer } from './adminOperatorLookupCacheReducer';
import { QueryState, queriesReducer } from './queriesReducer';
import { ServerStoresState, serverStoresReducer } from './serverStoresReducer';
import { WellLookupsState, wellLookupsReducer } from './wellLookupsReducer';

export interface AppState {
  auth: AuthState;
  toastr: ToastrState;
  wellLookups: WellLookupsState;
  queries: QueryState;
  serverStores: ServerStoresState;
  adminObject: AdminObjectState;
  adminObjects: AdminObjectsState;
  adminOperatorLookupCache: AdminOperatorLookupCacheState;
  breadcrumbs: BreadcrumbState;
}

const rootReducer = combineReducers<AppState>({
  auth: authReducer,
  toastr: toastrReducer,
  wellLookups: wellLookupsReducer,
  queries: queriesReducer,
  serverStores: serverStoresReducer,
  adminObject: adminObjectReducer,
  adminObjects: adminObjectsReducer,
  adminOperatorLookupCache: adminOperatorLookupCacheReducer,
  breadcrumbs: breadcrumbReducer,
});

export default rootReducer;
